import classNames from "classnames";

import { L10n } from "@encoway/l10n";

import { mapIndexed } from "../../utilities/utilities";

const PaginationItem = ({ index, currentIndex, onClick }) => (
  <li>
    <button
      data-button-id={`pagination-${index + 1}`}
      className={classNames([
        "pagination-link",
        { "is-current": index === currentIndex },
      ])}
      onClick={() => onClick(index)}
    >
      {index + 1}
    </button>
  </li>
);

const Seperator = () => (
  <li>
    <span className="pagination-ellipsis">&hellip;</span>
  </li>
);

const PaginationWithDots = ({
  elementIndex,
  currentPage,
  itemLength,
  onClick,
  range,
}) => {
  const isBeginning = elementIndex === 0;
  const isMiddle =
    elementIndex >= currentPage - range && elementIndex <= currentPage + range;
  const isEnding = elementIndex === itemLength - 1;
  const isSecondOrPenultimate =
    elementIndex === 1 || elementIndex === itemLength - 2;

  return (
    <>
      {isBeginning || isMiddle || isEnding ? (
        <PaginationItem
          index={elementIndex}
          currentIndex={currentPage}
          onClick={onClick}
        />
      ) : (
        isSecondOrPenultimate && <Seperator />
      )}
    </>
  );
};

export const TablePagination = ({
  currentIndex,
  items,
  onClick,
  isPaginationWithDots = false,
  range = 2,
}) => (
  <nav
    className="pagination is-centered"
    role="navigation"
    aria-label="pagination"
  >
    <button
      className="pagination-next"
      disabled={currentIndex === items.length - 1 || items.length === 0}
      onClick={
        currentIndex === items.length - 1 || items.length === 0
          ? null
          : () => onClick(currentIndex + 1)
      }
      data-button-id="next-offers"
    >
      {L10n.format("pagination_next")}
    </button>
    <button
      className="pagination-previous"
      disabled={currentIndex === 0}
      onClick={currentIndex <= 0 ? null : () => onClick(currentIndex - 1)}
      data-button-id="previous-offers"
    >
      {L10n.format("pagination_previous")}
    </button>
    <ul className="pagination-list">
      {mapIndexed(
        (_, index) =>
          isPaginationWithDots ? (
            <PaginationWithDots
              key={index}
              elementIndex={index}
              currentPage={currentIndex}
              itemLength={items.length}
              onClick={onClick}
              range={range}
            />
          ) : (
            <PaginationItem
              key={index}
              index={index}
              currentIndex={currentIndex}
              onClick={onClick}
            />
          ),
        items,
      )}
    </ul>
  </nav>
);
