import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  FlexTableCell as Cell,
  FlexTableHead,
} from "../../../../../components/flexTable/flexTable";
import { UserRoles } from "../../../../../utilities/cartUtils";
import { CartItemDetailsReturn } from "../../../hooks/useCartItemDetails";
import { CartViewReturn, CartViews } from "../../../hooks/useCartView";
import { t } from "../../../localizationUtils";
import { PartOrderCheckboxCell } from "../cells/partOrderCheckboxCell";

import "./rowHead.scss";

type ArticleDescriptionHeadCellProps = {
  cartItemDetails: CartItemDetailsReturn;
  headText: string;
};

const ArticleDescriptionHeadCell = ({
  cartItemDetails,
  headText,
}: ArticleDescriptionHeadCellProps) => (
  <Cell className="is-block" data-cell-id={headText}>
    <div>{t(headText)}</div>
    <button
      className="price-details-head"
      onClick={cartItemDetails.cartItemDetailsActions.toggleAllCartItemDetails}
      data-button-id={"all_price_details"}
    >
      {t("all_price_details")}
      {cartItemDetails.isToggleAll ? (
        <FontAwesomeIcon
          icon={faSortUp}
          height="10px"
          style={{ color: "#ef7b22" }}
        />
      ) : (
        <FontAwesomeIcon
          icon={faSortDown}
          height="10px"
          style={{ color: "#ef7b22" }}
        />
      )}
    </button>
  </Cell>
);

type RowHeadSalesProps = {
  tableViews: CartViews;
  cartItemDetails: CartItemDetailsReturn;
};

const RowHeadSales = ({ tableViews, cartItemDetails }: RowHeadSalesProps) => (
  <>
    <ArticleDescriptionHeadCell
      cartItemDetails={cartItemDetails}
      headText={"cart_table_brutto_gp_inc_tz"}
    />
    <Cell
      value={t("cart_table_allowance")}
      data-column-id="cart_table_allowance"
    />
    <Cell
      value={t("cart_table_nonsales")}
      data-column-id="cart_table_nonsales"
    />
    {tableViews.surcharge && (
      <>
        <Cell
          value={t("cart_table_surcharge_perc")}
          data-column-id="cart_table_surcharge_perc"
        />
        <Cell
          value={t("cart_table_surcharge")}
          data-column-id="cart_table_surcharge"
        />
        <Cell
          value={t("cart_table_ek_netto_gp_incl_surcharge")}
          data-column-id="cart_table_ek_netto_gp_incl_surcharge"
        />
        <Cell
          value={t("cart_table_position_discount")}
          data-column-id="cart_table_position_discount"
        />
        <Cell
          value={t("cart_table_position_discount_absolute")}
          data-column-id="cart_table_position_discount_absolute"
        />
        <Cell
          value={t("cart_table_sales_price")}
          data-column-id="cart_table_sales_price"
        />
      </>
    )}
    {tableViews.discount && (
      <>
        <Cell
          value={t("cart_table_price_list_discount_perc")}
          data-column-id="cart_table_price_list_discount_perc"
        />
        <Cell
          value={t("cart_table_price_list_discount")}
          data-column-id="cart_table_price_list_discount"
        />
        <Cell
          value={t("cart_table_margin_perc")}
          data-column-id="cart_table_margin_perc"
        />
        <Cell
          value={t("cart_table_margin")}
          data-column-id="cart_table_margin"
        />
        <Cell
          value={t("cart_table_sales_price")}
          data-column-id="cart_table_sales_price"
        />
      </>
    )}
  </>
);

const RowHeadCustomer = () => (
  <>
    <Cell
      value={t("cart_table_your_gross_price")}
      data-column-id="cart_table_your_gross_price"
    />
    <Cell
      value={t("cart_table_customer_discount_perc")}
      data-column-id="cart_table_customer_discount_perc"
    />
    <Cell
      value={t("cart_table_customer_discount")}
      data-column-id="cart_table_customer_discount"
    />
    <Cell
      value={t("cart_table_sales_price")}
      data-column-id="cart_table_sales_price"
    />
  </>
);

type RowHeadBuyerProps = {
  userRoles: UserRoles;
  cartItemDetails: CartItemDetailsReturn;
};

const RowHeadBuyer = ({ userRoles, cartItemDetails }: RowHeadBuyerProps) => {
  const { hasGraduatedDiscount, hasTeoDiscount } = userRoles;
  return (
    <>
      <ArticleDescriptionHeadCell
        cartItemDetails={cartItemDetails}
        headText={"cart_table_brutto_ep_inc_tz"}
      />
      <Cell
        value={t("cart_table_brutto_gp_inc_tz")}
        data-column-id="cart_table_brutto_gp_inc_tz"
      />
      <Cell
        value={t("cart_table_discount")}
        data-column-id="cart_table_discount"
      />
      <Cell
        value={t("cart_table_campaign_discount")}
        data-column-id="cart_table_campaign_discount"
      />
      {hasGraduatedDiscount && (
        <Cell
          value={t("cart_table_scale_discount")}
          data-column-id="cart_table_scale_discount"
        />
      )}
      {hasTeoDiscount && (
        <Cell
          value={t("cart_table_teo_discount")}
          data-column-id="cart_table_teo_discount"
        />
      )}
      <Cell
        value={t("cart_table_nonsales_ep")}
        data-column-id="cart_table_nonsales_ep"
      />
      <Cell
        value={t("cart_table_nonsales")}
        data-column-id="cart_table_nonsales"
      />
    </>
  );
};

const RowHeadPartialOrder = () => (
  <>
    <Cell
      className="is-justify-content-center"
      value={t("cart_table_order_status")}
      data-column-id="cart_table_order_status"
    />
    <Cell
      className="is-justify-content-center"
      value={t("cart_table_order_process")}
      data-column-id="cart_table_order_process"
    />
    <Cell />
    <Cell
      value={t("cart_table_nonsales")}
      data-column-id="cart_table_nonsales"
    />
  </>
);

type RowHeadProps = {
  userRoles: UserRoles;
  tableViews: CartViews;
  cartItemDetails: CartItemDetailsReturn;
  partOrderAll: boolean;
  viewActions: CartViewReturn["viewActions"];
  partialOrderActivated: boolean;
};

export const RowHead = ({
  userRoles,
  tableViews,
  cartItemDetails,
  partOrderAll,
  viewActions,
  partialOrderActivated,
}: RowHeadProps) => (
  <FlexTableHead>
    <PartOrderCheckboxCell
      optional={partOrderAll}
      setOptional={() => viewActions.togglePartialOrderAll(!partOrderAll)}
      show={tableViews.isOrder && partialOrderActivated}
      wrapperClassName={"checkbox-wrapper all-part-order-checkbox"}
      className="checkbox"
      dataCheckboxId="all-part-order-checkbox"
    />
    <Cell value={t("cart_table_pos")} data-column-id="cart_table_pos" />
    <Cell value="" />
    <Cell value={t("cart_table_name")} data-column-id="cart_table_name" />
    <Cell value={t("cart_table_option")} data-column-id="cart_table_option" />
    <Cell value={t("cart_table_amount")} data-column-id="cart_table_amount" />
    {tableViews.isOrder && <RowHeadPartialOrder />}
    {tableViews.isBuyer && (
      <RowHeadBuyer userRoles={userRoles} cartItemDetails={cartItemDetails} />
    )}
    {tableViews.isCustomer && <RowHeadCustomer />}
    {tableViews.isSales && (
      <RowHeadSales tableViews={tableViews} cartItemDetails={cartItemDetails} />
    )}
    <Cell />
  </FlexTableHead>
);
