import { isEmpty } from "ramda";

import { Reconfiguration } from "../../../../types/configuration";

const isReconfigFailedStatus = (state: Reconfiguration | null) =>
  state &&
  state.configurationStatus?.configuration?.properties
    ?.ReconfigurationSetupSuccessful === "false";

const isReconfigPossibleStatus = (state: Reconfiguration | null) =>
  state && !isEmpty(state.unsuccessfulParameters);

const checkedReconfigStatus = (state: Reconfiguration | null) => {
  if (isReconfigFailedStatus(state)) {
    return "isReconfigFailed";
  }

  if (isReconfigPossibleStatus(state)) {
    return "isReconfigPossible";
  }

  return "isReconfigSuccessful";
};

export const isReconfigFailed = (state: Reconfiguration | null) =>
  checkedReconfigStatus(state) === "isReconfigFailed";

export const isReconfigPossible = (state: Reconfiguration | null) =>
  checkedReconfigStatus(state) === "isReconfigPossible";

export const isReconfigSuccessful = (state: Reconfiguration | null) =>
  checkedReconfigStatus(state) === "isReconfigSuccessful";
