import classNames from "classnames";
import { not } from "ramda";
import { FocusEvent, KeyboardEvent, PropsWithChildren, useMemo } from "react";

import {
  FlexTableCell as Cell,
  FlexTableRow,
} from "../../../../../components/flexTable/flexTable";
import { SmartInput } from "../../../../../components/input/smartInput";
import { Loading } from "../../../../../components/loading";
import { useCart } from "../../../../../hooks/useCart";
import { useLocaleNumberFormat } from "../../../../../hooks/useLocaleNumberFormat";
import {
  CartActions,
  TeckentrupCartArticleTO,
} from "../../../../../types/cart";
import {
  ItemState,
  ORDER_STATUS_FLAGGED,
  ORDER_STATUS_TOGGLE_MAP,
  ORDER_STATUS_UNORDERD,
  UserRoles,
  configurationIncompleteOrHasNoBruttoEpIncTzPrice,
  hasPricing,
} from "../../../../../utilities/cartUtils";
import { CartDragAndDropReturn } from "../../../hooks/useCartDragAndDrop";
import { CartItemDetailsReturn } from "../../../hooks/useCartItemDetails";
import { CartModalsReturn } from "../../../hooks/useCartModals";
import { CartViews, ViewActions } from "../../../hooks/useCartView";
import { hasCartItemDetails } from "../../../hooks/utils/cartItemDetailsUtils";
import { t } from "../../../localizationUtils";
import { ArticleCell } from "../cells/articleCell";
import { CheckboxCell } from "../cells/checkboxCell";
import { DropDownCell } from "../cells/dropdownCell/dropdownCell";
import { GrossGpIncTzSalesCell } from "../cells/grossGpIncTzSalesCell";
import { InputCell } from "../cells/inputCell";
import { PartOrderCheckboxCell } from "../cells/partOrderCheckboxCell";
import { WarningCell } from "../cells/warningCell";
import { RowPriceDetails } from "./rowPriceDetails";
import { RowProductDetails } from "./rowProductDetails";

type RowsDiscountProps = {
  cartItem: TeckentrupCartArticleTO;
  actions: CartActions;
};

const RowsDiscount = ({ cartItem, actions }: RowsDiscountProps) => {
  const { setPriceListDiscountPerc, setPriceListDiscount } = actions;
  const {
    group,
    priceListDiscountRate,
    priceListDiscountAbsolute,
    marginNewPercent,
    marginNewAbsolut,
    vkNettoGp2,
    positionNr,
  } = cartItem;
  const { formatPrice } = useLocaleNumberFormat();
  return group ? (
    <>
      <Cell />
      <Cell />
      <Cell />
      <Cell
        value={formatPrice(marginNewAbsolut)}
        data-cell-id={`${positionNr}-cart-table-margin`}
      />
      <Cell
        value={formatPrice(vkNettoGp2)}
        data-cell-id={`${positionNr}-cart-table-sales-price`}
      />
    </>
  ) : (
    <>
      <InputCell
        value={priceListDiscountRate}
        setValue={setPriceListDiscountPerc(cartItem)}
        decimalScale={2}
        data-cell-id={`${positionNr}-cart-table-price-list-discount-perc`}
      />
      <InputCell
        value={priceListDiscountAbsolute.price}
        setValue={setPriceListDiscount(cartItem)}
        isCurrency
        data-cell-id={`${positionNr}-cart-table-price-list-discount`}
      />
      <Cell
        value={marginNewPercent}
        data-cell-id={`${positionNr}-cart-table-margin-perc`}
      />
      <Cell
        value={formatPrice(marginNewAbsolut)}
        data-cell-id={`${positionNr}-cart-table-margin`}
      />
      <Cell
        value={formatPrice(vkNettoGp2)}
        data-cell-id={`${positionNr}-cart-table-sales-price`}
      />
    </>
  );
};

type RowsSurchargeProps = {
  cartItem: TeckentrupCartArticleTO;
  actions: CartActions;
};

const RowsSurcharge = ({ cartItem, actions }: RowsSurchargeProps) => {
  const {
    setMarginPerc,
    setMargin,
    setPositionDiscountPerc,
    setPositionDiscount,
  } = actions;
  const {
    group,
    marginPercent,
    marginAbsolute,
    ekNettoGpInclMargin,
    positionDiscountRate,
    positionDiscountAbsolute,
    vkNettoGp,
    positionNr,
  } = cartItem;
  const { formatPrice } = useLocaleNumberFormat();
  return group ? (
    <>
      <Cell />
      <Cell
        value={formatPrice(marginAbsolute)}
        data-cell-id={`${positionNr}-cart-table-surcharge`}
      />
      <Cell
        value={formatPrice(ekNettoGpInclMargin)}
        data-cell-id={`${positionNr}-cart-table-ek-netto-gp-incl-surcharge`}
      />
      <Cell />
      <Cell
        value={formatPrice(positionDiscountAbsolute)}
        data-cell-id={`${positionNr}-cart-table-position-discount-absolute`}
      />
      <Cell
        value={formatPrice(vkNettoGp)}
        data-cell-id={`${positionNr}-cart-table-sales-price`}
      />
    </>
  ) : (
    <>
      <InputCell
        value={marginPercent}
        setValue={setMarginPerc(cartItem)}
        decimalScale={2}
        data-cell-id={`${positionNr}-cart-table-surcharge-perc`}
      />
      <InputCell
        value={marginAbsolute.price}
        setValue={setMargin(cartItem)}
        isCurrency
        data-cell-id={`${positionNr}-cart-table-surcharge`}
      />
      <Cell
        value={formatPrice(ekNettoGpInclMargin)}
        data-cell-id={`${positionNr}-cart-table-ek-netto-gp-incl-surcharge`}
      />
      <InputCell
        value={positionDiscountRate}
        setValue={setPositionDiscountPerc(cartItem)}
        decimalScale={2}
        data-cell-id={`${positionNr}-cart-table-position-discount`}
      />
      <InputCell
        value={positionDiscountAbsolute.price}
        setValue={setPositionDiscount(cartItem)}
        isCurrency
        data-cell-id={`${positionNr}-cart-table-position-discount-absolute`}
      />
      <Cell
        value={formatPrice(vkNettoGp)}
        data-cell-id={`${positionNr}-cart-table-sales-price`}
      />
    </>
  );
};

type ToggleCartItemDetails = ReturnType<
  CartItemDetailsReturn["cartItemDetailsActions"]["toggleCartItemDetails"]
>;

type RowSalesProps = {
  cartItem: TeckentrupCartArticleTO;
  toggleCartItemDetails: ToggleCartItemDetails;
  showCartItemDetails: ReturnType<CartItemDetailsReturn["isToggle"]>;
};

const RowSales = ({
  cartItem,
  toggleCartItemDetails,
  showCartItemDetails,
  children,
}: PropsWithChildren<RowSalesProps>) => {
  const {
    group,
    pricingStatus,
    bruttoGpIncTz,
    allowance,
    ekNettoGp,
    positionNr,
  } = cartItem;
  const { formatPrice } = useLocaleNumberFormat();
  return (
    <>
      {group ? (
        <>
          <Cell
            value={formatPrice(bruttoGpIncTz)}
            data-cell-id={`${positionNr}-cart-table-brutto-gp-inc-tz`}
          />
          <Cell
            value={formatPrice(allowance)}
            data-cell-id={`${positionNr}-cart-table-allowance`}
          />
          <Cell
            value={formatPrice(ekNettoGp)}
            data-cell-id={`${positionNr}-cart-table-nonsales`}
          />
        </>
      ) : (
        <>
          <GrossGpIncTzSalesCell
            hasCartItemDetails={hasCartItemDetails(cartItem)}
            toggleCartItemDetails={toggleCartItemDetails}
            pricingStatus={pricingStatus}
            bruttoGpIncTz={bruttoGpIncTz}
            showCartItemDetails={showCartItemDetails}
            labelId={`${positionNr}-cart-table-brutto-gp-inc-tz`}
          />
          <Cell
            value={formatPrice(allowance)}
            data-cell-id={`${positionNr}-cart-table-allowance`}
          />
          <Cell
            value={formatPrice(ekNettoGp)}
            data-cell-id={`${positionNr}-cart-table-nonsales`}
          />
        </>
      )}
      {children}
    </>
  );
};

type RowCustomerSurchargeProps = {
  cartItem: TeckentrupCartArticleTO;
  actions: CartActions;
};

const RowCustomerSurcharge = ({
  cartItem,
  actions,
}: RowCustomerSurchargeProps) => {
  const { setPositionDiscountPerc, setPositionDiscount } = actions;
  const {
    group,
    positionDiscountRate,
    positionDiscountAbsolute,
    vkNettoGp,
    ekNettoGpInclMargin,
    positionNr,
  } = cartItem;
  const { formatPrice } = useLocaleNumberFormat();
  return (
    <>
      <Cell
        value={formatPrice(ekNettoGpInclMargin)}
        data-cell-id={`${positionNr}-cart-table-your-gross-price`}
      />
      {group ? (
        <>
          <Cell />
          <Cell />
        </>
      ) : (
        <>
          <InputCell
            value={positionDiscountRate}
            setValue={setPositionDiscountPerc(cartItem)}
            decimalScale={2}
            data-cell-id={`${positionNr}-cart-table-customer-discount-perc`}
          />
          <InputCell
            value={positionDiscountAbsolute.price}
            setValue={setPositionDiscount(cartItem)}
            isCurrency
            data-cell-id={`${positionNr}-cart-table-customer-discount`}
          />
        </>
      )}
      <Cell value={formatPrice(vkNettoGp)} />
    </>
  );
};

type RowCustomerDiscountProps = {
  cartItem: TeckentrupCartArticleTO;
  actions: CartActions;
};

const RowCustomerDiscount = ({
  cartItem,
  actions,
}: RowCustomerDiscountProps) => {
  const { setPriceListDiscountPerc, setPriceListDiscount } = actions;
  const {
    group,
    priceListDiscountRate,
    priceListDiscountAbsolute,
    vkNettoGp2,
    bruttoGpIncTz,
    positionNr,
  } = cartItem;
  const { formatPrice } = useLocaleNumberFormat();
  return (
    <>
      <Cell
        value={formatPrice(bruttoGpIncTz)}
        data-cell-id={`${positionNr}-cart-table-your-gross-price`}
      />
      {group ? (
        <>
          <Cell />
          <Cell />
        </>
      ) : (
        <>
          <InputCell
            value={priceListDiscountRate}
            setValue={setPriceListDiscountPerc(cartItem)}
            decimalScale={2}
            data-cell-id={`${positionNr}-cart-table-customer-discount-perc`}
          />
          <InputCell
            value={priceListDiscountAbsolute.price}
            setValue={setPriceListDiscount(cartItem)}
            isCurrency
            data-cell-id={`${positionNr}-cart-table-customer-discount`}
          />
        </>
      )}
      <Cell
        value={formatPrice(vkNettoGp2)}
        data-cell-id={`${positionNr}-cart-table-sales-price`}
      />
    </>
  );
};

type RowBuyerProps = {
  cartItem: TeckentrupCartArticleTO;
  actions: CartActions;
  toggleCartItemDetails: ToggleCartItemDetails;
  showCartItemDetails: ReturnType<CartItemDetailsReturn["isToggle"]>;
  userRoles: UserRoles;
};

const RowBuyer = ({
  cartItem,
  actions,
  toggleCartItemDetails,
  showCartItemDetails,
  userRoles,
}: RowBuyerProps) => {
  const { setDiscountPerc, setCampaignDiscountPerc } = actions;
  const {
    group,
    pricingStatus,
    bruttoEpIncTz,
    discountRate,
    campaignDiscountRate,
    teoDiscountRate,
    scaleDiscountRate,
    ekNettoGp,
    bruttoGpIncTz,
    positionNr,
    freeArticle,
    ekNettoEp,
  } = cartItem;
  const { hasGraduatedDiscount, hasTeoDiscount } = userRoles;
  const { formatPrice } = useLocaleNumberFormat();
  const getPrice = hasPricing(pricingStatus)
    ? formatPrice(bruttoGpIncTz)
    : t("price_on_request");
  return (
    <>
      {group ? (
        <>
          <Cell />
          <Cell
            value={formatPrice(bruttoGpIncTz)}
            data-cell-id={`${positionNr}-cart-table-brutto-gp-inc-tz`}
          />
          <Cell />
          <Cell />
          {hasGraduatedDiscount && (
            <Cell data-cell-id={`${positionNr}-cart-table-scale-discount`} />
          )}
          {hasTeoDiscount && (
            <Cell data-cell-id={`${positionNr}-cart-table-teo-discount`} />
          )}
        </>
      ) : (
        <>
          <GrossGpIncTzSalesCell
            hasCartItemDetails={hasCartItemDetails(cartItem)}
            toggleCartItemDetails={toggleCartItemDetails}
            bruttoGpIncTz={bruttoEpIncTz}
            showCartItemDetails={showCartItemDetails}
            labelId={`${positionNr}-cart-table-brutto-ep-inc-tz`}
          />
          <Cell
            value={getPrice}
            data-cell-id={`${positionNr}-cart-table-brutto-gp-inc-tz`}
          />
          <InputCell
            value={discountRate}
            setValue={setDiscountPerc(cartItem)}
            decimalScale={2}
            data-cell-id={`${positionNr}-cart-table-discount`}
          />
          {freeArticle ? (
            <Cell value="" />
          ) : (
            <InputCell
              value={campaignDiscountRate}
              setValue={setCampaignDiscountPerc(cartItem)}
              decimalScale={2}
              data-cell-id={`${positionNr}-cart-table-campaign-discount`}
            />
          )}
          {hasGraduatedDiscount && (
            <Cell
              data-cell-id={`${positionNr}-cart-table-scale-discount`}
              {...(!freeArticle && { value: scaleDiscountRate })}
            />
          )}
          {hasTeoDiscount && (
            <Cell
              data-cell-id={`${positionNr}-cart-table-teo-discount`}
              {...(!freeArticle && { value: teoDiscountRate })}
            />
          )}
        </>
      )}
      <Cell
        value={formatPrice(ekNettoEp)}
        data-cell-id={`${positionNr}-cart_table_nonsales_ep`}
      />
      <Cell
        value={formatPrice(ekNettoGp)}
        data-cell-id={`${positionNr}-cart-table-nonsales`}
      />
    </>
  );
};

type RowPartialOrderProps = {
  cartItem: TeckentrupCartArticleTO;
};

export const RowPartialOrder = ({ cartItem }: RowPartialOrderProps) => {
  const {
    ekNettoGp,
    positionNr,
    ekNettoGpPartOrder,
    group,
    orderStatus,
    orderDate,
    orderNumber,
  } = cartItem;
  const { formatPrice } = useLocaleNumberFormat();
  return (
    <>
      <Cell className="is-flex-direction-column is-justify-content-center">
        <span data-cell-id={`${positionNr}-cart-table-order-status`}>
          {orderStatus}
        </span>
        <span data-cell-id={`${positionNr}-cart-table-order-date`}>
          {orderDate}
        </span>
      </Cell>
      <Cell
        className="is-justify-content-center"
        value={orderNumber}
        data-cell-id={`${positionNr}-cart-table-order-process`}
      />
      <Cell />
      {group ? (
        <Cell
          value={formatPrice(ekNettoGpPartOrder)}
          data-cell-id={`${positionNr}-cart-table-nonsales`}
        />
      ) : (
        <Cell
          value={formatPrice(ekNettoGp)}
          data-cell-id={`${positionNr}-cart-table-nonsales`}
        />
      )}
    </>
  );
};

export const RowLoading = () => (
  <FlexTableRow className="loading-spinner-row">
    <Cell className="loading-spinner-cell">
      <Loading />
    </Cell>
  </FlexTableRow>
);

type RowProps = {
  unsavedCart: boolean;
  userRoles: UserRoles;
  cartDragAndDrop: CartDragAndDropReturn;
  parentId: string;
  tableViews: CartViews;
  cartItem: TeckentrupCartArticleTO;
  cartActions: CartActions;
  modalActions: CartModalsReturn["modalActions"];
  hasRights: boolean;
  itemRole: ItemState;
  cartItemDetails: CartItemDetailsReturn;
  viewActions: ViewActions;
};

export const Row = ({
  unsavedCart,
  userRoles,
  cartDragAndDrop,
  parentId,
  tableViews,
  cartItem,
  cartActions,
  modalActions,
  hasRights,
  itemRole,
  cartItemDetails,
  viewActions,
}: RowProps) => {
  const { cart } = useCart();

  const onDrag = cartDragAndDrop(cartItem, parentId, cart.articles.articleId);
  const { setOptional, setAmount, setGroupName } = cartActions;
  const { setFlagAsPartOrder } = viewActions;
  const {
    positionNr,
    group,
    articleName,
    optional,
    amount,
    minimalAmount,
    articleOrderStatus,
    allowForPartOrder,
    quantityUnit,
    quantityMin,
    quantityMax,
  } = cartItem;
  const {
    cartDetailsParameter,
    isToggle,
    cartItemDetailsActions: { toggleCartItemDetails },
  } = cartItemDetails;

  const showPartialOrderCheckbox = useMemo(
    () =>
      tableViews.isOrder &&
      allowForPartOrder &&
      cart.flaggedForPartOrder &&
      !configurationIncompleteOrHasNoBruttoEpIncTzPrice(cartItem),
    [cart, cartItem, tableViews],
  );

  const onSetOptional = (optional: boolean) => {
    if (articleOrderStatus === ORDER_STATUS_FLAGGED && optional) {
      viewActions
        .setFlagAsPartOrder(cartItem, ORDER_STATUS_UNORDERD, false)
        .then(() => setOptional(cartItem, optional));
      return;
    }
    setOptional(cartItem, optional);
  };

  if (not(hasRights) && itemRole.isFreeItem) {
    return null;
  }

  async function onGroupRename(
    event: FocusEvent<HTMLInputElement> | KeyboardEvent<HTMLInputElement>,
  ): Promise<string> {
    const value = event.currentTarget!.value;
    await setGroupName(cartItem, value);
    return value;
  }

  return (
    <>
      {cart.reconfigurationPosition === positionNr && (
        <div className="overlay">
          <Loading />
        </div>
      )}
      <FlexTableRow
        className={classNames({
          "overlay-active": cart.reconfigurationPosition === positionNr,
        })}
        data-position-nr={positionNr}
      >
        <PartOrderCheckboxCell
          optional={articleOrderStatus === ORDER_STATUS_FLAGGED}
          setOptional={() =>
            setFlagAsPartOrder(
              cartItem,
              ORDER_STATUS_TOGGLE_MAP[articleOrderStatus],
            )
          }
          show={showPartialOrderCheckbox}
          wrapperClassName={"checkbox-wrapper"}
          className="checkbox"
          dataCheckboxId={`${positionNr}-part-order-checkbox`}
        />
        <Cell value={positionNr} draggable="true" {...onDrag} />
        <WarningCell cartItem={cartItem} />
        {group ? (
          <>
            <Cell>
              <SmartInput
                className="input table-input is-small"
                value={articleName}
                onKeyPress={onGroupRename}
                onBlur={onGroupRename}
                selectOnFocus={true}
                attributeId={`folder-${positionNr}`}
              />
            </Cell>
            <Cell />
            <Cell />
          </>
        ) : (
          <>
            <ArticleCell
              cartItem={cartItem}
              itemRole={itemRole}
              cartActions={cartActions}
              hasRights={hasRights}
              positionNr={positionNr}
            />
            <CheckboxCell
              optional={optional}
              setOptional={() => onSetOptional(!optional)}
              dataCheckboxId={`alt-pos-${positionNr}`}
            />
            <InputCell
              value={amount}
              setValue={setAmount(cartItem)}
              minimalValue={quantityMin ?? minimalAmount ?? 1}
              hasAmountWarning
              decimalScale={quantityUnit === "M" ? 2 : 0}
              data-cell-id={`${positionNr}-cart-table-amount`}
              quantity={{ quantityUnit, quantityMin, quantityMax }}
            />
          </>
        )}
        {tableViews.isOrder && <RowPartialOrder cartItem={cartItem} />}
        {tableViews.isBuyer && (
          <RowBuyer
            userRoles={userRoles}
            cartItem={cartItem}
            actions={cartActions}
            toggleCartItemDetails={toggleCartItemDetails(cartItem)}
            showCartItemDetails={isToggle(cartItem)}
          />
        )}
        {tableViews.isCustomer && tableViews.surcharge && (
          <RowCustomerSurcharge cartItem={cartItem} actions={cartActions} />
        )}
        {tableViews.isCustomer && tableViews.discount && (
          <RowCustomerDiscount cartItem={cartItem} actions={cartActions} />
        )}
        {tableViews.isSales && (
          <RowSales
            cartItem={cartItem}
            showCartItemDetails={isToggle(cartItem)}
            toggleCartItemDetails={toggleCartItemDetails(cartItem)}
          >
            {tableViews.surcharge && (
              <RowsSurcharge actions={cartActions} cartItem={cartItem} />
            )}
            {tableViews.discount && (
              <RowsDiscount actions={cartActions} cartItem={cartItem} />
            )}
          </RowSales>
        )}
        <DropDownCell
          unsavedCart={unsavedCart}
          cartItem={cartItem}
          itemRole={itemRole}
          toggleModal={modalActions.toggleModal}
        />
      </FlexTableRow>
      {not(tableViews.isCustomer || tableViews.isOrder) &&
        itemRole.isConfigurableItem &&
        isToggle(cartItem) && (
          <>
            <RowPriceDetails
              cartItem={cartItem}
              tableViews={tableViews}
              userRoles={userRoles}
            />
            <RowProductDetails
              cartItemDetails={cartDetailsParameter[cartItem.articleId]}
              positionNr={positionNr}
            />
          </>
        )}
    </>
  );
};
