import classNames from "classnames";

import { useCart } from "../../../../hooks/useCart";
import { isCartEmptyOrHasUserUnsavedCart } from "../../../../utilities/cartUtils";
import { getFormattedDate } from "../../../../utilities/dateUtils";
import { t } from "../../localizationUtils";
import { dateIsOlderThanSixWeeks } from "./cartInformationUtils";

import "./cartInformation.scss";

type InfoTextWrapperProps = {
  label: string;
  value: string;
  highlighted: boolean;
};

const InfoTextWrapper = ({
  label,
  value,
  highlighted,
}: InfoTextWrapperProps) => (
  <div className="shopping-cart-price-date">
    <span className="info-text-label" data-label-id="price-date">
      {`${t(label)}:`}
    </span>
    <span
      data-label-id="price-date-value"
      className={classNames("info-text-value", { highlighted: highlighted })}
    >
      {value}
    </span>
  </div>
);

export const CartInformation = () => {
  const { cart } = useCart();

  return (
    <div className="shopping-cart-cart-information is-size-7">
      {!isCartEmptyOrHasUserUnsavedCart(cart) && (
        <div className="shopping-cart-save-text">
          {!cart.readOnly && (
            <div
              className="auto-save-information is-size-7"
              data-label-id={`information-saves_automatically_information`}
            >
              {t("saves_automatically_information")}
            </div>
          )}
        </div>
      )}
      <div>
        {!cart.quote && (
          <div data-label-id={`information-shopping_cart_is_no_quote`}>
            {t("shopping_cart_is_no_quote")}
          </div>
        )}
      </div>
      <div className="shopping-cart-info-label has-text-right">
        <InfoTextWrapper
          key={"update_price_button_label"}
          label={t("update_price_button_label")}
          value={getFormattedDate(cart.priceDate)}
          highlighted={dateIsOlderThanSixWeeks(new Date(cart.priceDate))}
        />
      </div>
    </div>
  );
};
