import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import { equals, has, length, map, not, pathOr, propEq } from "ramda";

import { L10n } from "@encoway/l10n";
import { Icon, Viewport } from "@encoway/react-components";
import { isReady } from "@encoway/rest-api";

import {
  ConfiguratorComponentProps,
  ContainerTO,
} from "../../../types/configuration";
import { selectTab } from "./helper/tabsHelper";
import { useConfiguratorTabs } from "./hook/useConfiguratorTabs";
import { containerIsEmpty } from "./utils/tabsUtils";

import "./configuratorTabs.scss";

const getSections = (
  props: ConfiguratorComponentProps<ContainerTO>,
  currentTab: ContainerTO,
) => {
  const viewport = Viewport.parse(
    currentTab.name,
    Viewport.orElse(currentTab.viewPort!, "section"),
  );
  return Viewport.instance(viewport.name, {
    key: currentTab.id || `injected_${currentTab.name}`,
    data: has("id", currentTab) ? currentTab : props.data,
    style: viewport.style,
    options: props.options,
    // @ts-expect-error does not exist on type
    error: props.error,
    mediaLink: props.mediaLink,
    onValueChanged: props.onValueChanged,
    onFocus: props.onFocus,
    level: 1,
  });
};

type MenuItemProps = {
  containerFilter: ((container: ContainerTO) => boolean) | undefined;
  props: ConfiguratorComponentProps<ContainerTO>;
  currentTab: string;
  container: ContainerTO;
  iconPosition: string;
  setCurrentTab: (tabName: string) => void;
};

const MenuItem = ({
  containerFilter,
  props,
  currentTab,
  container,
  iconPosition,
  setCurrentTab,
}: MenuItemProps) => {
  if (
    (containerFilter && containerFilter(container) === false) ||
    containerIsEmpty(container)
  ) {
    return null;
  }
  const ready = isReady(container);
  const classNames = classnames("menu-item", {
    "is-active": propEq(currentTab, "name", container),
    "is-mandatory": not(ready),
    "is-ready": ready,
  });
  const vp = Viewport.parse(`${props.data.name}State`, "containerState");
  return (
    <li key={container.name}>
      <button
        onClick={() =>
          selectTab(
            props.options.eventBus,
            currentTab,
            setCurrentTab,
            container.name,
          )
        }
        data-tab-id={container.name}
        data-ready-state-id={ready ? "READY" : "NOT_READY"}
        className={classNames}
      >
        {equals(iconPosition, "left") && (
          <span>{container.translatedName}</span>
        )}
        {Viewport.instance(vp.name, { ...props, ...{ data: container } })}
        {!equals(iconPosition, "left") && (
          <span>{container.translatedName}</span>
        )}
      </button>
    </li>
  );
};

type MenuItemsProps = {
  props: ConfiguratorComponentProps<ContainerTO>;
  currentTab: string;
  containerFilter: ((container: ContainerTO) => boolean) | undefined;
  setCurrentTab: (tabName: string) => void;
  iconPosition: string;
};

function MenuItems({
  props,
  currentTab,
  containerFilter,
  setCurrentTab,
  iconPosition,
}: Readonly<MenuItemsProps>) {
  return (
    <>
      {map(
        (container: ContainerTO) => (
          <MenuItem
            key={container.id}
            containerFilter={containerFilter}
            props={props}
            currentTab={currentTab}
            container={container}
            iconPosition={iconPosition}
            setCurrentTab={setCurrentTab}
          />
        ),
        pathOr([], ["data", "children"], props),
      )}
    </>
  );
}
export type InjectedItem = {
  name: string;
  translatedName: string;
  icon: string;
};

type CustomMenuItemProps = {
  currentTab: string;
  item: InjectedItem;
  iconPosition: string;
  eventBus: any;
  setCurrentTab: React.Dispatch<React.SetStateAction<string | null>>;
};

const CustomMenuItem = ({
  currentTab,
  item,
  iconPosition,
  eventBus,
  setCurrentTab,
}: CustomMenuItemProps) => (
  <li key={item.name}>
    <button
      onClick={() => selectTab(eventBus, currentTab, setCurrentTab, item.name)}
      className={classnames("menu-item", {
        "is-active": propEq(currentTab, "name", item),
      })}
    >
      {equals(iconPosition, "left") && <span>{item.translatedName}</span>}
      {item.icon && (
        <span className="icon is-small">
          <Icon src={item.icon} />
        </span>
      )}
      {!equals(iconPosition, "left") && <span>{item.translatedName}</span>}
    </button>
  </li>
);

type NavigationProps = {
  previous: string | null;
  previousClickHandler: () => void | null;
  next: string | null;
  nextClickHandler: () => void | null;
};

const Navigation = ({
  previous,
  previousClickHandler,
  next,
  nextClickHandler,
}: NavigationProps) => (
  <nav
    className="configurator-pagination pagination is-centered"
    role="navigation"
    aria-label="pagination"
  >
    <button
      className="pagination-previous"
      disabled={previous === null}
      data-button-id={previous}
      onClick={previousClickHandler}
    >
      <FontAwesomeIcon icon={faAngleLeft} width="7px" />
      <span className="has-margin-left is-hidden-mobile">
        {L10n.format("previous_page")}
      </span>
    </button>
    <button
      className="pagination-next"
      disabled={next === null}
      data-button-id={next}
      onClick={nextClickHandler}
    >
      <span className="has-margin-right is-hidden-mobile">
        {L10n.format("next_page")}
      </span>
      <FontAwesomeIcon icon={faAngleRight} width="7px" />
    </button>
    <ul className="pagination-list" />
  </nav>
);

type TabProps = {
  tabStyle: string;
  props: ConfiguratorTabsProps;
  currentTab: string;
  iconPosition: string;
  setCurrentTab: React.Dispatch<React.SetStateAction<string | null>>;
  containerFilter: ((container: ContainerTO) => boolean) | undefined;
};

const Tab = ({
  tabStyle,
  props,
  currentTab,
  iconPosition,
  setCurrentTab,
  containerFilter,
}: TabProps) => (
  <div className={tabStyle}>
    <ul className="tab-container">
      {map(
        (item: InjectedItem) => (
          <CustomMenuItem
            key={currentTab}
            currentTab={currentTab}
            item={item}
            iconPosition={iconPosition}
            eventBus={props.options.eventBus}
            setCurrentTab={setCurrentTab}
          />
        ),
        pathOr([], ["inject", "before"], props),
      )}
      <MenuItems
        props={props}
        currentTab={currentTab}
        containerFilter={containerFilter}
        setCurrentTab={setCurrentTab}
        iconPosition={iconPosition}
      />
      {map(
        (item: InjectedItem) => (
          <CustomMenuItem
            key={currentTab}
            currentTab={currentTab}
            item={item}
            iconPosition={iconPosition}
            eventBus={props.options.eventBus}
            setCurrentTab={setCurrentTab}
          />
        ),
        pathOr([], ["inject", "after"], props),
      )}
    </ul>
  </div>
);

export type ConfiguratorTabsProps = Readonly<
  ConfiguratorComponentProps<ContainerTO>
> & {
  inject: {
    before: InjectedItem[];
    after: InjectedItem[];
  };
};

export function ConfiguratorTabs(props: ConfiguratorTabsProps) {
  const {
    style,
    tabStyle,
    containerFilter,
    current,
    currentTab,
    setCurrentTab,
    previous,
    next,
    previousClickHandler,
    nextClickHandler,
    tabs,
  } = useConfiguratorTabs(props);

  if (!currentTab) {
    return <div className="container" />;
  }

  if (equals(length(tabs), 1) || equals(style.hideSingleMenu, true)) {
    return <div className="container">{getSections(props, current)}</div>;
  }

  return (
    <div className="container">
      <Tab
        tabStyle={tabStyle}
        props={props}
        currentTab={currentTab}
        iconPosition={style.iconPosition}
        setCurrentTab={setCurrentTab}
        containerFilter={containerFilter}
      />
      {getSections(props, current)}
      {style.showNavigation === true && (
        <Navigation
          previous={previous}
          previousClickHandler={previousClickHandler}
          next={next}
          nextClickHandler={nextClickHandler}
        />
      )}
    </div>
  );
}
