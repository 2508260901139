import { equals } from "ramda";

import { MODE } from "../context/AppProvider";
import { useApp } from "../hooks/useApp";

export function CurrentUserDisplay() {
  const { mode } = useApp();

  if (equals(mode, MODE.DEVELOP)) {
    return (
      <>
        <span>Aktuell geladener User: </span>
        <span className={"boldText"}>
          {window.localStorage.getItem("currentUser")}
        </span>
      </>
    );
  }
  return null;
}
