import QRCode from "qrcode";
import { equals, isNil, or } from "ramda";
import { useCallback, useEffect, useState } from "react";

import { L10n } from "@encoway/l10n";
import { VisualEditor } from "@encoway/visual-editor";

import { Loading } from "../../components/loading";
import { Config } from "../../config/config";
import { useConfiguration } from "../../hooks/useConfiguration";
import { useVisualization } from "../../hooks/useVisualization";
import { VisualizationArCodeModal } from "./visualization/visualizationArCodeModal";
import { VisualizationButtonRow } from "./visualization/visualizationButtonRow";
import { VisualizationModal } from "./visualization/visualizationModal";

import "./visualizationContainer.scss";

export function VisualizationContainer() {
  const { configurationId, bus } = useConfiguration();
  const {
    load,
    vis,
    visRef,
    modalRef,
    showVisualization,
    showArButton,
    reloadVisualizationCharacteristics,
    uploadGlb,
    showWall,
    toggleWall,
  } = useVisualization();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showArCodeModal, setShowArCodeModal] = useState(false);

  const [qrCode, setQrCode] = useState<string | null>(null);
  const [qrCodeLoading, setQrCodeLoading] = useState(false);

  const setupVisualization = useCallback(() => {
    showVisualization && load();
  }, [showVisualization, load]);

  const loadAr = async () => {
    setQrCodeLoading(true);
    setShowArCodeModal(true);
    uploadGlb().then(async () => {
      setQrCode(
        await QRCode.toDataURL(
          `${Config.ArService.baseUrl}/ar-viewer/${configurationId}`,
          { width: 300, scale: 1 },
        ),
      );
      setQrCodeLoading(false);
    });
  };

  useEffect(() => {
    setupVisualization();
  }, [showVisualization]);

  useEffect(() => {
    if (!isNil(vis?.node)) {
      reloadVisualizationCharacteristics().then();
      return bus
        .filter((e: any) =>
          or(equals(e.event, "UpdateState"), equals(e.event, "InitialState")),
        )
        .onValue(() => {
          reloadVisualizationCharacteristics().then();
        });
    }
  }, [vis]);

  if (!showVisualization) {
    return null;
  }

  if (isNil(vis.context)) {
    return <Loading className="mt-2" />;
  }

  return (
    <div className="vis-container">
      <VisualEditor
        style={{ height: "392px", marginBottom: "60px" }}
        visualization={vis.context}
        settings={Config.Visualization.settings}
        locale={L10n.currentFullLocale()}
        ref={visRef}
        onReady={() => visRef?.current?.zoom()}
      />
      <VisualizationButtonRow
        showArButton={showArButton}
        showWall={showWall}
        toggleWall={toggleWall}
        loadAr={loadAr}
        openModal={() => setShowModal(true)}
      />
      <VisualizationModal
        show={showModal}
        showWall={showWall}
        context={vis.context}
        settings={Config.Visualization.settings}
        locale={L10n.currentFullLocale()}
        modalRef={modalRef}
        closeModal={() => setShowModal(false)}
        showArModal={() => setShowArCodeModal(true)}
        toggleWall={toggleWall}
      />
      <VisualizationArCodeModal
        show={showArCodeModal}
        qrCode={qrCode}
        loading={qrCodeLoading}
        closeModal={() => setShowArCodeModal(false)}
      />
    </div>
  );
}
