import { isNil } from "ramda";
import { useContext } from "react";

import { CartContext } from "../context/CartProvider";

export function useCart() {
  const context = useContext(CartContext);
  if (isNil(context)) {
    throw new Error("useCart must be used within CartProvider");
  }
  return context;
}
