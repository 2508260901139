import { AxiosPromise } from "axios";

import {
  CartResultTO,
  LoadConfigurationResult,
  PricingTO,
  TeckentrupCartTO,
} from "../types/cart";
import { fetchGet, fetchPost } from "./fetch";

const cartPathCustom = "teckentrup-server/api/cart/custom";

type ValidationResultTO = {
  valid: boolean;
};

type SparePartPricingTO = {
  AktionsRabatt: number;
  BruttoPreis: number;
  Grundrabatt: number;
  KundenNr: string;
  MaterialNr: string;
  Teuerungszuschlag: number;
  Variante: string;
  Teorabatt: number;
};

export const validateSparePart = (
  articleId: string,
): AxiosPromise<ValidationResultTO> =>
  fetchGet<ValidationResultTO>(
    `teckentrup-server/api/cart/articles/${articleId}/_validateSparePart`,
  )()();

export const validateSparePartWithSave = (
  articleId: string,
): AxiosPromise<ValidationResultTO> =>
  fetchGet<ValidationResultTO>(
    `teckentrup-server/api/cart/articles/${articleId}/_validateSparePartWithSave`,
  )()();

export const refreshConfiguration = (
  articleId: string,
): AxiosPromise<LoadConfigurationResult> =>
  fetchGet<LoadConfigurationResult>(
    `teckentrup-server/api/cart/articles/${articleId}/_refreshConfiguration`,
  )()();

export const validateConfigurable = (
  articleId: string,
  loadingResult: LoadConfigurationResult,
): AxiosPromise<CartResultTO> =>
  fetchPost<CartResultTO>(
    `teckentrup-server/api/cart/articles/${articleId}/_validateConfiguration?map=VALIDATION`,
  )(
    {},
    { "Content-type": "application/json" },
  )(loadingResult);

export const validatePrice = (
  configurationId: string,
): AxiosPromise<PricingTO> =>
  fetchGet<PricingTO>(
    `teckentrup-server/api/pricing/${configurationId}/VariantConditionPricesForCurrentDate`,
  )()();

export const validateSparePartPrice = (
  materialNr: string,
): AxiosPromise<SparePartPricingTO> =>
  fetchGet<SparePartPricingTO>(
    `teckentrup-server/api/pricing/SparePartPrice/${materialNr}`,
  )()();

export const validateCartArticle = async () => {
  const result = await fetchGet<TeckentrupCartTO>(
    "teckentrup-server/api/cart/_validate?map=VALIDATION",
  )()();
  if (result.status !== 200) {
    return;
  }
  return result.data;
};

export const setPriceDate = async (dateObject: number): Promise<void> => {
  const priceDate = new Intl.DateTimeFormat("en", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  })
    .format(dateObject)
    .split("/")
    .map((digits) => digits.replace(/[^\d]+/g, ""));
  await fetchGet(
    `teckentrup-server/api/cart/setPriceDate/${priceDate[2]}${priceDate[0]}${priceDate[1]}`,
  )()();
};

export const shareCart = async (): AxiosPromise<string> =>
  fetchGet<string>("teckentrup-server/api/cart/quote/custom/_getShareLink")()();

/**
 * Performs a checkout on a shared quote
 *
 * @param {string} quoteId the quote ID
 * @param data the data to check out
 */
export const performCheckoutSharedQuote = async (
  quoteId: string,
  data: object,
): AxiosPromise<CartResultTO> =>
  fetchPost<CartResultTO>(
    `teckentrup-server/api/cart/sharedQuoteCheckout/${quoteId}?`,
  )()(data);

export const performCheckoutPartOrder = async (
  data: object,
): AxiosPromise<CartResultTO> =>
  await fetchPost<CartResultTO>(
    `teckentrup-server/api/cart/partialOrderCheckout`,
  )()(data);

export const performDbCheck = async (quoteId: string): Promise<void> => {
  await fetchGet(
    `teckentrup-server/api/contributionMargin/contributionMarginCheck/${quoteId}`,
  )()();
};

export const duplicateArticle = async (
  id: string,
  language: string,
): Promise<CartResultTO> => {
  return (
    await fetchPost<CartResultTO>(`${cartPathCustom}/_duplicate/${id}?map=UI`)(
      {},
      { "Accept-Language": language },
    )()
  ).data;
};
