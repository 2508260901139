import { LvTable } from "../../../hooks/useLvTable";
import { mapIndexed } from "../../../utilities/utilities";
import { LvRowComponent } from "./LvRowComponent";

import "./LvTable.scss";

type LvTableProps = {
  lvTable: LvTable;
};

export const LvTableComponent = ({ lvTable }: LvTableProps) => {
  return (
    <table id="lvTable" className="table is-fullwidth">
      <tbody>
        {mapIndexed(
          (row, rowIndex) => (
            <LvRowComponent
              key={row.detail.value}
              lvTable={lvTable}
              row={row}
              rowIndex={rowIndex}
            />
          ),
          lvTable.lvRows,
        )}
      </tbody>
    </table>
  );
};
