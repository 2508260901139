import {
  append,
  filter,
  forEach,
  includes,
  isEmpty,
  length,
  path,
  without,
} from "ramda";
import { useEffect, useMemo, useState } from "react";

import { Search } from "@encoway/rest-api";

import { Loading } from "../../components/loading";
import { useApp } from "../../hooks/useApp";
import { hasRightToSeeAllProducts } from "../../http/identityHelper";
import { notNilEmpty } from "../offerManagement/offerManagementUtils";
import { CategorySelection } from "./categorySelection/categorySelection";
import ProductSelection from "./productSelection";
import {
  buildFilters,
  getDefaultSelection,
  getNonLimitedSelection,
  mergeForCategory,
  mergeWithFavorites,
  mutateForCategory,
  mutateForPropCategory,
  removeByIdInCategory,
} from "./productSelectionUtils";

export const DEFAULT_CATEGORY = "teckentrup";
export const LOCAL_STORAGE_PRODUCT_SELECTION_KEY = "productSelection";
export const LOCAL_STORAGE_CURRENT_CATEGORY_KEY = "currentCategory";
export const MAX_DATA_GET_LIMIT = 50;

const LOCAL_STORAGE_FAVORITE_PRODUCTS_KEY = "favoriteProducts";
const SORT_KEY_FOR_MERGING = "name";

const categorySearchSettings = {
  doors: ["shortcut"],
  gates: ["shortcut"],
  industrial_gates: ["shortcut"],
  spare_parts: ["sp_materialnummer", "sp_nachfolger_fuer"],
};

export const Products = () => {
  const { catalogService, language, identityStore } = useApp();
  const [loading, setLoading] = useState(true);
  const [searchFilterLoading, setSearchFilterLoading] = useState(false);
  const [productGroups, setProductGroups] = useState([]);
  const [categoryStateProducts, setCategoryStateProducts] = useState({});
  const [categoryStateFavoriteProducts, setCategoryStateFavoriteProducts] =
    useState({});
  const [currentCategory, setCurrentCategory] = useState(
    window.localStorage.getItem(LOCAL_STORAGE_CURRENT_CATEGORY_KEY) ||
      DEFAULT_CATEGORY,
  );
  const [
    categoryStateFiltersAndSearchterm,
    setCategoryStateFiltersAndSearchterm,
  ] = useState(
    JSON.parse(
      window.localStorage.getItem(LOCAL_STORAGE_PRODUCT_SELECTION_KEY),
    ) || {},
  );
  const [favoriteProductIds, setFavoriteProductIds] = useState(
    JSON.parse(
      window.localStorage.getItem(LOCAL_STORAGE_FAVORITE_PRODUCTS_KEY),
    ) || {},
  );

  const tokenCountry = useMemo(
    () => identityStore.getIdentity().FirmadesNutzers.FirmenLand,
    [identityStore],
  );

  const tokenRoles = useMemo(() => identityStore.getRoles(), [identityStore]);

  const getSearchTerm = (category) =>
    path([category, "searchterm"], categoryStateFiltersAndSearchterm) || "";

  const getFilters = (category) =>
    path([category, "filters"], categoryStateFiltersAndSearchterm) || [];

  const getFavoriteProductIds = (category) => {
    if (notNilEmpty(favoriteProductIds[category])) {
      return favoriteProductIds[category];
    }

    return [];
  };

  const getProducts = (category) => {
    if (length(getFavoriteProductIds(category)) > 0) {
      const nonFavoriteProducts = path([category], categoryStateProducts) || [];
      const combinedProducts = mergeWithFavorites(
        category,
        categoryStateFavoriteProducts,
        nonFavoriteProducts,
        getSearchTerm(category),
        getFilters(category),
      );
      return path([category], combinedProducts);
    }

    return path([category], categoryStateProducts);
  };

  const toggleFavorite = (product) => {
    const productId = product.id;
    const categoryFavoriteIds = getFavoriteProductIds(currentCategory);
    let newCategoryFavorites;
    if (!includes(productId, categoryFavoriteIds)) {
      newCategoryFavorites = append(productId, categoryFavoriteIds);
      setCategoryStateFavoriteProducts(
        mergeForCategory(
          currentCategory,
          categoryStateFavoriteProducts,
          { products: [product] },
          SORT_KEY_FOR_MERGING,
        ),
      );
    } else {
      newCategoryFavorites = without([productId], categoryFavoriteIds);
      setCategoryStateFavoriteProducts(
        removeByIdInCategory(
          currentCategory,
          categoryStateFavoriteProducts,
          productId,
        ),
      );
    }
    const newCategoryFavoritesObject = mutateForCategory(
      currentCategory,
      favoriteProductIds,
      newCategoryFavorites,
    );
    setFavoriteProductIds(newCategoryFavoritesObject);
    window.localStorage.setItem(
      LOCAL_STORAGE_FAVORITE_PRODUCTS_KEY,
      JSON.stringify(newCategoryFavoritesObject),
    );
  };

  const canSeeAllProducts = useMemo(
    () => hasRightToSeeAllProducts(identityStore.getBooleanAuthorities()),
    [identityStore],
  );

  const resetFiltersAndSearchterm = () => {
    const resettedState = mutateForPropCategory(
      currentCategory,
      "filters",
      mutateForPropCategory(
        currentCategory,
        "searchterm",
        categoryStateFiltersAndSearchterm,
        "",
      ),
      [],
    );

    setCategoryStateFiltersAndSearchterm(resettedState);
  };

  const buildSearch = (category, searchTerm) => {
    const search = new Search().term(`*${searchTerm.replace(/\*/g, "\\*")}*`);

    const characteristicsToSearchIn =
      path([category], categorySearchSettings) || [];

    forEach(
      (characteristic) => search.characteristic(characteristic),
      characteristicsToSearchIn,
    );

    return search;
  };

  const getProductsForCurrentFilterQuery = async (
    category,
    searchTerm,
    filters,
    limit = MAX_DATA_GET_LIMIT,
    offset = 0,
  ) => {
    const selection = getDefaultSelection(limit, offset);
    !isEmpty(searchTerm) && selection.search(buildSearch(category, searchTerm));

    selection.filter(
      buildFilters(
        category,
        filters,
        tokenCountry,
        tokenRoles,
        canSeeAllProducts,
      ),
    );

    return catalogService.products(selection);
  };
  const getFavoriteProductsForCurrentFilterQuery = async (
    category,
    searchTerm,
    filters,
  ) => {
    const categoryFavoriteProductIds = getFavoriteProductIds(category);
    const selection = getNonLimitedSelection(
      length(categoryFavoriteProductIds),
    );
    selection.filter(
      buildFilters(
        category,
        filters,
        tokenCountry,
        tokenRoles,
        canSeeAllProducts,
        categoryFavoriteProductIds,
      ),
    );
    const filteredProducts = catalogService.products(selection);
    return filteredProducts;
  };

  const getVisibleProductGroups = async (productGroupsResult) =>
    Promise.all(
      productGroupsResult.map(({ id }) =>
        getProductsForCurrentFilterQuery(id, "", [], 1),
      ),
    ).then((results) =>
      productGroupsResult.filter(
        (value, index) => length(results[index].products) > 0,
      ),
    );

  const manageFilters = (value, characteristicId) => {
    const currentFilters = getFilters(currentCategory);

    const filteredFilters = filter(
      (productFilter) => productFilter.characteristicId !== characteristicId,
      currentFilters,
    );

    const newFilters =
      value === "__undo__"
        ? filteredFilters
        : append({ characteristicId, value }, filteredFilters);

    setCategoryStateFiltersAndSearchterm(
      mutateForPropCategory(
        currentCategory,
        "filters",
        categoryStateFiltersAndSearchterm,
        newFilters,
      ),
    );
  };

  const setSearchTerm = (searchterm) => {
    setSearchFilterLoading(true);
    setCategoryStateFiltersAndSearchterm(
      mutateForPropCategory(
        currentCategory,
        "searchterm",
        categoryStateFiltersAndSearchterm,
        searchterm,
      ),
    );
  };

  useEffect(() => {
    if (loading || isEmpty(categoryStateProducts)) {
      (async () => {
        setLoading(true);
        catalogService
          .productGroup("teckentrup")
          .then(async (result) => {
            const visibleProductGroups = await getVisibleProductGroups(
              result.productGroups,
            );
            setProductGroups(visibleProductGroups);
            let categoryStateObject = {};
            let favoriteStateObject = {};

            for (let index = 0; index < length(visibleProductGroups); index++) {
              const currentGroupId = visibleProductGroups[index].id;
              try {
                if (
                  isEmpty(categoryStateFavoriteProducts) ||
                  !categoryStateFavoriteProducts[currentGroupId]
                ) {
                  const favoriteProducts =
                    await getFavoriteProductsForCurrentFilterQuery(
                      currentGroupId,
                      getSearchTerm(currentGroupId),
                      getFilters(currentGroupId),
                    );
                  favoriteStateObject = mutateForCategory(
                    currentGroupId,
                    favoriteStateObject,
                    favoriteProducts,
                  );
                }
                const productObject = await getProductsForCurrentFilterQuery(
                  currentGroupId,
                  getSearchTerm(currentGroupId),
                  getFilters(currentGroupId),
                );
                categoryStateObject = mutateForCategory(
                  currentGroupId,
                  categoryStateObject,
                  productObject,
                );
              } catch (e) {
                window.localStorage.removeItem(
                  LOCAL_STORAGE_PRODUCT_SELECTION_KEY,
                );
                setCategoryStateFiltersAndSearchterm({});
                if (
                  isEmpty(categoryStateFavoriteProducts) ||
                  !categoryStateFavoriteProducts[currentGroupId]
                ) {
                  const favoriteProducts =
                    await getFavoriteProductsForCurrentFilterQuery(
                      currentGroupId,
                      getSearchTerm(currentGroupId),
                      [],
                    );
                  favoriteStateObject = mutateForCategory(
                    currentGroupId,
                    favoriteStateObject,
                    favoriteProducts,
                  );
                }
                const productObject = await getProductsForCurrentFilterQuery(
                  currentGroupId,
                  getSearchTerm(currentGroupId),
                  [],
                );
                categoryStateObject = mutateForCategory(
                  currentGroupId,
                  categoryStateObject,
                  productObject,
                );
              }
            }
            isEmpty(categoryStateFavoriteProducts) &&
              setCategoryStateFavoriteProducts(favoriteStateObject);
            setCategoryStateProducts(categoryStateObject);
            setLoading(false);
          })
          .catch((e) => {
            setLoading(false);
            console.assert(window.location.hostname === "localhost", e.message);
          });
      })();
    }
  }, [language, loading]);

  const loadMoreDataOnScroll = async () => {
    const offset = getProducts(currentCategory).products.length;
    const result = await getProductsForCurrentFilterQuery(
      currentCategory,
      getSearchTerm(currentCategory),
      getFilters(currentCategory),
      MAX_DATA_GET_LIMIT,
      offset,
    );
    setCategoryStateProducts(
      mergeForCategory(currentCategory, categoryStateProducts, result),
    );
  };

  async function loadProductSelectionFromLocalStorage() {
    const result = await getProductsForCurrentFilterQuery(
      currentCategory,
      getSearchTerm(currentCategory),
      getFilters(currentCategory),
    );
    setCategoryStateProducts(
      mutateForCategory(currentCategory, categoryStateProducts, result),
    );
    setSearchFilterLoading(false);
    window.localStorage.setItem(
      LOCAL_STORAGE_PRODUCT_SELECTION_KEY,
      JSON.stringify(categoryStateFiltersAndSearchterm),
    );
  }

  useEffect(() => {
    loadProductSelectionFromLocalStorage().catch(() => {
      window.localStorage.removeItem(LOCAL_STORAGE_PRODUCT_SELECTION_KEY);
      setCategoryStateFiltersAndSearchterm({});
    });
  }, [categoryStateFiltersAndSearchterm]);

  useEffect(() => {
    window.localStorage.setItem(
      LOCAL_STORAGE_CURRENT_CATEGORY_KEY,
      currentCategory,
    );
  }, [currentCategory]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div data-page-id="product-selection">
      <CategorySelection
        setCurrentCategory={setCurrentCategory}
        currentCategory={currentCategory}
        productGroups={productGroups}
      />
      <ProductSelection
        language={language}
        currentCategory={currentCategory}
        products={getProducts(currentCategory)}
        favoriteProductIds={getFavoriteProductIds(currentCategory)}
        filters={getFilters(currentCategory)}
        searchTerm={getSearchTerm(currentCategory)}
        manageFilters={(value, characteristicId) =>
          manageFilters(value, characteristicId)
        }
        setSearchTerm={(searchterm) => setSearchTerm(searchterm)}
        resetFiltersAndSearchterm={() => resetFiltersAndSearchterm()}
        searchFilterLoading={searchFilterLoading}
        loadMoreDataOnScroll={loadMoreDataOnScroll}
        toggleFavorite={toggleFavorite}
        loading={loading}
      />
    </div>
  );
};
