import { isNil } from "ramda";
import { useContext } from "react";

import { AppContext } from "../context/AppProvider";

export function useApp() {
  const context = useContext(AppContext);
  if (isNil(context)) {
    throw new Error("useApp must be used within AppProvider");
  }
  return context;
}
