export type TrackableEvent = [string, string, string];

export const track = (event: TrackableEvent) => {
  try {
    _paq.push(["trackEvent", ...event]);
  } catch (e: unknown) {}
};

type AnyFunction = (...args: any[]) => unknown;

export const trackFunction = <T extends AnyFunction>(
  func: T,
  event: TrackableEvent,
) => {
  return ((...args: Parameters<T>) => {
    const ret = func(...args) as ReturnType<T>;
    track(event);
    return ret;
  }) as T;
};

export const printOfferEvent: TrackableEvent = [
  "Drucken",
  "Angebot drucken",
  "Angebot drucken",
];
export const orderRequestSentEvent: TrackableEvent = [
  "Bestellanfrage",
  "Bestellanfrage absenden",
  "Anfrage abgesendet",
];
export const exportExcelFileEvent: TrackableEvent = [
  "Drucken",
  "Exceldatei exportieren",
  "Exceldatei exportieren",
];
export const addFreePositionEvent: TrackableEvent = [
  "Position",
  "Freie Position hinzufügen",
  "Freie Position hinzufügen",
];
export const deliveryTimeChangeEvent: TrackableEvent = [
  "Lieferzeitenwechsel",
  "Lieferzeitenwechsel",
  "Lieferzeitenwechsel",
];
export const createGroupEvent: TrackableEvent = [
  "Ordner anlegen",
  "Ordner anlegen",
  "Ordner anlegen",
];
export const addPositionEvent: TrackableEvent = [
  "Position",
  "Position hinzufügen",
  "Position hinzufügen",
];
export const copyPositionEvent: TrackableEvent = [
  "Position",
  "Position kopieren",
  "Position kopieren",
];
export const updatePricesEvent: TrackableEvent = [
  "Preise",
  "Preise aktualisieren",
  "Preise aktualisieren",
];
export const reconfigureEvent: TrackableEvent = [
  "Konfiguration",
  "Rekonfigurieren",
  "Rekonfigurieren",
];
export const saveProcessEvent: TrackableEvent = [
  "Vorgang",
  "Vorgang speichern",
  "Vorgang speichern",
];
export const saveProcessAndGoToQuoteManagementEvent: TrackableEvent = [
  "Vorgang",
  "Vorgang speichern und zur Angebotsverwaltung",
  "Vorgang speichern und zur Angebotsverwaltung",
];
export const requestDrawingEvent: TrackableEvent = [
  "Zeichnung",
  "Zeichnung anfordern",
  "Zeichnung anfordern",
];
export const copyProcessEvent: TrackableEvent = [
  "Vorgang",
  "Vorgang kopieren",
  "Vorgang kopieren",
];
export const shareConfigurationEvent: TrackableEvent = [
  "Konfiguration",
  "Konfiguration teilen",
  "Konfiguration teilen",
];
export const copyConfigurationEvent: TrackableEvent = [
  "Konfiguration",
  "Konfiguration starten",
  "Konfiguration starten",
];
