import { t } from "../../../../localizationUtils";

import "./deleteCartItemWarningPopup.scss";

type DeleteCartItemWarningPopupProps = {
  label: string;
  onDelete: () => void;
  onCancel: () => void;
};

export const DeleteCartItemWarningPopup = ({
  label,
  onDelete,
  onCancel,
}: DeleteCartItemWarningPopupProps) => (
  <span className="warning-container">
    <div className="sec-warn is-visible" data-dialog-id="delete_warn">
      {label}
      <div className="buttons">
        <button
          data-button-id="accept_delete"
          className="button is-info is-pulled-right is-orange"
          onClick={onDelete}
        >
          {t("yes")}
        </button>
        <button
          data-button-id="cancel_delete"
          className="button is-info is-blue-dark"
          onClick={onCancel}
        >
          {t("no")}
        </button>
      </div>
    </div>
  </span>
);
