import { useNavigate } from "react-router-dom";

import {
  FlexTableCell as Cell,
  FlexTableRow as Row,
  FlexTableWrapper as Table,
} from "../../../../../components/flexTable/flexTable";
import { Loading } from "../../../../../components/loading";
import {
  Modal,
  ModalBodyWrapper,
  ModalFooter,
  ModalHeader,
} from "../../../../../components/modal/modal";
import { ProgressButton } from "../../../../../components/progressButton";
import { ROUTES } from "../../../../../http/constants";
import { mapIndexed } from "../../../../../utilities/utilities";
import { t } from "../../../localizationUtils";
import { useValidateCartModal } from "./useValidateCartModal";
import { determineItemValidationLabel } from "./validateCartUtils";

import "./validateCartModal.scss";

export const ValidateCartModal = () => {
  const navigate = useNavigate();
  const [status, cartItems, onSave] = useValidateCartModal();
  return (
    <Modal className="validate-cart-warning">
      <ModalHeader
        label={t("cart_validation_header")}
        onCancel={() => navigate(ROUTES.OFFER_MANAGEMENT, { replace: true })}
      />
      <ModalBodyWrapper label={t("cart_validation_start_text")}>
        {status.loading ? (
          <div className="loading">
            <Loading />
          </div>
        ) : (
          <Table>
            {mapIndexed(
              (cartItem, index) => (
                <Row key={cartItem.articleId}>
                  <Cell>
                    <ProgressButton
                      index={index}
                      isLoading={cartItem.validation.running}
                      isSuccess={
                        cartItem.validation.finished &&
                        cartItem.validation.valid
                      }
                    />
                  </Cell>
                  <Cell value={index + 1} />
                  <Cell value={determineItemValidationLabel(cartItem)} />
                </Row>
              ),
              cartItems,
            )}
          </Table>
        )}
      </ModalBodyWrapper>
      <ModalFooter
        disabled={!status.finished}
        saveLabel={t("ok")}
        onSave={onSave}
      />
    </Modal>
  );
};
