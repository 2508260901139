import { isNil } from "ramda";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Loading } from "../../components/loading";
import { useConfiguration } from "../../hooks/useConfiguration";
import { Configuration } from "./configuration";
import { isReconfigurationSetupFailed } from "./configurationUtils";
import { ReconfiguringArticleLoadingModal } from "./reconfiguringArticleLoadingModal";

export function ReconfigurationFromId() {
  const {
    articleId,
    articleName,
    cartPosition,
    configurationId,
    articleOrderStatus,
  } = useParams();
  const { restart, loading, reconfiguration } = useConfiguration();
  const [showModal, setShowModal] = useState(true);

  useEffect(() => {
    (async () => {
      if (isNil(configurationId)) {
        await restart({
          configurationId,
          articleId: articleId!,
          articleName: articleName!,
          cartPosition: cartPosition!,
          articleOrderStatus,
        });
      }
    })();
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (showModal && isReconfigurationSetupFailed(reconfiguration)) {
    return (
      <ReconfiguringArticleLoadingModal onClose={() => setShowModal(false)} />
    );
  }

  return <Configuration />;
}
