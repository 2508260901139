import { useState } from "react";

import { L10n } from "@encoway/l10n";

import {
  Modal,
  ModalBodyWrapper,
  ModalFooter,
  ModalHeader,
} from "../../../../../../components/modal/modal";
import { useCart } from "../../../../../../hooks/useCart";
import { hasRightToSeeCommentaryExtra } from "../../../../../../http/identityHelper";
import { t } from "../../../../localizationUtils";

type CartItemCommentModalProps = {
  onCancel: () => void;
  onSubmit: (value: string) => () => void;
  comment: string;
};

export const CartItemCommentModal = ({
  onCancel,
  onSubmit,
  comment,
}: CartItemCommentModalProps) => {
  const { cart } = useCart();
  const [textAreaValue, setTextAreaValue] = useState(comment || "");
  const labelExtra = hasRightToSeeCommentaryExtra(
    cart.authorities.booleanAuthorities,
  )
    ? L10n.format("will_not_be_transmitted_to_Teckentrup")
    : "";

  return (
    <Modal>
      <ModalHeader
        label={`${t("offer_management_edit_comment_title")} ${labelExtra}`}
        onCancel={onCancel}
      />
      <ModalBodyWrapper>
        <textarea
          className="textarea"
          onChange={(event) => setTextAreaValue(event.target.value)}
          value={textAreaValue}
        />
      </ModalBodyWrapper>
      <ModalFooter
        saveLabel={t("submit")}
        cancelLabel={t("cancel")}
        onCancel={onCancel}
        onSave={onSubmit(textAreaValue)}
      />
    </Modal>
  );
};
