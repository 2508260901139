import "./modalTag.scss";

type ModalTagProps = {
  value: string;
  label: string;
  onResetCustomerNumberTag: () => void;
};

export const ModalTag = ({
  value = "",
  label,
  onResetCustomerNumberTag,
}: ModalTagProps) => (
  <div className="column-entry with-padding">
    <label className="label">{label}</label>
    <div className="input-field-fake">
      <div className="tag is-medium is-flex is-justify-content-space-between">
        {value}
        <button
          className="delete is-small"
          onClick={onResetCustomerNumberTag}
        />
      </div>
    </div>
  </div>
);
