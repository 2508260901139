import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { map } from "ramda";
import { useState } from "react";

import { L10n } from "@encoway/l10n";

import { BurgerMenuAction } from "./offerManagementTableUtils";

import "./itemRowMenu.scss";

const ItemRowMenuItem = ({ action, icon, label }: BurgerMenuAction) => (
  <button
    className="dropdown-item"
    onMouseDown={action}
    data-context-menu-entry={label}
  >
    <span className={`image-icon ${icon}`} />
    <span>{L10n.format(label)}</span>
  </button>
);

type ItemRowMenuProps = {
  actions: BurgerMenuAction[];
  menuId: string;
  toggle?: boolean;
  toggleDropdown?: (dropDownActive?: boolean) => void;
};

export const ItemRowMenu = ({
  actions,
  menuId,
  toggle,
  toggleDropdown,
}: ItemRowMenuProps) => {
  const [dropdownActive, setDropdownActive] = useState(false);

  const toggleDropdownActiveOnClick = () => setDropdownActive(!dropdownActive);

  return (
    <div
      id="itemRowMenu"
      className={classNames("dropdown is-right", {
        "is-active": toggle ?? dropdownActive,
      })}
    >
      <div className="dropdown-trigger">
        <button
          className="button"
          data-button-id={menuId}
          onClick={
            toggleDropdown
              ? () => toggleDropdown()
              : toggleDropdownActiveOnClick
          }
          onBlur={
            toggleDropdown
              ? () => toggleDropdown(false)
              : () => setDropdownActive(false)
          }
        >
          <span className="icon is-small">
            <FontAwesomeIcon icon={faEllipsisVertical} />
          </span>
        </button>
      </div>
      <div className="dropdown-menu" data-context-menu={menuId}>
        <div className="dropdown-content">
          {map(
            ({ label, ...props }) => (
              <ItemRowMenuItem key={label} label={label} {...props} />
            ),
            actions,
          )}
        </div>
      </div>
    </div>
  );
};
