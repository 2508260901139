import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isEmpty, not, split } from "ramda";
import { useMemo } from "react";

import { LvTable } from "../../../hooks/useLvTable";
import { LvEmptyRow, LvRow } from "../../../types/lvTable";
import { getReferenceComment, isLvEmptyRow } from "../../../utilities/lvUtils";
import { t } from "../../shoppingCart/localizationUtils";
import { LvInputField } from "./components/LvInputField";
import { LvProductField } from "./components/LvProductField";

type LvTableRowProps = {
  row: LvRow;
  rowIndex: number;
  lvTable: LvTable;
  scrollToPosition: (elementId: string) => (() => void) | undefined;
};

const LvTableRow = ({
  row,
  rowIndex,
  lvTable,
  scrollToPosition,
}: LvTableRowProps) => {
  const {
    toggleLvRowChecked,
    updateLvRowValue,
    updateLvRowProduct,
    updateLvRowParameters,
  } = lvTable;
  const referenceComment = useMemo(
    () => getReferenceComment(row.parameters),
    [row.parameters],
  );
  return (
    <tr>
      <td>
        <div className="is-flex justify-content-center mt-2">
          <input
            className="checkbox"
            type="checkbox"
            checked={row.checked}
            onChange={() => toggleLvRowChecked(rowIndex)}
            disabled={isEmpty(row.product.value)}
          />
        </div>
      </td>
      <td>
        <LvInputField
          name={row["position"].name}
          value={row["position"].value}
          setValue={updateLvRowValue(rowIndex, "position")}
        />
        <LvInputField
          name={row["reference"].name}
          value={row["reference"].value}
          setValue={updateLvRowValue(rowIndex, "reference")}
        />
        <LvInputField
          name={row["quantity"].name}
          value={row["quantity"].value}
          setValue={updateLvRowValue(rowIndex, "quantity")}
        />
        {not(isEmpty(referenceComment)) && (
          <textarea
            className="textarea is-small"
            value={referenceComment}
            data-text-area-id="lv_reference_comment"
            style={{ paddingTop: 5, paddingBottom: 5, minHeight: "unset" }}
            rows={3}
            readOnly
          />
        )}
      </td>
      <td>
        <LvProductField
          rowIndex={rowIndex}
          lvRow={row}
          updateLvRowProduct={updateLvRowProduct}
          updateLvRowParameters={updateLvRowParameters}
        />
      </td>
      <td>
        <button
          className="button is-small is-info is-blue-dark"
          onClick={() => scrollToPosition(row.detail.value)}
        >
          {t("offer_management_lv_position")} {split("_", row.detail.value)[1]}
        </button>
      </td>
    </tr>
  );
};

type LvEmptyTableRowProps = {
  row: LvEmptyRow;
  rowIndex: number;
  lvTable: LvTable;
  scrollToPosition: (elementId: string) => (() => void) | undefined;
};

const LvEmptyTableRow = ({
  row,
  rowIndex,
  lvTable,
  scrollToPosition,
}: LvEmptyTableRowProps) => {
  const { updateLvRowValue } = lvTable;
  return (
    <tr>
      <td>
        <div
          className="is-flex justify-content-center mt-1-5"
          title={t("offer_management_lv_empty_position")}
        >
          <FontAwesomeIcon
            icon={faCircleExclamation}
            className="is-blue-dark-color"
          />
        </div>
      </td>
      <td>
        <LvInputField
          name={row["position"].name}
          value={row["position"].value}
          setValue={updateLvRowValue(rowIndex, "position")}
        />
      </td>
      <td>
        <textarea
          className="textarea is-small"
          value={row.text}
          data-text-area-id="lv_original_text"
          style={{ paddingTop: 5, paddingBottom: 5, minHeight: "unset" }}
          rows={1}
          readOnly
        />
      </td>
      <td>
        <button
          className="button is-small is-info is-blue-dark"
          onClick={() => scrollToPosition(row.detail.value)}
        >
          {t("offer_management_lv_position")} {split("_", row.detail.value)[1]}
        </button>
      </td>
    </tr>
  );
};

type LvRowProps = {
  lvTable: LvTable;
  row: LvRow | LvEmptyRow;
  rowIndex: number;
};

export const LvRowComponent = ({ lvTable, row, rowIndex }: LvRowProps) => {
  const { lvHtml } = lvTable;

  const scrollToPosition = (elementId: string) => {
    const htmlWindow = window.open();
    if (!htmlWindow) {
      return;
    }
    htmlWindow.document.body.innerHTML = lvHtml;
    const position = htmlWindow.document.getElementById(elementId);
    // Scrolling does not work if it is not in setTimeout
    // Checking for htmlDocument.readyState is not working
    const scrollTimer = setTimeout(() => {
      position?.scrollIntoView({ block: "start", behavior: "smooth" });
    }, 10);
    return () => {
      clearTimeout(scrollTimer);
    };
  };

  if (isLvEmptyRow(row)) {
    return (
      <LvEmptyTableRow
        row={row}
        rowIndex={rowIndex}
        lvTable={lvTable}
        scrollToPosition={scrollToPosition}
      />
    );
  }

  return (
    <LvTableRow
      row={row}
      rowIndex={rowIndex}
      lvTable={lvTable}
      scrollToPosition={scrollToPosition}
    />
  );
};
