import classNames from "classnames";
import type { PDFDocumentProxy } from "pdfjs-dist";
import { toLower } from "ramda";
import { ChangeEvent, Dispatch, SetStateAction, useRef, useState } from "react";
import { Document, pdfjs } from "react-pdf";

import { ModalBodyWrapper, ModalFooter } from "../../../components/modal/modal";
import { Config } from "../../../config/config";
import { LvTable } from "../../../hooks/useLvTable";
import { importLvFile } from "../../../service/lvService";
import { LV_TAB_ID, LvTabId } from "../../../utilities/lvUtils";
import { t } from "../../shoppingCart/localizationUtils";
import { PdfDocument, PdfDocumentSkeleton } from "./PdfDocument";

pdfjs.GlobalWorkerOptions.workerSrc = `${Config.LvImport.baseUrl}${Config.LvImport.pdfWorkerSrc}`;

const VALID_LV_FILE_TYPES = [".pdf", ".doc", ".docx", ".xls", ".xlsx"];

const InitialLvImportOptions = {
  firstPage: "1",
  lastPage: "0",
  headerHeightCm: "0",
  footerHeightCm: "0",
};

export type LvImportOptions = typeof InitialLvImportOptions;

type LvImportTabProps = {
  isHidden: boolean;
  lvTable: LvTable;
  selectedFile: File | null;
  setSelectedFile: Dispatch<SetStateAction<File | null>>;
  onCancel: () => void;
  setSelectedTab: Dispatch<SetStateAction<LvTabId>>;
};

export const LvImportTab = ({
  isHidden,
  lvTable,
  selectedFile,
  setSelectedFile,
  onCancel,
  setSelectedTab,
}: LvImportTabProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [lvImportOptions, setLvImportOptions] = useState(
    InitialLvImportOptions,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [alreadyImported, setAlreadyImported] = useState(false);
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (fileList && fileList.length > 0) {
      const file = fileList[0];
      const fileExtension = `.${file.name.split(".").pop()}`;
      if (VALID_LV_FILE_TYPES.includes(toLower(fileExtension))) {
        setSelectedFile(file);
        setErrorMessage("");
      } else {
        setSelectedFile(null);
        setErrorMessage("offer_management_create_lv_error_format");
      }
      setCurrentPage(1);
      setTotalPages(0);
      setLvImportOptions(InitialLvImportOptions);
      unsetAlreadyImported();
    }
  };

  const handleInputClick = () => {
    hiddenFileInput.current?.click();
  };

  const handleUploadClick = async () => {
    if (!selectedFile) {
      return;
    }
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("firstPage", lvImportOptions.firstPage);
      formData.append("lastPage", lvImportOptions.lastPage);
      formData.append("headerHeightCm", lvImportOptions.headerHeightCm);
      formData.append("footerHeightCm", lvImportOptions.footerHeightCm);
      const { data } = await importLvFile(formData);
      await lvTable.initializeLvRows(
        data.html,
        data.tableContent,
        data.fileName,
      );
      setErrorMessage("");
      setAlreadyImported(true);
      setSelectedTab(LV_TAB_ID.LV_POSITIONS);
    } catch (error) {
      setErrorMessage("offer_management_create_lv_error_upload");
      unsetAlreadyImported();
    } finally {
      setIsLoading(false);
    }
  };

  const setLvImportOptionsValue = (
    name: keyof LvImportOptions,
    value: string | number,
  ) => {
    setLvImportOptions({ ...lvImportOptions, [name]: String(value) });
  };

  const handleDocumentLoadSuccess = ({ numPages }: PDFDocumentProxy) => {
    setLvImportOptionsValue("lastPage", numPages);
    setTotalPages(numPages);
  };

  const unsetAlreadyImported = () => {
    lvTable.clearLvTableState();
    setAlreadyImported(false);
  };

  return (
    <>
      <ModalBodyWrapper className={classNames({ "is-hidden": isHidden })}>
        <input
          ref={hiddenFileInput}
          id="file"
          type="file"
          accept={VALID_LV_FILE_TYPES.join(",")}
          className="is-hidden"
          onChange={handleFileChange}
        />
        <Document
          file={selectedFile}
          onLoadSuccess={handleDocumentLoadSuccess}
          error=""
          onLoadError={(error) =>
            console.error("LoadError", error.message, { error })
          }
          onSourceError={(error) =>
            console.error("SourceError", error.message, { error })
          }
          noData={
            <PdfDocumentSkeleton
              errorMessage={errorMessage}
              handleInputClick={handleInputClick}
            />
          }
          loading={
            <PdfDocumentSkeleton errorMessage={errorMessage} isLoading />
          }
        >
          <PdfDocument
            selectedFile={selectedFile}
            currentPage={currentPage}
            totalPages={totalPages}
            lvImportOptions={lvImportOptions}
            setCurrentPage={setCurrentPage}
            setLvImportOptionsValue={setLvImportOptionsValue}
            unsetAlreadyImported={unsetAlreadyImported}
            handleInputClick={handleInputClick}
            errorMessage={errorMessage}
          />
        </Document>
      </ModalBodyWrapper>
      <ModalFooter
        className={classNames({ "is-hidden": isHidden })}
        cancelLabel={t("cancel")}
        saveLabel={t("next")}
        saveButtonId="lv_import_next"
        onCancel={onCancel}
        onSave={
          alreadyImported
            ? () => setSelectedTab(LV_TAB_ID.LV_POSITIONS)
            : handleUploadClick
        }
        disabled={!selectedFile}
        isLoading={isLoading}
      />
    </>
  );
};
