import { DragEvent, useState } from "react";

import { CartActions, TeckentrupCartArticleTO } from "../../../types/cart";
import {
  determineParentIndex,
  isBothGroup,
} from "./utils/cartDragAndDropUtils";

export type DragCartArticleTO = TeckentrupCartArticleTO & {
  parentId: string;
};

/**
 * useCartDragAndDrop is a simple hook to provide drag and drop features for cart Items
 * @param {CartActions} cartActions cartActions to fire articleMove
 * @returns {function(cartItem: {object},  parentId: {string})}
 * @returns {object} the final drag methods
 */
export const useCartDragAndDrop = (cartActions: CartActions) => {
  const [dragEnterItem, setDragEnterItem] = useState<DragCartArticleTO | null>(
    null,
  );
  const [dragStartItem, setDragStartItem] =
    useState<TeckentrupCartArticleTO | null>(null);

  const onDragEnd =
    (draggedItem: TeckentrupCartArticleTO, rootId: string) => () => {
      if (
        dragEnterItem?.parentId &&
        !isBothGroup(draggedItem, dragEnterItem, rootId)
      ) {
        cartActions
          .moveArticle(
            draggedItem.articleId,
            dragEnterItem.parentId,
            determineParentIndex(dragEnterItem),
          )
          .then();
      }

      setDragStartItem(null);
    };

  /**
   * Is fired if the item is dragged into something
   * @param {TeckentrupCartArticleTO} cartItem the cartItem object who its dragged into
   * @param {string} parentId the parent id of the object who its dragged into
   * @returns {function}
   */
  const onDragEnter =
    (cartItem: TeckentrupCartArticleTO, parentId: string) => () => {
      setDragEnterItem({ ...cartItem, parentId });
    };

  const onDragOver =
    (cartItem: TeckentrupCartArticleTO, parentId: string, rootId: string) =>
    (event: DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      const dragOverItem = { ...cartItem, parentId };
      const isDroppable =
        !isBothGroup(dragStartItem, dragOverItem, rootId) ||
        cartItem.articleId === dragStartItem?.articleId;
      if (!isDroppable) {
        event.dataTransfer.dropEffect = "none";
      }
    };

  const onDragStart = (cartItem: TeckentrupCartArticleTO) => () => {
    setDragStartItem(cartItem);
  };

  return (
    cartItem: TeckentrupCartArticleTO,
    parentId: string,
    rootId: string,
  ) => ({
    onDragEnter: onDragEnter(cartItem, parentId),
    onDragEnd: onDragEnd(cartItem, rootId),
    onDragOver: onDragOver(cartItem, parentId, rootId),
    onDragStart: onDragStart(cartItem),
  });
};

export type CartDragAndDropReturn = ReturnType<typeof useCartDragAndDrop>;
