import {
  all,
  any,
  equals,
  filter,
  find,
  head,
  isEmpty,
  isNil,
  length,
  map,
  path,
  pathOr,
} from "ramda";

import IdentityStore from "../http/identityStore";
import {
  BasedOn,
  cartViews,
  CartViewsState,
  LOCAL_STORAGE_CART_VIEW_BASED_ON_KEY,
  LOCAL_STORAGE_CART_VIEW_KEY,
  ViewName,
} from "../pages/shoppingCart/hooks/useCartView";
import { getQuotes } from "../service/offerManagementService";
import {
  ExtendedTeckentrupCartTO,
  LocalizedShippingMethodTO,
  TeckentrupCartArticleTO,
  TeckentrupCartTO,
} from "../types/cart";

export type ViewsState = {
  surcharge: boolean;
  isCustomer: boolean;
  isSales: boolean;
  discount: boolean;
  isBuyer: boolean;
  isOrder: boolean;
};

export type ItemState = {
  isFreeItem: boolean;
  isGroupItem: boolean;
  isConfigurableItem: boolean;
  isSparePartItem: boolean;
  isDrawingItem: boolean;
};

type ShippingMethodDropdownItem = {
  id: string;
  value: string;
  css: string;
};

export type UserRoles = {
  hasGraduatedDiscount: boolean;
  hasTeoDiscount: boolean;
};

export const ORDER_STATUS_FLAGGED = "FLAGGED";
export const ORDER_STATUS_UNORDERD = "UNORDERED";
export const ORDER_STATUS_ORDERED = "ORDERED";
export const CART_STATUS_OPEN = "OPEN";
export const CART_STATUS_ORDERED = "ORDERED";
export const CART_STATUS_ORDERED_PART = "ORDERED_PART";

export type OrderStatus =
  | typeof ORDER_STATUS_FLAGGED
  | typeof ORDER_STATUS_UNORDERD
  | typeof ORDER_STATUS_ORDERED
  | typeof CART_STATUS_OPEN
  | typeof CART_STATUS_ORDERED
  | typeof CART_STATUS_ORDERED_PART;

export const ORDER_STATUS_TOGGLE_MAP: {
  [key: OrderStatus[number]]: OrderStatus;
} = {
  [ORDER_STATUS_FLAGGED]: ORDER_STATUS_UNORDERD,
  [ORDER_STATUS_UNORDERD]: ORDER_STATUS_FLAGGED,
};

export const DEFAULT_QUANTITY_MIN = 1;
export const DEFAULT_QUANTITY_MAX = 999;

const CONFIGURATION_INCOMPLETE = "CONFIGURATION_INCOMPLETE";
const TEO_READY_STATE_UNSPECIFIED = "UNSPECIFIED";
export const CART_ITEM_TYPE_SPAREPART = "SPAREPART";
const CART_ITEM_WITHOUT_DRAWING = "O";

/**
 * Checks if the cart has a quote or not
 * @param {ExtendedTeckentrupCartTO} cart the cart object
 * @returns {boolean} true if the cart has a quote
 */
export const hasQuote = (cart: ExtendedTeckentrupCartTO): boolean =>
  Boolean(cart && cart.quote && cart.headerData);

export const cartHasArticles = (
  cart: TeckentrupCartTO | ExtendedTeckentrupCartTO,
): boolean =>
  pathOr<number>(-1, ["articles", "subArticles", "length"], cart) > 0;

/**
 * Checks if the cart is empty or not
 * @param {ExtendedTeckentrupCartTO} cart the cart object or not
 * @returns {boolean} true if the cart is empty
 */
export const hasUserUnsavedCart = (
  cart: TeckentrupCartTO | ExtendedTeckentrupCartTO,
): boolean => !cart.quote && cartHasArticles(cart);

export const isCartEmpty = (cart: ExtendedTeckentrupCartTO): boolean =>
  isNil(cart.id) ||
  (cart.itemCount === 0 && isEmpty(cart.articles.subArticles));

export const isCartEmptyAndHasNoQuote = (
  cart: ExtendedTeckentrupCartTO,
): boolean => isCartEmpty(cart) && !cart.quote;

export const isCartEmptyOrHasUserUnsavedCart = (
  cart: ExtendedTeckentrupCartTO,
): boolean => isCartEmptyAndHasNoQuote(cart) || hasUserUnsavedCart(cart);

export const quoteIsSavable = (cart: ExtendedTeckentrupCartTO): boolean =>
  !cart.quote && !isCartEmpty(cart);

export const canNotBeOrdered = (cart: ExtendedTeckentrupCartTO): boolean =>
  equals(pathOr("", ["headerData", "status"], cart), "ORDERED");

export const configurationIncompleteOrHasNoBruttoEpIncTzPrice = (
  cartItem: TeckentrupCartArticleTO,
): boolean =>
  CONFIGURATION_INCOMPLETE === cartItem.pricingStatus ||
  (!cartItem.group &&
    !cartItem.freeArticle &&
    pathOr<number>(-1, ["bruttoEpIncTz", "price"], cartItem) <= 0);

export const hasPricing = (pricingStatus?: string): boolean =>
  pricingStatus !== "NO_PRICE_FOUND";

const hasPricingStatus = (
  article: TeckentrupCartArticleTO,
  status: string,
): boolean => {
  if (article.pricingStatus && article.pricingStatus === status) {
    return true;
  }
  return (
    typeof find((s) => hasPricingStatus(s, status), article.subArticles) !==
    "undefined"
  );
};

export const hasTeoReadyStateUnspecified = (
  cartItem: TeckentrupCartArticleTO,
): boolean =>
  !cartItem.group && TEO_READY_STATE_UNSPECIFIED === cartItem.teoReadyState;

export const needsValidationDeep = (article: TeckentrupCartArticleTO) =>
  article.validationNeeded || article.subArticles?.some(needsValidationDeep);

export const needsValidation = (cart: ExtendedTeckentrupCartTO) =>
  cart.headerData?.needsValidation ??
  cart.articles.subArticles.some(needsValidationDeep);

export const isValidLocalBasedOnStorageValue = () =>
  any(
    (value) =>
      equals(
        window.localStorage.getItem(LOCAL_STORAGE_CART_VIEW_BASED_ON_KEY),
        value,
      ),
    [cartViews.basedOn.surchargeBased, cartViews.basedOn.discountBased],
  );

export const getInitialCartView = (): CartViewsState => {
  const view =
    (window.localStorage.getItem(
      LOCAL_STORAGE_CART_VIEW_KEY,
    ) as ViewName | null) ?? cartViews.view.buyerView.name;
  const basedOn = isValidLocalBasedOnStorageValue()
    ? (window.localStorage.getItem(
        LOCAL_STORAGE_CART_VIEW_BASED_ON_KEY,
      ) as BasedOn)
    : cartViews.basedOn.surchargeBased;
  return { view, basedOn };
};

export const getAllArticles = (
  rootArticle: TeckentrupCartArticleTO,
): TeckentrupCartArticleTO[] =>
  pathOr<TeckentrupCartArticleTO[]>([], ["subArticles"], rootArticle).flatMap(
    (a) => (a.group ? getAllArticles(a) : [a]),
  );

export const hasArticlesWithNoPrice = (article: TeckentrupCartArticleTO) =>
  hasPricingStatus(article, "NO_PRICE_FOUND");

/**
 * Determines different views for the cartTable
 * @param {CartViewState} views the views object from useCartView
 * @returns {ViewsState} the views
 */
export const getViews = (views: CartViewsState): ViewsState => {
  const isSales = views.view === cartViews.view.salesView.name;
  const isBuyer = views.view === cartViews.view.buyerView.name;
  const isCustomer = views.view === cartViews.view.customerView.name;
  const isOrder = views.view === cartViews.view.orderView.name;
  const discount = views.basedOn === cartViews.basedOn.discountBased;
  const surcharge = views.basedOn === cartViews.basedOn.surchargeBased;
  return { isSales, isBuyer, isCustomer, isOrder, discount, surcharge };
};

/**
 * Determines different roles for the cart item
 * @param {CartResultTOCartArticle} cartItem the views object from useCartView
 * @returns {ItemState} the views
 */
export const itemRole = (cartItem: TeckentrupCartArticleTO): ItemState => {
  const {
    group,
    configurable,
    specialArticleType,
    pricingStatus,
    drawingType,
    freeArticle,
  } = cartItem;
  const isFreeItem = freeArticle;
  const isGroupItem = group;
  const isConfigurableItem = configurable;
  const isSparePartItem = specialArticleType === CART_ITEM_TYPE_SPAREPART;
  const isDrawingItem =
    configurable &&
    !equals(pricingStatus, CONFIGURATION_INCOMPLETE) &&
    !equals(drawingType, CART_ITEM_WITHOUT_DRAWING);

  return {
    isFreeItem,
    isGroupItem,
    isConfigurableItem,
    isSparePartItem,
    isDrawingItem,
  };
};

const VISUALISIERUNG_UI_PATH = ["Nutzerrechte", "VisualisierungUI"];

export const specialUserRoles = (identityStore: IdentityStore): UserRoles => {
  const identity = identityStore.getIdentity();
  const hasGraduatedDiscount = pathOr(
    false,
    [...VISUALISIERUNG_UI_PATH, "Staffelrabatt_exists"],
    identity,
  );
  const hasTeoDiscount = pathOr(
    false,
    [...VISUALISIERUNG_UI_PATH, "TEO_Rabatt_exists"],
    identity,
  );
  return { hasGraduatedDiscount, hasTeoDiscount };
};

/**
 * Builds Teckentrup-Dropdown-Entry-Objects. Teckentrup Dropdown need a special list of configuration
 * objects defining available entries. Css determines if entry is selected or not.
 *
 * @param {Array<LocalizedShippingMethodTO>} allowedShippingMethods - List of allowed shipping methods
 * @param {LocalizedShippingMethodTO} currentShippingMethod - current shipping method
 * @returns {Array<ShippingMethodDropdownItem>} - list of dropdown entries
 */
export const buildShippingMethodDropDown = (
  allowedShippingMethods: Array<LocalizedShippingMethodTO>,
  currentShippingMethod: LocalizedShippingMethodTO,
): Array<ShippingMethodDropdownItem> =>
  map(
    (shippingMethod) => ({
      id: shippingMethod.id,
      value: shippingMethod.translatedName,
      css:
        shippingMethod.id === path(["id"], currentShippingMethod)
          ? "is-selected"
          : "selectable",
    }),
    allowedShippingMethods,
  );

/**
 * Determines if dropdown for shipping methods should be shown.
 *
 * @param {Array<LocalizedShippingMethodTO>} allowedShippingMethods - List of currently allowed shipping methods
 * @returns {boolean} - true if more than one entry
 */
export const isShippingMethodDropdownShown = (
  allowedShippingMethods: Array<LocalizedShippingMethodTO>,
): boolean => allowedShippingMethods && length(allowedShippingMethods) > 1;

export const getNonOpenedQuoteByOrderNumber = async (
  orderNumber: string,
  userId: string,
) => {
  const search = {
    term: orderNumber,
    in: ["_quoteOrderNumber"],
  };

  const data = {
    quoteRequest: {
      source: "ACTIVE",
      search,
    },
    seeAllQuotes: true,
  } as const;

  const {
    data: { quotes },
  } = await getQuotes(data);

  if (isEmpty(quotes)) {
    return null;
  }

  const loadedQuote = head(quotes);
  if (loadedQuote?.cartTO.active && loadedQuote.cartTO.userId !== userId) {
    return null;
  }

  return loadedQuote;
};

export const allPartOrderAllowedItemsFlagged = (
  articles: TeckentrupCartArticleTO,
) => {
  const subArticles = getAllArticles(articles);
  const allowedArticles = filter(
    (article) => article.allowForPartOrder,
    subArticles,
  );
  return (
    length(subArticles) > 0 &&
    all(
      (item) => item.articleOrderStatus === ORDER_STATUS_FLAGGED,
      allowedArticles,
    )
  );
};

export const getAllPartOrderFlaggedItems = (
  articles: TeckentrupCartArticleTO,
) =>
  filter(
    (article) =>
      article.allowForPartOrder &&
      article.articleOrderStatus === ORDER_STATUS_FLAGGED,
    getAllArticles(articles),
  );
