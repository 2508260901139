import { isNil } from "ramda";
import { useContext } from "react";

import { ConfigurationContext } from "../context/ConfigurationProvider";

export function useConfiguration() {
  const context = useContext(ConfigurationContext);
  if (isNil(context)) {
    throw new Error(
      "useConfiguration must be used within ConfigurationProvider",
    );
  }
  return context;
}
