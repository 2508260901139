import { path, pathEq, pathOr } from "ramda";

import { CreatedCfg, LoadedCfg } from "../../types/configuration";

const ARTICLE_NAME_PATH = ["configuration", "articleName"];

export const isReconfigurationSetupFailed = pathEq("false", [
  "configurationStatus",
  "configuration",
  "properties",
  "ReconfigurationSetupSuccessful",
]);

export function getArticleName(cfg: CreatedCfg | LoadedCfg | undefined) {
  const articleName = path<string>(ARTICLE_NAME_PATH, cfg);
  if (articleName) {
    return articleName;
  }
  return pathOr("", ["configurationStatus", ...ARTICLE_NAME_PATH], cfg);
}
