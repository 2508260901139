import { AxiosPromise } from "axios";
import { produce } from "immer";
import {
  T,
  all,
  any,
  assoc,
  compose,
  cond,
  count,
  defaultTo,
  equals,
  filter,
  find,
  gt,
  head,
  identity,
  includes,
  isEmpty,
  isNil,
  isNotEmpty,
  join,
  length,
  lte,
  map,
  multiply,
  not,
  pathOr,
  pick,
  pipe,
  prop,
  propEq,
  reduce,
  reject,
  replace,
  split,
  startsWith,
  trim,
} from "ramda";

import IdentityStore from "../http/identityStore";
import {
  COUNTRY_AVAILABILITY,
  ROLE_AVAILABILITY,
} from "../pages/products/productSelectionUtils";
import { t } from "../pages/shoppingCart/localizationUtils";
import { ParameterTO } from "../types/@encoway/Parameter";
import { SelectionSource, Value } from "../types/@encoway/Value";
import { ContainerTO, CreatedConfig } from "../types/configuration";
import {
  LvAddress,
  LvBase,
  LvBaseExtended,
  LvEmptyRow,
  LvProduct,
  LvRow,
  LvRowDTO,
  LvValue,
} from "../types/lvTable";
import { defaultToString, isEmptyString } from "./utilities";

export const LV_TAB_ID = {
  LV_IMPORT: "lvImport",
  LV_POSITIONS: "lvPositions",
  LV_HEADER_DATA: "lvHeaderData",
} as const;

export type LvTabId = (typeof LV_TAB_ID)[keyof typeof LV_TAB_ID];

// Standard height           Standard width (1. flg)         Standard width (2. flg)
// 1.90 m  - 2.20 m          0.61 m   - 0.99 m               1.23 m  - 1.99 m
// 190 cm  - 220 cm            61 cm  -   99 cm              123 cm  - 199 cm
// 1900 mm - 2200 mm           610 mm -   990 mm             1230 mm - 1990 mm
const METERS_MAX = 4;
const CENTIMETERS_MAX = 400;

// see https://www.utf8-chartable.de/unicode-utf8-table.pl?start=128&number=1024&utf8=string-literal
// and https://www.utf8-chartable.de/unicode-utf8-table.pl?start=8192&number=1024&utf8=string-literal
const UTF8_REPLACE_LIST = [
  { pattern: /\\xc3\\xa4/g, replacement: "ä" },
  { pattern: /\\xc3\\x84/g, replacement: "Ä" },
  { pattern: /\\xc3\\xb6/g, replacement: "ö" },
  { pattern: /\\xc3\\x96/g, replacement: "Ö" },
  { pattern: /\\xc3\\xbc/g, replacement: "ü" },
  { pattern: /\\xc3\\x9c/g, replacement: "Ü" },
  { pattern: /\\xc3\\x9f/g, replacement: "ß" },
  { pattern: /\\xc2\\xb2/g, replacement: "²" },
  { pattern: /\\xc2\\xb3/g, replacement: "³" },
  { pattern: /\\xc2\\xba/g, replacement: "°" },
  { pattern: /\\xc2\\xb0/g, replacement: "°" },
  { pattern: /\\xc2\\xa7/g, replacement: "§" },
  { pattern: /\\xc3\\xb8/g, replacement: "ø" },
  { pattern: /\\xc3\\x98/g, replacement: "Ø" },
  { pattern: /\\xc2\\xb5/g, replacement: "µ" },
  { pattern: /\\xc2\\xb4/g, replacement: "´" },
  { pattern: /\\xc2\\xad/g, replacement: "-" },
  { pattern: /\\xc2\\xb1/g, replacement: "±" },
  { pattern: /\\xc2\\x9f/g, replacement: "•" },
  { pattern: /\\xc2\\xb7/g, replacement: "•" },
  { pattern: /\\xc2\\xa9/g, replacement: "©" },
  { pattern: /\\xc2\\xae/g, replacement: "®" },
  { pattern: /\\xc3\\x86/g, replacement: "Æ" },
  { pattern: /\\xc3\\xa9/g, replacement: "é" },
  { pattern: /\\xc3\\xb5/g, replacement: "õ" },
  { pattern: /\\xcf\\x88/g, replacement: "ψ" },
  { pattern: /\\xc7\\x80/g, replacement: "|" },
  { pattern: /\\xcd\\xbe/g, replacement: ";" },
  { pattern: /\\xe2\\x80\\xa2/g, replacement: "•" },
  { pattern: /\\xef\\x82\\xb7/g, replacement: "•" },
  { pattern: /\\xe2\\x88\\x99/g, replacement: "•" },
  { pattern: /\\xe2\\x80\\x9e/g, replacement: "„" },
  { pattern: /\\xe2\\x80\\x9c/g, replacement: "“" },
  { pattern: /\\xe2\\x80\\x98/g, replacement: "‘" },
  { pattern: /\\xe2\\x80\\x99/g, replacement: "’" },
  { pattern: /\\xe2\\x82\\xac/g, replacement: "€" },
  { pattern: /\\xe2\\x89\\xa4/g, replacement: "≤" },
  { pattern: /\\xe2\\x89\\xa5/g, replacement: "≥" },
  { pattern: /\\xe2\\x80\\x90/g, replacement: "-" },
  { pattern: /\\xe2\\x80\\x93/g, replacement: "-" },
  { pattern: /\\xef\\xac\\x81/g, replacement: "fi" },
  { pattern: /\\xef\\xac\\x82/g, replacement: "fl" },
  { pattern: /\\xe2\\x80\\xa6/g, replacement: "..." },
  { pattern: /\\xe2\\x88\\x86/g, replacement: "∆" },
  { pattern: /\\xef\\x80\\xa0/g, replacement: "•" },
  { pattern: /\\xef\\x81\\xac/g, replacement: "•" },
  { pattern: /\\xe2\\x80\\x86/g, replacement: " " },
  { pattern: /<title>.*<\/title>/, replacement: "<title>Original LV</title>" },
  { pattern: /^b'/, replacement: "" },
  { pattern: /'$/, replacement: "" },
];

const PARAMETER_NAME_MAP: Record<string, string | undefined> = {
  staerkeTuerblatt: "inputStaerkeTuerblatt",
  T_M_RS: "inputRauchschutz",
};

const CONFIGURATION_CHARACTERISTICS = [
  "T_M_EINGMA",
  "T_M_BMB",
  "T_M_BMH",
  "T_M_DINR",
  "T_M_ZARGE",
  "T_M_MAULW",
  "T_M_EN1125",
  "T_M_PAFKT",
];

export const parseQuantity = (quantity: LvBase) => {
  const amount = isEmpty(quantity.value)
    ? "1"
    : split(",", quantity.value)[0].replaceAll(/\D/g, "");
  return {
    ...quantity,
    value: Number(amount),
  };
};

const parseDimension = (value: string) =>
  Number(value.replace(",", ".").replaceAll(/[a-zA-Z\s]/g, ""));

const convertDimension = cond<number[], number>([
  [gt(METERS_MAX), multiply(1000)],
  [gt(CENTIMETERS_MAX), multiply(10)],
  [T, identity],
]);

export const pickAddressFields = pick([
  "firstname",
  "surname",
  "street",
  "houseNumber",
  "zip",
  "city",
  "phone",
  "email",
]);

export const prepareParameters = map<LvBase, LvBase>((lvParameter) => {
  if (
    equals(lvParameter.name, "T_M_BMB") ||
    equals(lvParameter.name, "T_M_BMH")
  ) {
    const parsedValue = parseDimension(lvParameter.value);
    const convertedValue = Math.round(convertDimension(parsedValue));
    return {
      ...lvParameter,
      value: String(convertedValue),
    };
  }
  return lvParameter;
});

export const splitParameters = reduce<
  LvBase,
  { lvParameters: LvBase[]; configurationParameters: LvBase[] }
>(
  (acc, lvParameter) => {
    if (includes(lvParameter.name, CONFIGURATION_CHARACTERISTICS)) {
      return produce(acc, (draft) => {
        draft.configurationParameters.push(lvParameter);
      });
    }
    return produce(acc, (draft) => {
      draft.lvParameters.push(lvParameter);
    });
  },
  { lvParameters: [], configurationParameters: [] },
);

const toReplaced = (
  htmlString: string,
  { pattern, replacement }: (typeof UTF8_REPLACE_LIST)[number],
) => replace(pattern, replacement, htmlString);

export const convertUtf8Literals = (htmlString: string) =>
  reduce(toReplaced, htmlString, UTF8_REPLACE_LIST);

function getParameters(
  container: ContainerTO,
  parameterMap: Map<ParameterTO["name"], ParameterTO>,
) {
  for (const parameter of container.parameters) {
    parameterMap.set(parameter.name, parameter);
  }
  for (const child of container.children) {
    getParameters(child, parameterMap);
  }
}

function getParameterMap(container: ContainerTO) {
  const parameterMap = new Map<ParameterTO["name"], ParameterTO>();
  getParameters(container, parameterMap);
  return parameterMap;
}

function anyAffectedParameterNotSetByDefault(error: unknown) {
  return any(
    propEq(false, "setByDefault"),
    pathOr([], ["response", "affectedParameters"], error),
  );
}

type ChangeParameter = {
  name: string;
  value?: string;
};

async function tryParameterChanges(
  cs: CreatedConfig,
  parameterMap: Map<ParameterTO["name"], ParameterTO>,
  parameters: ChangeParameter[],
) {
  // for of loop, as changes must not be made in parallel
  for (const { name, value } of parameters) {
    try {
      const parameterId = parameterMap.get(name)?.id;
      if (isEmptyString(value) || isNil(parameterId)) {
        continue;
      }
      await cs.force(parameterId, value, "UNFORMATTED");
    } catch (error) {
      console.error("Error on cs.force:", name, value, error);
      if (anyAffectedParameterNotSetByDefault(error)) {
        await cs
          .decline()
          .catch((declineError) =>
            console.error("Error on cs.decline:", name, value, declineError),
          );
        console.info("Declined force for cs.force error:", name, value);
      }
    }
  }
}

const prepareParameterName = (lvParameter: LvBase) =>
  assoc(
    "name",
    pathOr(lvParameter.name, [lvParameter.name], PARAMETER_NAME_MAP),
    lvParameter,
  );

const joinRoles = compose(
  join(";"),
  map((role) => `"${role}"`),
);

function prepareRoleValue(roles: string[]) {
  if (lte(length(roles), 1)) {
    return head(roles);
  }
  return `{${joinRoles(roles)}}`;
}

// The following sequence must be followed: role availability, country availability, all others
export async function setConfigValues(
  cs: CreatedConfig,
  lvParameters: LvRowDTO["parameters"],
  identityStore: IdentityStore,
  product?: LvRow["product"],
) {
  const guiTO = await cs.ui();
  const parameterMap = getParameterMap(guiTO.rootContainer);
  const preparedLvParameters = map(prepareParameterName, lvParameters);
  const allParameters = [
    {
      name: ROLE_AVAILABILITY,
      value: prepareRoleValue(defaultTo([], identityStore.getRoles())),
    },
    {
      name: COUNTRY_AVAILABILITY,
      value: identityStore.getIdentity()?.FirmadesNutzers.FirmenLand,
    },
    ...preparedLvParameters,
    {
      name: "doors",
      value: product?.value,
    },
  ];
  await tryParameterChanges(cs, parameterMap, allParameters);
}

const getLvValues = map<Value, LvValue>(
  pick(["value", "translatedValue", "selectable", "selectionSource"]),
);

function getOriginalValue(lvParameter: LvBase | LvBaseExtended | undefined) {
  if (isNil(lvParameter)) {
    return "";
  }
  return pathOr(lvParameter.value, ["originalValue"], lvParameter);
}

function getSelectedProperty<T>(
  defaultValue: T,
  property: keyof Value,
  parameterTO: ParameterTO | undefined,
) {
  return pathOr(defaultValue, ["selectedValues", 0, property], parameterTO);
}

const buildProduct = async (
  parameterMap: Map<ParameterTO["name"], ParameterTO>,
  getConflictFreeParameterTO?: (
    parameterId: string,
  ) => AxiosPromise<ParameterTO>,
): Promise<LvProduct> => {
  const doors = parameterMap.get("doors");
  const parameterId = pathOr("", ["id"], doors);
  const parameterTO = await getConflictFreeParameterTO?.(parameterId);
  const parameter = pathOr(doors, ["data"], parameterTO);
  return {
    value: getSelectedProperty("", "value", doors),
    translatedValue: getSelectedProperty("", "translatedValue", doors),
    selectable: getSelectedProperty(false, "selectable", doors),
    values: getLvValues(pathOr([], ["values"], parameter)),
    selectionSource: getSelectedProperty("NOT_SET", "selectionSource", doors),
  };
};

const buildParameters = (
  parameterMap: Map<ParameterTO["name"], ParameterTO>,
  lvParameters: LvRowDTO["parameters"] | LvRow["parameters"],
) =>
  map(({ name, translatedName, selectedValues, values }) => {
    const lvParameter = find<LvBase | LvBaseExtended>(
      propEq(name, "name"),
      lvParameters,
    );
    return {
      name,
      translatedName: defaultToString(translatedName),
      value: pathOr("", [0, "value"], selectedValues),
      values: getLvValues(defaultTo([], values)),
      translatedValue: pathOr("", [0, "translatedValue"], selectedValues),
      originalValue: getOriginalValue(lvParameter),
      recognized: pathOr("", ["recognized"], lvParameter),
      selectionSource: pathOr<SelectionSource>(
        "NOT_SET",
        [0, "selectionSource"],
        selectedValues,
      ),
    };
  }, Array.from(parameterMap.values()));

export async function getGuiParameters(
  cs: CreatedConfig,
  lvParameters: LvRowDTO["parameters"] | LvRow["parameters"],
  getConflictFreeParameterTO?: (
    parameterId: string,
  ) => AxiosPromise<ParameterTO>,
) {
  const guiTO = await cs.ui();
  const parameterMap = getParameterMap(guiTO.rootContainer);
  const product = await buildProduct(parameterMap, getConflictFreeParameterTO);
  parameterMap.delete("doors");
  const parameters = buildParameters(parameterMap, lvParameters);
  return { parameters, product };
}

export const rejectInputParameters = reject<LvRow["parameters"][number]>(
  pipe(prop("name"), startsWith("input")),
);

export function isOriginalValue(
  originalValue: string | undefined,
  value: string,
) {
  if (isEmptyString(originalValue)) {
    return false;
  }
  return equals(originalValue, value);
}

export function updateSelectionSource(
  selectedValue: string,
  values: LvValue[],
) {
  const isSelected = equals(selectedValue);
  return map(
    (value) =>
      assoc<SelectionSource, LvValue, "selectionSource">(
        "selectionSource",
        isSelected(value.value) ? "SET_BY_USER" : "NOT_SET",
        value,
      ),
    values,
  );
}

export function isLvRow(lvRow: LvRow | LvEmptyRow): lvRow is LvRow {
  return (lvRow as LvRow).parameters !== undefined;
}

export function isLvEmptyRow(lvRow: LvRow | LvEmptyRow): lvRow is LvEmptyRow {
  return (lvRow as LvRow).parameters === undefined;
}

export const areAllValuesEmpty = all<{ value: string }>(({ value }) =>
  isEmpty(value),
);

export function prepareText(positionValue: string, text: string) {
  const replaceUnwanted = pipe(
    split("\n"),
    head,
    replace(positionValue, ""),
    replace(/\s+/g, " "),
    trim,
  );
  return replaceUnwanted(text);
}

export function getReferenceComment(parameters: LvBaseExtended[]) {
  // The backend only sets the "value" for reference parameters, "recognized" remains empty
  const referenceParameters = filter(
    ({ originalValue, recognized }) =>
      not(isEmpty(originalValue)) && isEmpty(recognized),
    parameters,
  );
  if (isEmpty(referenceParameters)) {
    return "";
  }
  const referenceParameterNames = join(
    ", ",
    map(prop("translatedName"), referenceParameters),
  );
  return `${t("offer_management_lv_reference_comment")} ${referenceParameterNames}`;
}

const joinOnSpaceAndTrim = compose(trim, join(" "));

export const getAddressDetails = (option: LvAddress) => {
  const details = [
    option.addressIdentifier,
    joinOnSpaceAndTrim([option.street, option.houseNumber]),
    joinOnSpaceAndTrim([option.zip, option.city]),
    option.email,
    option.phone,
  ];
  const filteredDetails = reject(isEmpty, details);
  return join(" | ", filteredDetails);
};

export const getPersonDetails = (option: LvAddress) => {
  const details = [
    option.company,
    joinOnSpaceAndTrim([option.salutation, option.firstname, option.surname]),
    joinOnSpaceAndTrim([option.street, option.houseNumber]),
    joinOnSpaceAndTrim([option.zip, option.city]),
    option.email,
    option.phone,
  ];
  const filteredDetails = reject(isEmpty, details);
  return join(" | ", filteredDetails);
};

function getLvAddressProperty(
  property: keyof LvAddress,
  contactPerson: LvAddress | undefined,
  address: LvAddress | undefined,
) {
  if (contactPerson && isNotEmpty(contactPerson[property])) {
    return contactPerson[property];
  }
  return pathOr("", [property], address);
}

export function mergeLeftPersonAndAddress(
  contactPerson: LvAddress | undefined,
  address: LvAddress | undefined,
) {
  return {
    customerName: getLvAddressProperty("company", contactPerson, address),
    title: getLvAddressProperty("salutation", contactPerson, address),
    firstname: getLvAddressProperty("firstname", contactPerson, address),
    surname: getLvAddressProperty("surname", contactPerson, address),
    street: getLvAddressProperty("street", contactPerson, address),
    houseNumber: getLvAddressProperty("houseNumber", contactPerson, address),
    addressIdentifier: getLvAddressProperty(
      "addressIdentifier",
      contactPerson,
      address,
    ),
    zip: getLvAddressProperty("zip", contactPerson, address),
    city: getLvAddressProperty("city", contactPerson, address),
    phone: getLvAddressProperty("phone", contactPerson, address),
    email: getLvAddressProperty("email", contactPerson, address),
  };
}

export function determineSelectableCount(
  selectedValue: string,
  values: LvValue[],
) {
  const selectableCount = count((value) => value.selectable, values);
  if (isNotEmpty(selectedValue) && equals(selectableCount, 0)) {
    return 1;
  }
  return selectableCount;
}
