import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { L10n } from "@encoway/l10n";

import { SmartInput } from "../../components/input/smartInput";

export const CustomerSearch = ({ onNewCustomerDialog, onSearch }) => (
  <div className="columns">
    <div className="column">
      <div className="field">
        <p className="control has-icons-right ">
          <SmartInput
            className="input"
            placeholder={L10n.format(
              "customer_management_search_offer_placeholder",
            )}
            onChange={onSearch}
            type="text"
            persistOnChange={true}
            autoComplete="off"
            attributeId="customer-search"
          />
          <span className="icon is-small is-right">
            <FontAwesomeIcon icon={faMagnifyingGlass} />
          </span>
        </p>
      </div>
    </div>

    <div className="column" style={{ flexGrow: "0" }}>
      <button
        data-button-id="add-new-customer"
        className="button is-info is-blue-dark"
        onClick={onNewCustomerDialog}
      >
        <div className="is-pulled-left">
          {L10n.format("customer_management_create_new_customer")}
        </div>
        <div className="is-pulled-right">
          <i
            className="image-icon neuen-vorgang-erstellen"
            aria-hidden="true"
          />
        </div>
      </button>
    </div>
  </div>
);
