import { decodeJwt } from "jose";
import { pathOr } from "ramda";

import { getRoleAndRights } from "../service/authorizationService";
import { TokenPayload, UiAuthorities } from "../types/identity";
import { isNonEmptyString } from "../utilities/utilities";

/**
 * Manages the authentication token which is communicated with every request.
 */
export default class IdentityStore {
  private roleAndRights: UiAuthorities | null = null;

  constructor(initialToken: string) {
    this.storeToken(initialToken);
  }

  /**
   * Saves the given token in jwt format.
   * The decoded information of this token will then be available via #getIdentity().
   * @param  {String} token The token
   */
  storeToken(token: string) {
    if (this.storageAvailable()) {
      window.localStorage.setItem("authToken", token);
    }
  }

  /**
   * Stores the authorities coming from the _ui_authorities endpoint.
   */
  async storeAuthorities() {
    const response = await getRoleAndRights();
    this.roleAndRights = response?.data;
  }

  getRoleAndRights() {
    return this.roleAndRights;
  }

  /**
   * Returns the raw, undecoded token string.
   */
  getToken() {
    return window.localStorage.getItem("authToken");
  }

  /**
   * Returns the decoded token or an empty Object if no token is present.
   */
  getIdentity() {
    const token = this.getToken();
    if (isNonEmptyString(token)) {
      return decodeJwt<TokenPayload>(token);
    }
  }

  /**
   * Returns the company of the token or undefined if no token is present.
   */
  getCompany() {
    const token = this.getToken();
    if (isNonEmptyString(token)) {
      return decodeJwt<TokenPayload>(token).FirmadesNutzers;
    }
  }

  /**
   * Returns the user of the token or an empty Object if no token is present.
   */
  getUser() {
    const token = this.getToken();
    if (isNonEmptyString(token)) {
      return decodeJwt<TokenPayload>(token).Nutzer;
    }
  }

  /**
   * Determines whether browser localStorage should be used to store the token.
   */
  storageAvailable() {
    if (window.localStorage) {
      return true;
    }
    return false;
  }

  getRoles() {
    return pathOr<string[]>([], ["roles"], this.roleAndRights);
  }

  getBooleanAuthorities() {
    return pathOr<string[]>([], ["booleanAuthorities"], this.roleAndRights);
  }
}
