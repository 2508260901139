import { equals, map } from "ramda";
import { Link } from "react-router-dom";

interface NavigationLink {
  displayName: string;
  route: string;
  target?: string;
}

const topBarNavigationLinks: NavigationLink[] = [
  {
    displayName: "Benutzerselektionsseite",
    route: "/devLogin",
  },
  {
    displayName: "Konfiguration",
    route: "/catalog",
  },
  {
    displayName: "Vorgangsübersicht",
    route: "/offerManagement",
  },
  {
    displayName: "Kundenverwaltung",
    route: "/customerManagement",
  },
  {
    displayName: "Meine Daten",
    route: "#",
  },
];

const helpNavigationLinks: NavigationLink[] = [
  {
    displayName: "Kontakt",
    route: "#",
  },
  {
    displayName: "Erläuterung Icons",
    route: "https://teo.teckentrup.biz/fileadmin/user_upload/Doku/Icons.pdf",
    target: "_blank",
  },
  {
    displayName: "Letzte Änderungen",
    route: "#",
    target: "_blank",
  },
];

/**
 * Element that displays the navigation links
 * @returns {JSX.Element} element displays the navigation links
 */
export function NavigationLinks() {
  return (
    <ul className="mmenu">
      {map(
        (link) => (
          <li key={link.displayName}>
            <Link to={link.route} title={link.displayName}>
              {link.displayName}
            </Link>
          </li>
        ),
        topBarNavigationLinks,
      )}
      <li className="sub ">
        <Link target="_self" title="Hilfe" to="#">
          Hilfe
        </Link>
        <ul>
          {map(
            (link) => (
              <li key={link.displayName}>
                {equals(link.target, "_blank") ? (
                  <a href={link.route} target="_blank" rel="noreferrer">
                    {link.displayName}
                  </a>
                ) : (
                  <Link to={link.route} title={link.displayName}>
                    {link.displayName}
                  </Link>
                )}
              </li>
            ),
            helpNavigationLinks,
          )}
        </ul>
      </li>
    </ul>
  );
}
