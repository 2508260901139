import { isNil, path } from "ramda";
import { useMemo } from "react";

import { L10n } from "@encoway/l10n";

import { useApp } from "../hooks/useApp";
import { useCart } from "../hooks/useCart";
import { useConfiguration } from "../hooks/useConfiguration";
import {
  hasRightToSeeBuildingProject,
  hasRightToSeeVisibleReference,
  isCurrentUserAdminOrFromSameCompanyAsCreator,
} from "../http/identityHelper";
import { ExtendedTeckentrupCartTO } from "../types/cart";

import "./cartInformation.scss";

type ValidPropertyKeys = "status" | "buildProject" | "referenceNumber";

function getHeaderDataProperty(
  cart: ExtendedTeckentrupCartTO,
  propertyKey: ValidPropertyKeys,
): string {
  const property: string | undefined = path<string>(
    ["headerData", propertyKey],
    cart,
  );

  return isNil(cart.orderNumber) || isNil(property) ? "-" : property;
}

type InfoTextWrapperProps = Readonly<{
  label: string;
  value: string;
  labelDataName: string;
  valueDataName: string;
}>;

function InfoTextWrapper(props: InfoTextWrapperProps) {
  const { label, value, labelDataName, valueDataName } = props;

  return (
    <div className="info-text">
      <span
        className="info-text-label"
        data-label-id={labelDataName}
      >{`${L10n.format(label)}:`}</span>
      <span className="info-text-value" data-label-id={valueDataName}>
        {value}
      </span>
    </div>
  );
}

function AdditionalInformation() {
  const { identityStore } = useApp();
  const { cart } = useCart();

  const hasRight: boolean = useMemo(
    () =>
      hasRightToSeeBuildingProject(cart.authorities.booleanAuthorities) &&
      hasRightToSeeVisibleReference(cart.authorities.booleanAuthorities),
    [cart.authorities.booleanAuthorities],
  );
  const isAdminOrFromSameCompany = useMemo(
    () =>
      isCurrentUserAdminOrFromSameCompanyAsCreator(
        identityStore,
        cart.headerData,
        cart.authorities,
      ),
    [cart, identityStore],
  );

  if (hasRight) {
    return (
      <>
        <InfoTextWrapper
          label={"building_project"}
          value={
            isAdminOrFromSameCompany
              ? getHeaderDataProperty(cart, "buildProject")
              : "-"
          }
          labelDataName="building-project"
          valueDataName="building-project-value"
        />
        <InfoTextWrapper
          label={"reference_number"}
          value={
            isAdminOrFromSameCompany
              ? getHeaderDataProperty(cart, "referenceNumber")
              : "-"
          }
          labelDataName="reference-number"
          valueDataName="reference-number-value"
        />
      </>
    );
  }

  return null;
}

export function CartInformation() {
  const { systemName } = useApp();
  const { reconfiguration } = useConfiguration();
  const { cart } = useCart();

  return (
    <div className="cart-information-columns" data-page-id="cart">
      <div className="system-info">{systemName}</div>
      <div className="cart-information-column" data-div-id="cart-information">
        <div className="cart-information">
          <InfoTextWrapper
            label={"offer_management_operation_number"}
            value={cart.orderNumber ? cart.orderNumber : "-"}
            labelDataName="operation-number"
            valueDataName="operation-number-value"
          />
          {reconfiguration && (
            <InfoTextWrapper
              label={"offer_management_table_position"}
              value={reconfiguration.cartPosition}
              labelDataName="offer-management-table-position"
              valueDataName="offer-management-table-position-value"
            />
          )}
          <InfoTextWrapper
            label={"offer_management_table_status"}
            value={
              cart.orderNumber && cart.headerData?.status
                ? L10n.format(
                    `offer_management_table_status_${cart.headerData.status}`,
                  )
                : "-"
            }
            labelDataName="status"
            valueDataName="status-value"
          />
          <AdditionalInformation />
        </div>
      </div>
    </div>
  );
}
