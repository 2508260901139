import { AxiosResponse } from "axios";
import { has } from "ramda";

import {
  CartResultTO,
  ExtendedCartResultTO,
  ExtendedTeckentrupCartTO,
} from "../types/cart";

export function getResponseCart(
  response: CartResultTO | AxiosResponse<ExtendedCartResultTO>,
) {
  if (has("data", response)) {
    return response.data.cart.cartTO;
  }
  return response.cart;
}

export function getResponseAuthorities(
  response: CartResultTO | AxiosResponse<ExtendedCartResultTO>,
  cart: ExtendedTeckentrupCartTO | null,
) {
  if (has("data", response)) {
    return response.data.cart.uiRelevantAuthorizationTO;
  }
  // cart cant be null at this point, because the first function that gets
  // called in the CartProvider ist getActiveCart. This is an Axios call, means
  // the if gets called and cart gets set.
  return cart!.authorities;
}
