import { clamp, isEmpty, map, path, pathOr, splitEvery } from "ramda";

import { t } from "../../../localizationUtils";

const INITIAL_VALIDATION_STATUS = {
  finished: false,
  valid: false,
  running: true,
  error: false,
};
export const VALID_VALIDATION_STATUS = {
  finished: true,
  valid: true,
  running: false,
  error: false,
};
export const ERROR_VALIDATION_STATUS = {
  finished: true,
  valid: false,
  running: false,
  error: true,
};
const VALIDATION_RESULT = {
  VALID: "VALID",
  ARTICLE_NOT_EXISTING: "ARTICLE_NOT_EXISTING",
  CONFIGURATION_NOT_LOADED: "CONFIGURATION_NOT_LOADED",
};
const PARALLEL_REQUEST_SPLIT = {
  factor: 0.4,
  min: 2,
  max: 10,
};
const VALIDATION_RESULT_MAP = {
  [VALIDATION_RESULT.VALID]: () => ({
    ...VALID_VALIDATION_STATUS,
    errorMsg: "",
  }),
  [VALIDATION_RESULT.ARTICLE_NOT_EXISTING]: (cartItem) => ({
    ...ERROR_VALIDATION_STATUS,
    errorMsg: t("cart_validation_invalid_not_found", cartItem),
  }),
  [VALIDATION_RESULT.CONFIGURATION_NOT_LOADED]: (cartItem) => ({
    ...ERROR_VALIDATION_STATUS,
    errorMsg: t("cart_validation_invalid_not_started", cartItem),
  }),
};

export const getAllNonFreeArticles = (rootArticle) =>
  pathOr([], ["subArticles"], rootArticle)
    .filter((a) => !a.freeArticle)
    .flatMap((a) => (a.group ? getAllNonFreeArticles(a) : [a]));

/**
 * Receives all validatable articles and divides them into a 2d array
 * based on factor, min and max and adds the validation object.
 * The separation is needed to relive the server on large shopping carts
 * (see TT-1102).
 * @returns {ValidatedCartItem[][]}
 */
export const getCartItemValidationState = (cartItems) => {
  const { factor, min, max } = PARALLEL_REQUEST_SPLIT;
  const subArticles = pathOr([], ["subArticles"], cartItems);
  const split =
    subArticles.length <= min
      ? subArticles.length
      : clamp(min, max, Math.round(subArticles.length * factor));
  return splitEvery(
    split,
    map(
      (cartItem) => ({
        ...cartItem,
        validation: { ...INITIAL_VALIDATION_STATUS, errorMsg: "" },
      }),
      getAllNonFreeArticles(cartItems),
    ),
  );
};

export const determineCartItemValidationStatus = (cartItem, loadingResult) => {
  const validationResult = path(
    ["configurationStatus", "properties", "validation-result"],
    loadingResult,
  );
  const validationStatus = VALIDATION_RESULT_MAP[validationResult];
  if (validationStatus) {
    return validationStatus(cartItem);
  }
  return VALIDATION_RESULT_MAP[VALIDATION_RESULT.ARTICLE_NOT_EXISTING](
    cartItem,
  );
};

export const determineItemValidationLabel = (cartItem) => {
  const {
    articleNumber,
    articleName,
    validation: { finished, valid, error, errorMsg },
  } = cartItem;
  if (finished && error) {
    return errorMsg;
  }
  if (finished && valid && isEmpty(articleName)) {
    return articleNumber;
  }
  return articleName;
};
