import { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";

import { Loading } from "../../components/loading";
import { useConfiguration } from "../../hooks/useConfiguration";

export function ConfigurationFromProduct() {
  const params = useParams();
  const { configurationId, start } = useConfiguration();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    start({ articleName: params.articleName }).then(() => setIsLoading(false));
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return <Navigate to={`/configuration/id/${configurationId}`} />;
}
