import {
  faCheck,
  faExclamationTriangle,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { map, pathOr } from "ramda";
import { PropsWithChildren } from "react";

import { L10n } from "@encoway/l10n";

import {
  Modal,
  ModalBodyWrapper,
  ModalFooter,
  ModalHeader,
} from "../../components/modal/modal";
import { useConfiguration } from "../../hooks/useConfiguration";
import { ReconfigurationParameter } from "../../types/configuration";
import { t } from "../shoppingCart/localizationUtils";

import "../shoppingCart/components/modals/reconfiguringArticleLoadingModal.scss";

const CLASS_NAME_ICON_MAP: Record<string, IconDefinition | undefined> = {
  "is-success": faCheck,
  "is-warning": faExclamationTriangle,
};

type ProgressIconProps = Readonly<{
  className: string;
}>;

function ProgressIcon({ className }: ProgressIconProps) {
  const icon = CLASS_NAME_ICON_MAP[className];
  return (
    <div className={classNames("button is-progress", className)}>
      {icon && (
        <span className="icon is-small">
          <FontAwesomeIcon icon={icon} />
        </span>
      )}
    </div>
  );
}

type ProgressRowProps = Readonly<{
  label: string;
}>;

function ProgressRow({ label, children }: PropsWithChildren<ProgressRowProps>) {
  return (
    <div className="reconfiguration-loading columns">
      <div className="reconfiguration-loading-state column">{children}</div>
      <div className="reconfiguration-loading-label column">{label}</div>
    </div>
  );
}

function FailedTable() {
  const { reconfiguration } = useConfiguration();
  return (
    <>
      <div className="modal-card-subtitle pt-5 pb-4">
        {L10n.format("cart_title_reconfiguration_broken")}
      </div>
      <table className="table failed-table" align="center">
        <thead>
          <tr>
            <th>{L10n.format("characteristic")}</th>
            <th>{L10n.format("value")}</th>
          </tr>
        </thead>
        <tbody>
          {map(
            (unsuccessfulParameter) => (
              <tr key={unsuccessfulParameter.translatedName}>
                <td>{unsuccessfulParameter.translatedName}</td>
                <td>
                  {unsuccessfulParameter.selectedValues[0].translatedValue}
                </td>
              </tr>
            ),
            pathOr<ReconfigurationParameter[]>(
              [],
              ["unsuccessfulParameters"],
              reconfiguration,
            ),
          )}
        </tbody>
      </table>
    </>
  );
}

type ReconfiguringArticleLoadingModalProps = Readonly<{
  onClose?: () => void;
}>;

export function ReconfiguringArticleLoadingModal({
  onClose,
}: ReconfiguringArticleLoadingModalProps) {
  return (
    <Modal className="create-a-new-quote">
      <ModalHeader
        className="reconfiguration-modal-header"
        label={t("cart_title_reconfiguration_warning")}
        {...(onClose && { onCancel: onClose })}
      />
      <ModalBodyWrapper
        label={t("cart_title_reconfiguration_validate_warning")}
      >
        <div className="reconfiguration-modal">
          <ProgressRow label={t("cart_reconfiguration_started")}>
            <ProgressIcon className="is-success" />
          </ProgressRow>
          <ProgressRow label={t("cart_reconfiguration_running")}>
            <ProgressIcon className={onClose ? "is-warning" : "is-loading"} />
          </ProgressRow>
          <ProgressRow label={t("cart_reconfiguration_finished")}>
            <ProgressIcon className={onClose ? "is-warning" : "is-loading"} />
          </ProgressRow>
        </div>
        {onClose && <FailedTable />}
      </ModalBodyWrapper>
      <ModalFooter
        {...(onClose && { saveLabel: L10n.format("ok"), onSave: onClose })}
      />
    </Modal>
  );
}
