import {
  ChangeEvent,
  Dispatch,
  FocusEvent,
  KeyboardEvent,
  SetStateAction,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";

import { useCart } from "../../hooks/useCart";
import { ROUTES } from "../../http/constants";
import {
  getActiveQuote,
  setHeader,
} from "../../service/offerManagementService";
import { copyProcessEvent, track } from "../../utilities/eventTracking";
import { HeaderDataPayload } from "./modifyOfferModal/modifyOfferModalActions";
import { OfferManagementDialogState } from "./offerManagement";
import {
  ArchiveQuote,
  ChangeFilter,
  ChangeSort,
  DeleteQuote,
  DuplicateQuote,
  GetQuotes,
  LoadQuote,
  LoadQuoteReadOnly,
  LoadSharedQuote,
  OfferSearch,
  SetFilterArchived,
  ToggleShowOnlyThirdPartySystemOffers,
  ToggleShowOnlyUserOffers,
  preparePayload,
} from "./offerManagementActions";
import {
  OfferManagementTableRow,
  isShared,
} from "./offerManagementTable/offerManagementTableUtils";
import { OfferManagementStore } from "./offerManagementUtils";

export const useOfferManagementDispatcherActions = (
  handleQuoteError: (e: unknown) => void,
  dialogState: OfferManagementDialogState,
  setDialogState: Dispatch<SetStateAction<OfferManagementDialogState>>,
  dispatch: Dispatch<(store: OfferManagementStore) => OfferManagementStore>,
  store: OfferManagementStore,
) => {
  const navigate = useNavigate();
  const { cartActions } = useCart();
  const [isLoadingQuote, setIsLoadingQuote] = useState(false);

  const onToggleShowOnlyUserOffers = () => dispatch(ToggleShowOnlyUserOffers());

  const onToggleShowOnlyThirdPartySystemOffers = () =>
    dispatch(ToggleShowOnlyThirdPartySystemOffers());

  const onSetFilterArchived = (
    target: ChangeEvent<HTMLSelectElement>["target"],
  ) => dispatch(SetFilterArchived(target));

  const onOfferSearch = (
    event: KeyboardEvent<HTMLInputElement> | FocusEvent<HTMLInputElement>,
  ) => {
    dispatch(OfferSearch(event.currentTarget));
    return event.currentTarget.value;
  };

  const onChangeSort = (
    sortKey: string,
    sortDirection: string,
    sortType?: string,
  ) => dispatch(ChangeSort({ sortKey, sortDirection, sortType }));

  const onChangeFilter = (name: string, operator: string, value: string) =>
    dispatch(ChangeFilter({ name, operator, value }));

  const onSetHeader = async (id: string, data: HeaderDataPayload) => {
    try {
      await setHeader(id, data);
      dispatch(
        await GetQuotes(
          preparePayload(store, store.showOnlyUserOffers, store.searchQuery),
        ),
      );
    } catch (e) {
      handleQuoteError(e);
    }
  };

  const onDuplicateQuote = async (id: string) => {
    try {
      dispatch(await DuplicateQuote(id));
      dispatch(
        await GetQuotes(
          preparePayload(store, store.showOnlyUserOffers, store.searchQuery),
        ),
      );
      track(copyProcessEvent);
    } catch (e) {
      handleQuoteError(e);
    }
  };

  const onArchiveQuote = async (id: string, archived: boolean) => {
    try {
      dispatch(await ArchiveQuote(id, archived));
      dispatch(
        await GetQuotes(
          preparePayload(store, store.showOnlyUserOffers, store.searchQuery),
        ),
      );
    } catch (e) {
      handleQuoteError(e);
    }
  };

  const onDeleteQuote = async (id: string) => {
    try {
      dispatch(await DeleteQuote(id));
      dispatch(
        await GetQuotes(
          preparePayload(store, store.showOnlyUserOffers, store.searchQuery),
        ),
      );
      const cartActiveQuote = await getActiveQuote();
      cartActions.overwriteState({
        ...cartActiveQuote.data.cart.cartTO,
        authorities: cartActiveQuote.data.cart.uiRelevantAuthorizationTO,
      });
      cartActions.removeAllCartEntries().then();
    } catch (e) {
      handleQuoteError(e);
    }
  };

  const onLoadQuote = async (id: string) => {
    setIsLoadingQuote(true);
    try {
      dispatch(await LoadQuote(id, cartActions, dialogState, setDialogState));
      setIsLoadingQuote(false);
      navigate(ROUTES.CART, { replace: true });
    } catch (e) {
      setIsLoadingQuote(false);
      handleQuoteError(e);
    }
  };

  const onLoadQuoteReadOnly = async (item: OfferManagementTableRow) => {
    setIsLoadingQuote(true);
    try {
      if (isShared(item.status)) {
        dispatch(
          await LoadSharedQuote(
            item.id,
            cartActions,
            dialogState,
            setDialogState,
          ),
        );
      } else {
        dispatch(
          await LoadQuoteReadOnly(
            item.id,
            cartActions,
            dialogState,
            setDialogState,
          ),
        );
      }
      setIsLoadingQuote(false);
      navigate(ROUTES.CART, { replace: true });
    } catch (e) {
      handleQuoteError(e);
      setIsLoadingQuote(false);
    }
  };

  return {
    onToggleShowOnlyUserOffers,
    onToggleShowOnlyThirdPartySystemOffers,
    onSetFilterArchived,
    onOfferSearch,
    onChangeSort,
    onChangeFilter,
    onSetHeader,
    onDuplicateQuote,
    onArchiveQuote,
    onDeleteQuote,
    onLoadQuote,
    onLoadQuoteReadOnly,
    isLoadingQuote,
  };
};

export type DispatcherActions = ReturnType<
  typeof useOfferManagementDispatcherActions
> & {
  handleQuoteError: (e: unknown) => void;
};
