import classNames from "classnames";
import { head, isEmpty, isNil, mapObjIndexed, values } from "ramda";
import { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { InputTitle } from "../../../components/input/inputTitle";
import { ModalDropdownInput } from "../../../components/modal/ModalDropdownInput";
import { ModalInput } from "../../../components/modal/ModalInput";
import { ModalBodyWrapper, ModalFooter } from "../../../components/modal/modal";
import { TextArea } from "../../../components/textarea";
import { useApp } from "../../../hooks/useApp";
import { useCart } from "../../../hooks/useCart";
import { LvTable } from "../../../hooks/useLvTable";
import { ROUTES } from "../../../http/constants";
import { hasRightToSeeCommentaryExtraOM } from "../../../http/identityHelper";
import { createProductsInProcess } from "../../../service/lvService";
import { loadQuote } from "../../../service/offerManagementService";
import {
  LvAddress,
  LvConstructionAndAddressData,
} from "../../../types/lvTable";
import { getNonOpenedQuoteByOrderNumber } from "../../../utilities/cartUtils";
import {
  getAddressDetails,
  getPersonDetails,
  mergeLeftPersonAndAddress,
  pickAddressFields,
} from "../../../utilities/lvUtils";
import { defaultToString } from "../../../utilities/utilities";
import { t } from "../../shoppingCart/localizationUtils";
import { GenericDropdown } from "../lvTable/components/GenericDropdown";
import { InputConstants } from "../modifyOfferModal/inputConstants";
import { findMaxLength } from "../modifyOfferModal/modifyOfferDialogUtils";

import "./lvOfferTab.scss";

export type LvHeaderData = {
  buildProject: string;
  referenceNumber: string;
  comment: string;
  title: string;
  customerName: string;
  firstname: string;
  surname: string;
  street: string;
  houseNumber: string;
  zip: string;
  city: string;
  phone: string;
  email: string;
};

type AddressFields = Pick<
  LvHeaderData,
  | "firstname"
  | "surname"
  | "street"
  | "houseNumber"
  | "zip"
  | "city"
  | "phone"
  | "email"
>;

export type AddressFieldsWithTitle = AddressFields &
  Pick<LvHeaderData, "title">;

type CustomerInformationProps = {
  addressFields: { [key in keyof AddressFields]: string };
  getChangeHandler: (
    name: keyof AddressFields,
  ) => (event: ChangeEvent<HTMLInputElement>) => void;
};

const CustomerInformation = ({
  addressFields,
  getChangeHandler,
}: CustomerInformationProps) => (
  <div className="two-columns custom-margin-2">
    {values(
      mapObjIndexed(
        (value, key) => (
          <ModalInput
            key={key}
            name={key}
            label={t(`modify_dialog_contact_${key}`)}
            placeholder={t(`modify_dialog_contact_${key}`)}
            value={value}
            onChange={getChangeHandler(key)}
            maxLength={findMaxLength(`modify_dialog_contact_${key}`)}
            inputId={`lv_modify_dialog_contact_${key}`}
          />
        ),
        addressFields,
      ),
    )}
  </div>
);

function getInitialHeaderData(
  addressData: LvConstructionAndAddressData,
): LvHeaderData {
  const { construction, reference, addresses, contactPersons } = addressData;
  const mergedPersonAndAddress = mergeLeftPersonAndAddress(
    head(contactPersons),
    head(addresses),
  );
  return {
    buildProject: defaultToString(head(construction)),
    referenceNumber: defaultToString(head(reference)),
    comment: "",
    ...mergedPersonAndAddress,
  };
}

type LvOfferTabProps = {
  isHidden: boolean;
  lvTable: LvTable;
  onCancel: () => void;
};

export const LvOfferTab = ({
  isHidden,
  lvTable,
  onCancel,
}: LvOfferTabProps) => {
  const { constructionAndAddressData } = lvTable;
  const navigate = useNavigate();
  const { identityStore } = useApp();
  const { cart } = useCart();
  const [lvHeaderData, setLvHeaderData] = useState(
    getInitialHeaderData(constructionAndAddressData),
  );
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedAddress, setSelectedAddress] = useState(
    head(constructionAndAddressData.addresses),
  );
  const [selectedContactPerson, setSelectedContactPerson] = useState(
    head(constructionAndAddressData.contactPersons),
  );

  useEffect(() => {
    setLvHeaderData(getInitialHeaderData(constructionAndAddressData));
    setSelectedAddress(head(constructionAndAddressData.addresses));
    setSelectedContactPerson(head(constructionAndAddressData.contactPersons));
  }, [constructionAndAddressData]);

  function updateLvHeaderData(newLvHeaderData: Partial<typeof lvHeaderData>) {
    setLvHeaderData((prev) => ({ ...prev, ...newLvHeaderData }));
  }

  const getChangeHandler =
    (name: keyof LvHeaderData) =>
    (
      event:
        | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        | MouseEvent<HTMLButtonElement>,
    ) => {
      updateLvHeaderData({ [name]: event.currentTarget.value });
    };

  function handleSelectContactPerson(value: LvAddress) {
    setSelectedContactPerson(value);
    const mergedPersonAndAddress = mergeLeftPersonAndAddress(
      value,
      selectedAddress,
    );
    updateLvHeaderData(mergedPersonAndAddress);
  }

  function handleSelectAddress(value: LvAddress) {
    setSelectedAddress(value);
    const mergedPersonAndAddress = mergeLeftPersonAndAddress(
      selectedContactPerson,
      value,
    );
    updateLvHeaderData(mergedPersonAndAddress);
  }

  async function handleSaveClick() {
    setIsLoading(true);
    try {
      const company = identityStore.getCompany();
      const user = identityStore.getUser();
      if (isNil(company) || isNil(user)) {
        throw new Error("company or user is undefined");
      }
      const { data } = await createProductsInProcess(
        lvTable.checkedLvRows,
        lvHeaderData,
        company,
        user,
      );
      const foundQuote = await getNonOpenedQuoteByOrderNumber(
        data.cartOrderNumber,
        user.Email,
      );
      if (isNil(foundQuote)) {
        throw new Error(
          `no quote found by order number ${data.cartOrderNumber}`,
        );
      }
      await loadQuote(foundQuote.cartTO.id);
      setErrorMessage("");
      onCancel();
      navigate(ROUTES.CART);
    } catch (error) {
      setErrorMessage("offer_management_create_lv_error_new_offer");
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <ModalBodyWrapper className={classNames({ "is-hidden": isHidden })}>
        {!isEmpty(errorMessage) && (
          <p className="has-text-danger mb-4 with-padding">{t(errorMessage)}</p>
        )}
        <div className="two-columns mb-2">
          <ModalDropdownInput
            inputId="lv_building_project"
            name="lvBuildProject"
            label={t("building_project") + t("mandatory_symbol")}
            placeholder={t("building_project")}
            value={lvHeaderData.buildProject}
            onChange={getChangeHandler("buildProject")}
            maxLength={InputConstants.length.building_project}
            options={constructionAndAddressData.construction}
            onMouseDown={getChangeHandler("buildProject")}
          />
          <ModalDropdownInput
            inputId="lv_reference_order_id"
            name="lvReferenceNumber"
            label={t("reference_order_id") + t("mandatory_symbol")}
            placeholder={t("reference_order_id")}
            value={lvHeaderData.referenceNumber}
            onChange={getChangeHandler("referenceNumber")}
            maxLength={InputConstants.length.modify_dialog_reference}
            options={constructionAndAddressData.reference}
            onMouseDown={getChangeHandler("referenceNumber")}
          />
          <span className="smallText with-padding">
            {t("mandatory_symbol_information")}
          </span>
        </div>
        <TextArea
          label={t("comment")}
          subLabel={
            hasRightToSeeCommentaryExtraOM(cart.authorities.booleanAuthorities)
              ? t("will_not_be_transmitted_to_Teckentrup")
              : ""
          }
          value={lvHeaderData.comment}
          onInputChange={getChangeHandler("comment")}
          attributeId="lv_comment"
        />
        <div className="p-hr-title">
          <p>{t("customer")}</p>
          <hr />
        </div>
        <div className="custom-margin-2">
          <div className="quote-addresses with-padding mb-2">
            <p className="has-text-weight-bold">
              {t("modify_dialog_contact_persons")}
            </p>
          </div>
          <div className="two-columns">
            <div className="column-entry with-padding">
              {isEmpty(constructionAndAddressData.contactPersons) ? (
                <span className="is-italic">
                  {t("modify_dialog_contact_no_contact_persons")}
                </span>
              ) : (
                <GenericDropdown
                  value={selectedContactPerson}
                  onChange={handleSelectContactPerson}
                  possibleItems={constructionAndAddressData.contactPersons}
                  dropdownId="addressDropdown"
                  getDisplayValue={getPersonDetails}
                />
              )}
            </div>
          </div>
          <br />
          <div className="quote-addresses with-padding mb-2">
            <p className="has-text-weight-bold">
              {t("modify_dialog_contact_addresses")}
            </p>
          </div>
          <div className="two-columns">
            <div className="column-entry with-padding">
              {isEmpty(constructionAndAddressData.addresses) ? (
                <span className="is-italic">
                  {t("modify_dialog_contact_no_addresses")}
                </span>
              ) : (
                <GenericDropdown
                  value={selectedAddress}
                  onChange={handleSelectAddress}
                  possibleItems={constructionAndAddressData.addresses}
                  dropdownId="addressDropdown"
                  getDisplayValue={getAddressDetails}
                />
              )}
            </div>
          </div>
        </div>
        <div className="quote-addresses with-padding mb-2">
          <h2 className="subtitle has-text-weight-bold">
            {t("modify_dialog_customer_title")}
          </h2>
        </div>
        <div className="two-columns">
          <div className="column-entry">
            <InputTitle
              title={lvHeaderData.title}
              onClick={(title) => updateLvHeaderData({ title })}
            />
          </div>
          <ModalInput
            name={"customerName"}
            label={t("modify_dialog_contact_customerName")}
            placeholder={t("modify_dialog_contact_customerName")}
            value={lvHeaderData.customerName}
            onChange={getChangeHandler("customerName")}
            maxLength={findMaxLength("modify_dialog_contact_customerName")}
            inputId={"lv_modify_dialog_contact_customerName"}
          />
        </div>
        <CustomerInformation
          addressFields={pickAddressFields(lvHeaderData)}
          getChangeHandler={getChangeHandler}
        />
      </ModalBodyWrapper>
      <ModalFooter
        className={classNames({ "is-hidden": isHidden })}
        cancelLabel={t("cancel")}
        saveLabel={t("force_new_offer_dialog_title")}
        saveButtonId="save_import"
        onCancel={onCancel}
        onSave={handleSaveClick}
        disabled={
          isEmpty(lvHeaderData.buildProject) ||
          isEmpty(lvHeaderData.referenceNumber)
        }
        isLoading={isLoading}
      />
    </>
  );
};
