import classNames from "classnames";
import { equals, isEmpty } from "ramda";
import { Link, useLocation } from "react-router-dom";

import { L10n } from "@encoway/l10n";

import { useConfiguration } from "../hooks/useConfiguration";
import { CONSTANTS, ROUTES } from "../http/constants";

type TabProps = Readonly<{
  to: string;
  name: string;
  active?: boolean;
  disabled?: boolean;
}>;

function Tab({ to, name, active = false, disabled = false }: TabProps) {
  return (
    <li
      className={classNames("tab", {
        "is-active": active,
        "is-disabled": disabled,
        disabled,
      })}
    >
      {disabled ? (
        <Link to={"#"}>{L10n.format(name)}</Link>
      ) : (
        <Link to={to}>{L10n.format(name)}</Link>
      )}
    </li>
  );
}

export function Tabs() {
  const { configurationId, reconfiguration, articleName } = useConfiguration();
  const { pathname } = useLocation();

  const configurationPath = reconfiguration
    ? `/reconfiguration/id/${configurationId}/${articleName}/${reconfiguration.articleId}/${reconfiguration.cartPosition}/${reconfiguration.articleOrderStatus}`
    : `/configuration/id/${configurationId}`;

  return (
    <div
      style={{ marginBottom: ".5em" }}
      className="tabs is-centered is-medium is-fullwidth"
    >
      <ul>
        <Tab
          to={ROUTES.PRODUCTS}
          name={CONSTANTS.Tabs.productSelection}
          active={equals(ROUTES.PRODUCTS, pathname)}
        />
        <Tab
          to={configurationPath}
          name={CONSTANTS.Tabs.configuration}
          active={equals(configurationPath, pathname)}
          disabled={isEmpty(configurationId)}
        />
        <Tab
          to={ROUTES.CART}
          active={equals(ROUTES.CART, pathname)}
          name={CONSTANTS.Tabs.shoppingCart}
        />
      </ul>
    </div>
  );
}
