import { isNil } from "ramda";
import { useState } from "react";

import {
  Modal,
  ModalBodyWrapper,
  ModalFooter,
  ModalHeader,
} from "../../../../../components/modal/modal";
import { TextArea } from "../../../../../components/textarea";
import { useCart } from "../../../../../hooks/useCart";
import { shareCart } from "../../../../../service/cartRestService";
import {
  shareConfigurationEvent,
  track,
} from "../../../../../utilities/eventTracking";
import { copyToClipboard } from "../../../../../utilities/utilities";
import { isShared } from "../../../../offerManagement/offerManagementTable/offerManagementTableUtils";
import { t } from "../../../localizationUtils";

type ShareCartErrorModalProps = Readonly<{
  closingMethod: () => void;
}>;

function ShareCartErrorModal({ closingMethod }: ShareCartErrorModalProps) {
  return (
    <Modal className="share-cart-modal">
      <ModalHeader
        label={t("share_shopping_cart_error")}
        onCancel={closingMethod}
      />
      <ModalBodyWrapper label={t("share_shopping_cart_error_message")} />
      <ModalFooter cancelLabel={t("cancel")} onCancel={closingMethod} />
    </Modal>
  );
}

type ShareCartModalProps = Readonly<{
  closingMethod: () => void;
}>;

export function ShareCartModal({ closingMethod }: ShareCartModalProps) {
  const { cart, cartActions } = useCart();
  const { headerData } = cart;

  const [state, setState] = useState({
    shared: !isNil(headerData?.shareUrl),
    shareCartLink: headerData?.shareUrl,
    errorStatus: false,
  });

  const closeDialog = () => {
    if (headerData && !isShared(headerData.status) && state.shared) {
      cartActions.getSharedCart(cart.id);
    }
    closingMethod();
  };

  const copyLinkAndCloseDialog = async () => {
    copyToClipboard(state.shareCartLink ?? "");
    closeDialog();
  };

  const shareCartAndSetUrl = async () => {
    const { data } = await shareCart();

    if (!isNil(data)) {
      setState({
        ...state,
        shared: true,
        shareCartLink: data,
      });
      track(shareConfigurationEvent);
    } else {
      setState({
        ...state,
        errorStatus: true,
      });
    }
  };

  const modalBodyLabel = state.shared
    ? "share_shopping_cart_confirmation"
    : "share_shopping_cart_response";

  const saveLabel = state.shared
    ? t("copy_share_link")
    : t("share_shopping_cart");
  const onSaveEvent = state.shared
    ? copyLinkAndCloseDialog
    : shareCartAndSetUrl;

  if (state.errorStatus) {
    return <ShareCartErrorModal closingMethod={closingMethod} />;
  }

  return (
    <Modal className="share-cart-modal" dialogId="share-shopping-cart">
      <ModalHeader label={t("share_shopping_cart")} onCancel={closingMethod} />
      <ModalBodyWrapper label={t(modalBodyLabel)}>
        {state.shared && (
          <TextArea value={state.shareCartLink} attributeId={modalBodyLabel} />
        )}
      </ModalBodyWrapper>

      <ModalFooter
        cancelLabel={t("cancel")}
        onCancel={closeDialog}
        saveLabel={saveLabel}
        onSave={onSaveEvent}
      />
    </Modal>
  );
}
