import { useCart } from "../../../../hooks/useCart";
import { isInReadOnlyMode } from "../../../../http/identityHelper";
import {
  canNotBeOrdered,
  needsValidation,
} from "../../../../utilities/cartUtils";
import { isShared } from "../../../offerManagement/offerManagementTable/offerManagementTableUtils";
import { t } from "../../localizationUtils";
import { ValidateCartModal } from "./validateCartModal/validateCartModal";
import { WarningBox } from "./warningBox";

export const Warnings = () => {
  const { cart } = useCart();
  const canNotBeOrderedByRight = canNotBeOrdered(cart);

  return (
    <>
      {isShared(cart.headerData?.status) && !canNotBeOrderedByRight && (
        <WarningBox>
          <span
            className="warning-box-content"
            data-label-id="warning-content-cart_offer_is_being_shared_warn"
          >
            {t("cart_offer_is_being_shared_warn")}
          </span>
        </WarningBox>
      )}
      {canNotBeOrderedByRight && (
        <WarningBox className="offer-can-not-be-ordered-again-warning">
          <span
            data-label-id="warning-content-cart_offer_cannot_be_ordered_again_warn"
            className="warning-box-content"
          >
            {t("cart_offer_cannot_be_ordered_again_warn")}
          </span>
        </WarningBox>
      )}
      {isInReadOnlyMode(cart.authorities.booleanAuthorities) && (
        <WarningBox className="quote-is-read-only-warning">
          <span
            data-label-id="warning-content-cart_offer_read_only_warn"
            className="warning-box-content"
          >
            {t("cart_offer_read_only_warn")}
          </span>
        </WarningBox>
      )}
      {needsValidation(cart) && <ValidateCartModal />}
    </>
  );
};
