import {
  faAngleDown,
  faAngleUp,
  faFileLines,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AxiosPromise } from "axios";
import classNames from "classnames";
import { equals, gte, length, map, not } from "ramda";
import { useEffect, useState } from "react";

import { ParameterTO } from "../../../../types/@encoway/Parameter";
import { Value } from "../../../../types/@encoway/Value";
import { LvValue } from "../../../../types/lvTable";
import { isOriginalValue } from "../../../../utilities/lvUtils";
import {
  dropDownValuesSeparable,
  getSelectableDropDownValues,
  getUnselectableDropDownValues,
} from "../../../configurator/customizing/configuratorDropDownUtils";

const ITEM_COUNT_SCROLLABLE_CONTENT = 8;

type LvDropdownItemProps = {
  name: string;
  isNotSelectable: boolean;
  isSelected: boolean;
  onMouseDown: React.MouseEventHandler<HTMLButtonElement>;
  isOriginal?: boolean;
};

const LvDropdownItem = ({
  name,
  isNotSelectable,
  isSelected,
  onMouseDown,
  isOriginal,
}: LvDropdownItemProps) => (
  <button
    className={classNames(
      "dropdown-item is-size-7 is-flex is-align-items-center is-justify-content-space-between",
      {
        "is-not-selectable": isNotSelectable,
        "is-selected": isSelected,
      },
    )}
    onMouseDown={onMouseDown}
  >
    <p>{name}</p>
    {isOriginal && (
      <span className="icon is-small">
        <FontAwesomeIcon
          className={classNames({
            "has-text-success-dark": not(isSelected),
            "has-text-black": isSelected,
          })}
          icon={faFileLines}
        />
      </span>
    )}
  </button>
);

type LvDropdownItemsProps = Readonly<{
  values: Value[] | LvValue[];
  originalValue?: string;
  isSelected: (value: string) => boolean;
  onMouseDown: (value: string) => void;
}>;

function LvDropdownItems(props: LvDropdownItemsProps) {
  const { values, originalValue, isSelected, onMouseDown } = props;
  return (
    <>
      {map(
        ({ value, translatedValue, selectable }) => (
          <LvDropdownItem
            key={value}
            name={translatedValue}
            isNotSelectable={not(selectable) && not(isSelected(value))}
            isSelected={isSelected(value)}
            onMouseDown={() => onMouseDown(value)}
            isOriginal={isOriginalValue(originalValue, value)}
          />
        ),
        values,
      )}
    </>
  );
}

type LvDropdownProps = {
  values: Value[] | LvValue[];
  selectedValue: { value: string; translatedValue: string };
  originalValue?: string;
  isDisabled?: boolean;
  onSelectValue: (value: string) => void;
  getParameterTO?: () => AxiosPromise<ParameterTO>;
};

export const LvDropdown = ({
  values,
  selectedValue,
  originalValue,
  isDisabled,
  onSelectValue,
  getParameterTO,
}: LvDropdownProps) => {
  const [dropdownActive, setDropdownActive] = useState(false);
  const [conflictFreeValues, setConflictFreeValues] = useState(values);

  useEffect(() => {
    setConflictFreeValues(values);
  }, [values]);

  const isOriginal = isOriginalValue(originalValue, selectedValue.value);

  const isScrollable = gte(
    length(conflictFreeValues),
    ITEM_COUNT_SCROLLABLE_CONTENT,
  );

  async function onToggleDropdown() {
    if (not(dropdownActive) && getParameterTO) {
      const result = await getParameterTO();
      if (result.data.values) {
        setConflictFreeValues(result.data.values);
      }
    }
    setDropdownActive((prev) => not(prev));
  }

  return (
    <div className={classNames("dropdown", { "is-active": dropdownActive })}>
      <div className="dropdown-trigger">
        <button
          className="button is-small"
          aria-haspopup="true"
          aria-controls="dropdown-menu"
          onClick={onToggleDropdown}
          onBlur={() => setDropdownActive(false)}
          disabled={isDisabled}
        >
          <span>{selectedValue.translatedValue}</span>
          {isOriginal && (
            <span className="icon is-small">
              <FontAwesomeIcon
                className="has-text-success-dark"
                icon={faFileLines}
              />
            </span>
          )}
          <span className="icon is-small has-text-orange">
            <FontAwesomeIcon icon={dropdownActive ? faAngleUp : faAngleDown} />
          </span>
        </button>
      </div>
      <div className="dropdown-menu w-100" id="dropdown-menu" role="menu">
        <div
          className={classNames("dropdown-content", {
            "is-scrollable": isScrollable,
          })}
        >
          <LvDropdownItems
            values={getSelectableDropDownValues(conflictFreeValues)}
            originalValue={originalValue}
            isSelected={equals(selectedValue.value)}
            onMouseDown={onSelectValue}
          />
          {dropDownValuesSeparable(conflictFreeValues) && (
            <hr className="dropdown-divider" />
          )}
          <LvDropdownItems
            values={getUnselectableDropDownValues(conflictFreeValues)}
            originalValue={originalValue}
            isSelected={equals(selectedValue.value)}
            onMouseDown={onSelectValue}
          />
        </div>
      </div>
    </div>
  );
};
