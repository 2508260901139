import {
  faSort,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isEmpty, isNil, pathOr } from "ramda";
import { useEffect, useMemo, useReducer, useState } from "react";

import { L10n } from "@encoway/l10n";

import {
  FlexTableCell,
  FlexTableHead,
  FlexTableRow,
  FlexTableWrapper,
} from "../../components/flexTable/flexTable";
import { TablePagination } from "../../components/flexTable/tablePagination";
import { Loading } from "../../components/loading";
import { useApp } from "../../hooks/useApp";
import { mapIndexed } from "../../utilities/utilities";
import { ItemRowMenu as CustomerTableActions } from "../offerManagement/offerManagementTable/itemRowMenu";
import {
  AddCustomer,
  ChangeSortingState,
  DeleteCustomer,
  EditCustomer,
  GetCustomers,
  InitialCustomerManagementStore,
  PagingCustomers,
  SearchCustomers,
  SortCustomer,
} from "./customerManagementActions";
import {
  noCustomersFound,
  willPageSizeReduce,
} from "./customerManagementUtils";
import { CustomerModal } from "./customerModal/customerModal";
import { CustomerRemoveDialog } from "./customerRemoveDialog";
import { CustomerSearch } from "./customerSearch";

import "./customerManagement.scss";

const ARROW_DIRECTIONS = {
  default: <FontAwesomeIcon icon={faSort} width="8px" />,
  desc: <FontAwesomeIcon icon={faSortDown} width="8px" />,
  asc: <FontAwesomeIcon icon={faSortUp} width="8px" />,
};

const CustomerTableHeadCell = ({ label, sort, onClick, attributeId }) => {
  return (
    <button
      data-button-id={attributeId}
      className="flex-cell sortableColumn"
      onClick={onClick}
    >
      <span className="icon is-small">
        {pathOr(ARROW_DIRECTIONS.default, [sort], ARROW_DIRECTIONS)}
      </span>
      {label}
    </button>
  );
};

const CustomerManagementTable = ({
  customer,
  store,
  dispatch,
  dialogState,
  setDialogState,
}) => {
  const { customers, customerSearch, sorting } = store;

  const customersList = useMemo(
    () =>
      isNil(customerSearch)
        ? customers[sorting.page]
        : customerSearch[sorting.page],
    [sorting.page, customer, customerSearch],
  );

  const customerTableActions = (selectedCustomer) => [
    {
      action: () =>
        setDialogState({
          ...dialogState,
          toggleModifyDialog: true,
          customer: selectedCustomer,
        }),
      icon: "notiz",
      label: "edit_customer",
    },
    {
      action: () =>
        setDialogState({
          ...dialogState,
          toggleDeleteDialog: true,
          customer: selectedCustomer,
        }),
      icon: "loeschen",
      label: "customer_management_menu_delete",
    },
  ];

  const customerTableTitles = [
    {
      sortKey: "customerNumber",
      label: L10n.format("customer_management_table_customer_number"),
    },
    { sortKey: "customerName", label: L10n.format("customer") },
  ];

  const sortCustomerAscDesc = (index, sortKey) => {
    dispatch(ChangeSortingState(index, sortKey));
    dispatch(SortCustomer(sortKey));
  };

  const getSearchResultInfo = () =>
    isEmpty(customers)
      ? "customer_management_table_no_customers_found"
      : "customer_search_no_entries_found";

  return (
    <>
      <FlexTableWrapper>
        <FlexTableHead>
          {mapIndexed(
            ({ sortKey, label }, index) => (
              <CustomerTableHeadCell
                attributeId={
                  sortKey === "customerNumber" ? "customer-number" : "customer"
                }
                key={index}
                sort={index === sorting.sortCell ? sorting.sort : null}
                label={label}
                onClick={() => sortCustomerAscDesc(index, sortKey)}
              />
            ),
            customerTableTitles,
          )}
          <FlexTableCell className="no-sort" />
        </FlexTableHead>
        {noCustomersFound(store) ? (
          <FlexTableRow>
            <FlexTableCell value={L10n.format(getSearchResultInfo())} />
          </FlexTableRow>
        ) : (
          mapIndexed(
            (cell, index) => (
              <FlexTableRow key={index}>
                <FlexTableCell
                  value={cell.customerNumber}
                  data-cell-id={`customer-number-${cell.customerNumber}`}
                />
                <FlexTableCell
                  value={cell.customerName}
                  data-cell-id={`customer-${cell.customerName}`}
                />
                <FlexTableCell>
                  <CustomerTableActions
                    {...{
                      actions: customerTableActions(cell),
                      menuId: cell.customerNumber,
                    }}
                  />
                </FlexTableCell>
              </FlexTableRow>
            ),
            customersList,
          )
        )}
      </FlexTableWrapper>

      <TablePagination
        currentIndex={store.sorting.page}
        items={isNil(customerSearch) ? customers : customerSearch}
        onClick={(index) => dispatch(PagingCustomers(index))}
      />
    </>
  );
};

export const CustomerManagement = () => {
  const { companyId, identityStore } = useApp();
  const [dialogState, setDialogState] = useState({
    loading: true,
    companyId: null,
    customer: null,
    toggleAddDialog: false,
    toggleDeleteDialog: false,
    toggleModifyDialog: false,
  });
  const [store, dispatch] = useReducer(
    (state, action) => action(state),
    InitialCustomerManagementStore,
  );

  const {
    loading,
    toggleAddDialog,
    toggleDeleteDialog,
    toggleModifyDialog,
    customer,
  } = dialogState;
  const { sorting, searchValue } = store;

  useEffect(() => {
    (async () => {
      dispatch(await GetCustomers(companyId));
      setDialogState({ ...dialogState, loading: false });
    })();
  }, []);

  const resetDialogState = () =>
    setDialogState({
      ...dialogState,
      toggleDeleteDialog: false,
      toggleAddDialog: false,
      toggleModifyDialog: false,
      customer: null,
    });

  const onModifyCustomer = async (selectedCustomer) => {
    dispatch(
      await EditCustomer(
        {
          ...selectedCustomer,
          id: dialogState.customer.id,
          companyId,
        },
        dialogState.companyId,
      ),
    );
    resetDialogState();
  };

  const onAddCustomer = async (selectedCustomer) => {
    dispatch(await AddCustomer(selectedCustomer, dialogState.companyId));
    dispatch(SearchCustomers(searchValue));
    resetDialogState();
  };

  const onDeleteCustomer = async () => {
    dispatch(
      await DeleteCustomer({
        customerId: customer.id,
        companyId: dialogState.companyId,
      }),
    );
    if (
      willPageSizeReduce(sorting.page, store.customers) ||
      willPageSizeReduce(sorting.page, store.customerSearch)
    ) {
      dispatch(PagingCustomers(sorting.page - 1));
    }
    resetDialogState();
  };

  return (
    <div className="customer-management" data-page-id={"customer-management"}>
      <h2 className="h2">{L10n.format("customer_management_overview")}</h2>
      {loading ? (
        <Loading />
      ) : (
        <>
          <CustomerSearch
            onNewCustomerDialog={() =>
              setDialogState({ ...dialogState, toggleAddDialog: true })
            }
            onSearch={({ target: { value } }) =>
              dispatch(SearchCustomers(value))
            }
          />
          <CustomerManagementTable
            customer={customer}
            store={store}
            dispatch={dispatch}
            dialogState={dialogState}
            setDialogState={setDialogState}
          />
        </>
      )}
      {toggleDeleteDialog && (
        <CustomerRemoveDialog
          onDelete={onDeleteCustomer}
          onClose={resetDialogState}
        />
      )}
      {toggleModifyDialog && (
        <CustomerModal
          onSave={onModifyCustomer}
          onCancel={resetDialogState}
          identity={identityStore.getIdentity()}
          customerState={customer}
        />
      )}
      {toggleAddDialog && (
        <CustomerModal
          onSave={onAddCustomer}
          onCancel={resetDialogState}
          identity={identityStore.getIdentity()}
        />
      )}
    </div>
  );
};
