import {
  FlexTableCell as Cell,
  FlexTableFoot,
  FlexTableRow,
} from "../../../../../components/flexTable/flexTable";
import { useCart } from "../../../../../hooks/useCart";
import { useLocaleNumberFormat } from "../../../../../hooks/useLocaleNumberFormat";
import { hasRightToSeeFreeItem } from "../../../../../http/identityHelper";
import { UserRoles } from "../../../../../utilities/cartUtils";
import { CartModalsReturn } from "../../../hooks/useCartModals";
import { CartViews } from "../../../hooks/useCartView";
import { t } from "../../../localizationUtils";
import { ButtonRow } from "../buttonRow";

type FooterCellProps = {
  value: string;
  cellId: string;
};

const FooterCell = ({ value, cellId }: FooterCellProps) => (
  <Cell data-cell-id={cellId} className="is-bold" value={value} />
);

type RowFootProps = {
  toggleModal: CartModalsReturn["modalActions"]["toggleModal"];
  tableViews: CartViews;
  userRoles: UserRoles;
};

export const RowFoot = ({
  toggleModal,
  tableViews,
  userRoles,
}: RowFootProps) => {
  const { cart, cartActions } = useCart();
  const { isSales, isBuyer, isCustomer, isOrder, discount, surcharge } =
    tableViews;
  const { itemCount, articles, itemCountPartOrder } = cart;
  const {
    bruttoGpIncTz,
    ekNettoGp,
    ekNettoGpPartOrder,
    vkNettoGp,
    vkNettoGp2,
    allowance,
    marginNewAbsolut,
    marginAbsolute,
    priceListDiscountAbsolute,
    ekNettoGpInclMargin,
  } = articles;
  const { hasGraduatedDiscount, hasTeoDiscount } = userRoles;
  const { formatPrice } = useLocaleNumberFormat();

  return (
    <>
      {!isOrder && (
        <FlexTableFoot>
          <div className="footer-row">
            <ButtonRow
              cartActions={cartActions}
              toggleModal={toggleModal}
              hasRightToSeeFreeItem={hasRightToSeeFreeItem(
                cart.authorities.booleanAuthorities,
              )}
            />
          </div>
        </FlexTableFoot>
      )}
      <FlexTableRow className="flex-row-foot">
        <Cell />
        <Cell />
        <Cell />
        <Cell />
        <Cell />
        <FooterCell
          cellId={"cart-sum-cart_table_amount"}
          value={`${(isOrder ? itemCountPartOrder : itemCount) || 0} ${t("cart_table_unit_quantity")}`}
        />
        {isBuyer && (
          <>
            <Cell />
            <FooterCell
              cellId={"cart-sum-cart_table_brutto_gp_inc_tz"}
              value={formatPrice(bruttoGpIncTz)}
            />
            <Cell />
            <Cell />
            {hasGraduatedDiscount && <Cell />}
            {hasTeoDiscount && <Cell />}
            <Cell />
            <FooterCell
              cellId={"cart-sum-cart_table_nonsales"}
              value={formatPrice(isOrder ? ekNettoGpPartOrder : ekNettoGp)}
            />
          </>
        )}
        {isOrder && (
          <>
            <Cell />
            <Cell />
            <Cell />
            <Cell />
            {hasGraduatedDiscount && <Cell />}
            {hasTeoDiscount && <Cell />}
            <FooterCell
              cellId={"cart-sum-cart_table_nonsales"}
              value={formatPrice(isOrder ? ekNettoGpPartOrder : ekNettoGp)}
            />
          </>
        )}
        {isCustomer && surcharge && (
          <>
            <FooterCell
              cellId={"cart-sum-cart_table_your_gross_price"}
              value={formatPrice(ekNettoGpInclMargin)}
            />
            <Cell />
            <Cell />
            <FooterCell
              cellId={"cart-sum-cart_table_sales_price"}
              value={formatPrice(vkNettoGp)}
            />
          </>
        )}
        {isCustomer && discount && (
          <>
            <FooterCell
              cellId={"cart-sum-cart_table_your_gross_price"}
              value={formatPrice(bruttoGpIncTz)}
            />
            <Cell />
            <Cell />
            <FooterCell
              cellId={"cart-sum-cart_table_sales_price"}
              value={formatPrice(vkNettoGp2)}
            />
          </>
        )}
        {isSales && (
          <>
            <FooterCell
              cellId={"cart-sum-cart_table_brutto_gp_inc_tz"}
              value={formatPrice(bruttoGpIncTz)}
            />
            <FooterCell
              cellId={"cart-sum-cart_table_allowance"}
              value={formatPrice(allowance)}
            />
            <FooterCell
              cellId={"cart-sum-cart_table_nonsales"}
              value={formatPrice(isOrder ? ekNettoGpPartOrder : ekNettoGp)}
            />
            <Cell />
            {surcharge && !isOrder && (
              <>
                <FooterCell
                  cellId={"cart-sum-cart_table_surcharge"}
                  value={formatPrice(marginAbsolute)}
                />
                <FooterCell
                  cellId={"cart-sum-cart_table_ek_netto_gp_incl_surcharge"}
                  value={formatPrice(ekNettoGpInclMargin)}
                />
                <Cell />
                <Cell />
                <FooterCell
                  cellId={"cart-sum-cart_table_sales_price"}
                  value={formatPrice(vkNettoGp)}
                />
              </>
            )}
            {discount && (
              <>
                <FooterCell
                  cellId={"cart-sum-cart_table_price_list_discount"}
                  value={formatPrice(priceListDiscountAbsolute)}
                />
                <Cell />
                <FooterCell
                  cellId={"cart-sum-cart_table_margin"}
                  value={formatPrice(marginNewAbsolut)}
                />
                <FooterCell
                  cellId={"cart-sum-cart_table_sales_price"}
                  value={formatPrice(vkNettoGp2)}
                />
              </>
            )}
          </>
        )}
        <Cell />
      </FlexTableRow>
    </>
  );
};
