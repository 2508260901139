import { pathOr } from "ramda";
import { useEffect } from "react";

import { L10n } from "@encoway/l10n";
import { Configurator, Sticky } from "@encoway/react-components";

import { SERVICE_BASE_URL } from "../../context/AppProvider";
import { useApp } from "../../hooks/useApp";
import { useConfiguration } from "../../hooks/useConfiguration";
import { hasRightToSeeConexLink } from "../../http/identityHelper";
import { configure } from "./customizing/config";

const ConexLink = () => {
  const { configurationId } = useConfiguration();
  return (
    <a
      href={`${SERVICE_BASE_URL}/conex/?configurationId=${configurationId}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      Conex
    </a>
  );
};

export const ConfiguratorContainer = () => {
  const { conex, identityStore } = useApp();
  const { config, bus, reconfiguration, articleName } = useConfiguration();

  const options = { hideLinkedTree: true, hideInternalTree: true };
  const configurationProductId = pathOr(
    articleName,
    ["configurationStatus", "configuration", "articleName"],
    reconfiguration,
  );

  useEffect(() => {
    configure();
  }, []);

  return (
    <div
      className="configurator column is-three-quarters"
      data-configuration-product-id={configurationProductId}
    >
      <Sticky source=".is-configurator" target=".is-configurator .tabs">
        <Configurator
          config={config}
          lang={L10n.currentLocale()}
          eventBus={bus}
          options={options}
        />
      </Sticky>
      {(hasRightToSeeConexLink(identityStore.getBooleanAuthorities()) ||
        conex) && <ConexLink />}
    </div>
  );
};
