import Big from "big.js";
import { has, or } from "ramda";
import {
  InputAttributes,
  NumericFormatProps,
  numericFormatter,
} from "react-number-format";

import { generateOptions, getLocale } from "./localeNumberFormatUtils";
import { useApp } from "./useApp";
import { useCart } from "./useCart";

// Useful Links:
// 1. https://www.destatis.de/Europa/DE/Staat/Laendercodes.html
// 2. https://en.wikipedia.org/wiki/IETF_language_tag
// 3. https://en.wikipedia.org/wiki/ISO_4217#List_of_ISO_4217_currency_codes

// Challenges:
// 1. If you use Intl.NumberFormat some currency symbols are only displayed in combination with a matching language, otherwise the ISO code is returned
//    - For example: DKK requires DA for kr
// 2. In some countries, currency values are formatted differently from all other values
//    - For example: In Switzerland dot is used as a decimal separator for currency values, but a comma is used for all other values

type PriceValue =
  | string
  | number
  | { price: string | number }
  | null
  | undefined;

export function useLocaleNumberFormat() {
  const { identityStore } = useApp();
  const { cart } = useCart();
  const countryLocale = getLocale(identityStore.getRoleAndRights());
  const numberOptions = generateOptions(countryLocale);
  const currencyOptions = generateOptions(
    countryLocale,
    cart.currencyCode,
    cart.validCurrencyConversionRateFound,
  );

  return {
    formatNumber: (
      value: string | number,
      options: NumericFormatProps<InputAttributes> = {},
    ) => numericFormatter(String(value), { ...numberOptions, ...options }),
    formatPrice: (priceValue: PriceValue) => {
      const value = has("price", priceValue) ? priceValue.price : priceValue;
      // value gets set to 0, if it is null, undefined or an empty string
      // Number.prototype.toFixed() does not work due to rounding errors
      const roundedValue = Big(or(value, 0)).toFixed(2);
      return numericFormatter(roundedValue, currencyOptions);
    },
    getOptions: (isCurrency = false) => ({
      ...numberOptions,
      ...(isCurrency && currencyOptions),
    }),
  };
}
