import PropTypes from "prop-types";
import React from "react";

import { L10n } from "@encoway/l10n";
import { selectedValue } from "@encoway/rest-api";

const MaxStringLength = 40;
const StringSplitCount = 16;

/**
 * Limits txt to n characters.
 * @param {String} txt the text String
 * @param {Number} n the maximal numbers
 * @param {Number} count the string split count
 * @return the limited string
 */
function limit(txt, n, count) {
  if (typeof txt !== "string") {
    return txt;
  }
  if (txt.length <= n) {
    return txt;
  }

  return `${txt.substring(0, count)}\u2026${txt.substring(txt.length - count - 1, count)}`;
}

/**
 * The epri dialog component.
 * @class epri
 */
export default class Epri extends React.Component {
  static propTypes = {
    parameter: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    affectedParameters: PropTypes.array.isRequired,
    onAccept: PropTypes.func,
    onDecline: PropTypes.func,
  };

  render() {
    if (this.props.affectedParameters === null) {
      return null;
    }

    const context = {
      name: this.props.parameter,
      value: limit(this.props.value, MaxStringLength, StringSplitCount),
    };
    const title = L10n.format("epri_accept", {
      name: this.props.parameter,
      value: this.props.value,
    });
    const buttonText = L10n.format("epri_accept", context);
    const content = this.props.affectedParameters.map((p) => {
      let value = selectedValue(p, "#");
      if (value === p.translatedName) {
        value = L10n.format("selected");
      }
      return (
        <tr key={p.id}>
          <td data-cell-id={p.id}>{p.translatedName}</td>
          <td data-cell-id={`${p.id}-${value}`} title={value}>
            {limit(value, MaxStringLength, StringSplitCount)}
          </td>
        </tr>
      );
    });
    return (
      <div className="modal is-active is-epri">
        <div className="modal-background" />
        <div className="modal-card">
          <header className="modal-card-head">
            <p data-dialog-id="epri-dialog" className="modal-card-title">
              {this.props.parameter}
            </p>
          </header>
          <section className="modal-card-body">
            <p>{L10n.format("epri_message", context)}</p>
            <table className="table is-narrow">
              <thead>
                <tr>
                  <th>{L10n.format("epri_name")}</th>
                  <th>{L10n.format("epri_value")}</th>
                </tr>
              </thead>
              <tbody>{content}</tbody>
            </table>
          </section>
          <footer className="modal-card-foot">
            <div className="columns is-multiline is-mobile">
              <button
                className="button is-primary"
                data-button-id="epri_accept"
                title={title}
                onClick={() => this.props.onAccept()}
              >
                {buttonText}
              </button>
              <button
                className="button"
                data-button-id="epri_decline"
                onClick={() => this.props.onDecline()}
              >
                {L10n.format("epri_decline", context)}
              </button>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}
