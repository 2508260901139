import classNames from "classnames";

import { FlexTableCell } from "../../../../../components/flexTable/flexTable";

import "./checkboxCell.scss";

type CheckboxCellProps = {
  optional: boolean;
  setOptional: () => void;
  className?: string;
  dataCheckboxId?: string;
};

export const CheckboxCell = ({
  optional,
  setOptional,
  className,
  dataCheckboxId,
}: CheckboxCellProps) => (
  <FlexTableCell>
    <input
      checked={optional}
      className={classNames("table-checkbox", className)}
      data-checkbox-id={dataCheckboxId}
      onChange={setOptional}
      type="checkbox"
    />
  </FlexTableCell>
);
