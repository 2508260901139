import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import { equals, isNil, join, values } from "ramda";
import { useState } from "react";

import "./GenericDropdown.scss";

type PrimitiveType = string | number | object;

type GenericDropdownProps<T> = {
  value: T | undefined;
  onChange: (value: T) => void;
  possibleItems: T[];
  dropdownId: string;
  valueTextBold?: boolean;
  valueTextItalic?: boolean;
  getDisplayValue?: (value: T) => string;
};

export const GenericDropdown = <T extends PrimitiveType>({
  value,
  onChange,
  possibleItems,
  dropdownId,
  valueTextBold = false,
  valueTextItalic = false,
  getDisplayValue,
}: GenericDropdownProps<T>) => {
  const [dropDownActive, setDropDownActive] = useState(false);
  const toggleDropDown = () => setDropDownActive(!dropDownActive);
  const deactivateDropDown = () => setDropDownActive(false);

  function renderDisplayValue(value: T | undefined): string | number {
    if (isNil(value)) {
      return "";
    }
    if (getDisplayValue) {
      return getDisplayValue(value);
    }
    if (typeof value === "object") {
      return join(" | ", values(value));
    }
    return value;
  }

  return (
    <div
      className={classnames("dropdown object-dropdown", {
        "is-active": dropDownActive,
      })}
    >
      <div className="dropdown-trigger">
        <button
          className={classnames("button", {
            "has-text-weight-bold": valueTextBold,
            "is-italic": valueTextItalic,
          })}
          aria-haspopup="true"
          aria-controls="dropdown-menu"
          data-dropdown-id={dropdownId}
          onClick={toggleDropDown}
          onBlur={deactivateDropDown}
        >
          <span className="selected-value-span">
            {renderDisplayValue(value)}
          </span>
          <span className="icon is-small has-text-orange">
            <FontAwesomeIcon
              icon={dropDownActive ? faAngleUp : faAngleDown}
              size="xs"
            />
          </span>
        </button>
      </div>
      <div className="dropdown-menu">
        <div className="dropdown-content">
          {possibleItems.map((item, index) => (
            <DropDownItem
              key={index}
              value={item}
              itemIndex={index}
              onSelect={onChange}
              isActive={equals(item, value)}
              dropdownId={dropdownId}
              renderDisplayValue={renderDisplayValue}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

type DropDownItemProps<T> = {
  value: T;
  itemIndex: number;
  onSelect: (value: T) => void;
  isActive: boolean;
  dropdownId: string;
  renderDisplayValue: (value: T) => string | number;
};

const DropDownItem = <T extends PrimitiveType>({
  value,
  itemIndex,
  onSelect,
  isActive,
  dropdownId,
  renderDisplayValue,
}: DropDownItemProps<T>) => (
  <button
    className={classnames("dropdown-item", { "is-selected": isActive })}
    onMouseDown={() => onSelect(value)}
  >
    <span data-dropdown-entry-id={`${dropdownId}-${itemIndex}`}>
      {renderDisplayValue(value)}
    </span>
  </button>
);
