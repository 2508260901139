import { any, startsWith } from "ramda";
import { Link, useLocation } from "react-router-dom";

import { L10n } from "@encoway/l10n";

import { CONSTANTS, ROUTES } from "../http/constants";

const CONFIGURATION_ROUTES: string[] = ["#/configuration", "#/reconfiguration"];

function determineTranslationKey(pathname: string): string {
  return any((route) => startsWith(route, pathname), CONFIGURATION_ROUTES)
    ? "error_configuration"
    : "error_general";
}

export function ErrorPage() {
  const { pathname } = useLocation();
  const messageTranslationKey = determineTranslationKey(pathname);

  return (
    <>
      <div className="notification is-warning">
        {L10n.format(messageTranslationKey)}
      </div>
      <Link to={ROUTES.PRODUCTS}>
        {L10n.format(CONSTANTS.Tabs.productSelection)}
      </Link>
    </>
  );
}
