import { isNil } from "ramda";

import {
  ExtendedTeckentrupCartTO,
  TeckentrupCartArticleTO,
} from "../../../../types/cart";
import { DragCartArticleTO } from "../useCartDragAndDrop";

/**
 * Checks if the targetItem is a groupItem or not and returns the right articleId to handle drag and drop via rest-call
 * @param {ExtendedTeckentrupCartTO} cart the cart object
 * @param {TeckentrupCartArticleTO} targetItem the item to check with
 * @returns {string} parentId
 */
export const determineParentId = (
  cart: ExtendedTeckentrupCartTO,
  targetItem: TeckentrupCartArticleTO,
): string =>
  targetItem.group ? targetItem.articleId : cart.articles.articleId;

/**
 * Checks if the targetItem is a groupItem or not and returns the right index to handle drag and drop via rest-call
 * @param {TeckentrupCartArticleTO} targetItem the item to check with
 * @returns {number} parentIndex
 */
export const determineParentIndex = (
  targetItem: TeckentrupCartArticleTO,
): number => (targetItem.group ? 0 : targetItem.index);

export const isBothGroup = (
  draggedItem: TeckentrupCartArticleTO | null,
  targetItem: DragCartArticleTO,
  rootId: string,
): boolean =>
  !isNil(draggedItem) &&
  draggedItem.group &&
  (targetItem?.group || targetItem?.parentId !== rootId);
