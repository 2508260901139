import classNames from "classnames";
import { find, map, not, propEq } from "ramda";
import { useNavigate } from "react-router-dom";

import { Sticky } from "@encoway/react-components";

import { useConfiguration } from "../../hooks/useConfiguration";
import { useLocaleNumberFormat } from "../../hooks/useLocaleNumberFormat";
import { ROUTES } from "../../http/constants";
import { VariantConditions } from "../../types/app";
import { VariantConditionPrices } from "../../types/configuration";
import { addPositionEvent, trackFunction } from "../../utilities/eventTracking";
import { t } from "../shoppingCart/localizationUtils";
import { VisualizationContainer } from "./visualizationContainer";

type BasePriceProps = Readonly<{
  conditionZVAGPriceSum: number | null;
  basePriceConditions: VariantConditions;
}>;

function BasePrice({
  conditionZVAGPriceSum,
  basePriceConditions,
}: BasePriceProps) {
  const { formatPrice } = useLocaleNumberFormat();
  return (
    <div className="px-3 py-2">
      <div className="is-pulled-right" data-label-id="condition-basic-price">
        {formatPrice(conditionZVAGPriceSum)}
      </div>
      <div data-label-id="basic-price">{basePriceConditions.label}</div>
    </div>
  );
}

type TotalPriceProps = Readonly<{
  priceTZ: number | null;
  percentageTZ: number | null;
  totalPriceSum: number | null;
  totalPriceSumWithTZ: number | null;
}>;

function TotalPrice({
  priceTZ,
  percentageTZ,
  totalPriceSum,
  totalPriceSumWithTZ,
}: TotalPriceProps) {
  const { formatPrice } = useLocaleNumberFormat();
  if (percentageTZ) {
    return (
      <div>
        <div className="px-3 py-2">
          <div
            className="boldText is-pulled-right"
            data-label-id={"condition-total-gross-price"}
          >
            {formatPrice(totalPriceSum)}
          </div>
          <div className="boldText">{t("pricing_total_price_gross")}</div>
        </div>
        <div className="px-3 py-2">
          <div className="is-pulled-right" data-label-id={"condition-tz-price"}>
            {formatPrice(priceTZ)}
          </div>
          <div
            className="calculationSymbol"
            data-label-id="condition-calculation-tz"
          >
            +
          </div>
          <div>
            {t("price_details_surcharge")} ({percentageTZ}%)
          </div>
        </div>
        <hr />
        <hr />
        <div className="px-3 py-2">
          <div
            className="boldText is-pulled-right"
            data-label-id={"condition-total-gross-tz-price"}
          >
            {formatPrice(totalPriceSumWithTZ)}
          </div>
          <div className="boldText">
            {t("pricing_total_price_gross_inkl_tz")}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="px-3 py-2">
      <div
        className="boldText is-pulled-right"
        data-label-id={"condition-total-price"}
      >
        {formatPrice(totalPriceSum)}
      </div>
      <div className="boldText" data-label-id="total-price">
        {t("pricing_total_price_gross")}
      </div>
    </div>
  );
}

type VariantConditionProps = Readonly<{
  variantCondition: VariantConditions;
}>;

function VariantCondition({ variantCondition }: VariantConditionProps) {
  const { formatPrice } = useLocaleNumberFormat();
  if (variantCondition.zagp || variantCondition.zva0 || variantCondition.zvam) {
    return (
      <div className="px-3 py-2">
        <div
          className="calculationSymbol"
          data-label-id={`condition-calculation-symbol-${variantCondition.id}`}
        >
          +
        </div>
        <div
          className="is-pulled-right"
          data-label-id={`condition-${variantCondition.id}-price`}
        >
          {formatPrice(variantCondition.conditionTotalPrice)}
        </div>
        <div
          className="variantCondition-label"
          data-label-id={`condition-${variantCondition.id}`}
        >
          {variantCondition.label}
        </div>
      </div>
    );
  }
  return null;
}

type VariantConditionPriceBarProps = Readonly<{
  isVisible: boolean;
  variantConditionPrices: VariantConditionPrices;
}>;

function VariantConditionPriceBar(props: VariantConditionPriceBarProps) {
  const { isVisible, variantConditionPrices } = props;
  if (not(isVisible)) {
    return;
  }
  const {
    variantConditions,
    conditionZVAGPriceSum,
    priceTZ,
    percentageTZ,
    totalPriceSum,
    totalPriceSumWithTZ,
  } = variantConditionPrices;
  const basePriceConditions = find(
    propEq(true, "zvag"),
    variantConditions ?? [],
  );
  return (
    <aside className="menu" style={{ fontSize: "0.8rem" }}>
      <ul className="menu-list">
        <li>
          {basePriceConditions && (
            <BasePrice
              conditionZVAGPriceSum={conditionZVAGPriceSum}
              basePriceConditions={basePriceConditions}
            />
          )}
          {map(
            (variantCondition) => (
              <VariantCondition
                key={variantCondition.id}
                variantCondition={variantCondition}
              />
            ),
            variantConditions ?? [],
          )}
          <hr />
          <hr />
          <TotalPrice
            priceTZ={priceTZ}
            percentageTZ={percentageTZ}
            totalPriceSum={totalPriceSum}
            totalPriceSumWithTZ={totalPriceSumWithTZ}
          />
        </li>
      </ul>
    </aside>
  );
}

type DynamicSmallTextProps = Readonly<{
  isVisible: boolean;
  text: string;
}>;

function DynamicSmallText(props: DynamicSmallTextProps) {
  const { isVisible, text } = props;
  if (not(isVisible)) {
    return;
  }
  return <div className="smallText">{text}</div>;
}

export function ResultContainer() {
  const navigate = useNavigate();
  const { save, loading, configurationId, guiTO, variantConditionPrices } =
    useConfiguration();

  const { translatedName, rootContainer } = guiTO;

  const onSave = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    save(configurationId);
    navigate(ROUTES.CART, { replace: true });
  };

  return (
    <div className="column is-one-quarter">
      <div className="is-source">
        <Sticky source=".is-source" target=".result">
          <div className="result" style={{ height: "80%" }}>
            <div className="resultContainer">
              <div className="boldText" data-label-id="result-title">
                {translatedName}
              </div>
              <DynamicSmallText
                isVisible={
                  rootContainer.readyState === "READY" &&
                  variantConditionPrices.state === "CANCELLED"
                }
                text={t("pricing_no_calculation_possible")}
              />
              <DynamicSmallText
                isVisible={rootContainer.readyState === "NOT_READY"}
                text={t(
                  "pricing_no_calculation_possible_not_all_mandatory_parameters_selected",
                )}
              />
              <VariantConditionPriceBar
                isVisible={variantConditionPrices.state !== "CANCELLED"}
                variantConditionPrices={variantConditionPrices}
              />
            </div>
            <div className="resultButtonContainer">
              <button
                className={classNames([
                  "button is-info is-pulled-right is-orange is-disabled",
                  { "is-loading": loading },
                ])}
                onClick={trackFunction(onSave, addPositionEvent)}
                data-button-id="catalogue_add_to_shopping_cart"
              >
                <div className="is-pulled-left is-hidden-tablet-only is-hidden-desktop-only">
                  {t("catalogue_add_to_shopping_cart")}
                </div>
                <div className="is-pulled-right">
                  <i className="image-icon warenkorb-leer" aria-hidden="true" />
                </div>
              </button>
            </div>
            <VisualizationContainer />
          </div>
        </Sticky>
      </div>
    </div>
  );
}
