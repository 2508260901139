import { curry, map, pick } from "ramda";
import { Dispatch, SetStateAction } from "react";

import IdentityStore from "../../../http/identityStore";
import {
  CartActions,
  ExtendedCartTO,
  ExtendedTeckentrupCartTO,
} from "../../../types/cart";
import { hasUserUnsavedCart } from "../../../utilities/cartUtils";
import { safePick } from "../../../utilities/utilities";
import {
  HeaderDataPayload,
  ModifyOfferDialogReducedState,
  prepareInformationDTO,
} from "../modifyOfferModal/modifyOfferModalActions";
import { OfferManagementDialogState } from "../offerManagement";
import { OfferManagementStore } from "../offerManagementUtils";
import { DispatcherActions } from "../useOfferManagementDispatcherActions";
import {
  OfferManagement,
  OfferManagementTableRow,
  determineLoadFunction,
  isOrdered,
  isQuoteLoadedByOtherUser,
  isQuoteReadOnly,
  isShared,
} from "./offerManagementTableUtils";

export const InitialOfferManagementTableState = {
  orderNumber: "",
  creationDate: "",
  createdBy: "",
  modificationDate: "",
  lastModifiedBy: "",
  buildProject: "",
  referenceNumber: "",
  companyName: "",
  status: "",
  comment: "",
  id: "",
  userId: "",
  creatorUserId: "",
  currentUserId: "",
  clientId: "",
  orderedDate: "",
  isRequest: false,
  active: false,
  archived: false,
  accountId: "",
};

type LoadCartProps = {
  item: OfferManagementTableRow;
  dispatcherActions: DispatcherActions;
  remove: () => Promise<void>;
  cartActions: CartActions;
  identityStore: IdentityStore;
  cart: ExtendedTeckentrupCartTO;
  offerManagement: OfferManagement;
};

type ArchiveProps = {
  item: OfferManagementTableRow;
  dispatcherActions: DispatcherActions;
  archive: boolean;
  identityStore: IdentityStore;
  cart: ExtendedTeckentrupCartTO;
  offerManagement: OfferManagement;
};

type SetDialogAction = Dispatch<
  SetStateAction<{
    quoteId: string;
    visible: boolean;
  }>
>;

const prepareOfferManagementTableRowState = (quote: ExtendedCartTO) => ({
  ...InitialOfferManagementTableState,
  ...pick(
    [
      "id",
      "userId",
      "creatorUserId",
      "active",
      "archived",
      "modificationDate",
      "creationDate",
      "orderNumber",
    ],
    quote.cartTO,
  ),
  ...pick(["currentUserId"], quote.cartTO.properties),
  ...safePick(
    [
      "buildProject",
      "referenceNumber",
      "orderedDate",
      "comment",
      "status",
      "clientId",
      "lastModifiedBy",
      "createdBy",
      "isRequest",
      "accountId",
    ],
    quote.cartTO.headerData,
  ),
  ...safePick(["companyName"], quote.cartTO.headerData?.address),
});

export const prepareOfferManagementTableState = (
  offerManagementStore: OfferManagementStore,
) => map(prepareOfferManagementTableRowState, offerManagementStore.quotes);

export const onCommentModalInputChange = curry(
  (
    { id }: OfferManagementTableRow,
    onSetHeader: (id: string, data: HeaderDataPayload) => Promise<void>,
    commentState: string,
    setCommentModalVisible: Dispatch<SetStateAction<boolean>>,
  ) => {
    onSetHeader(id, { comment: commentState }).then(() =>
      setCommentModalVisible(false),
    );
  },
);

export const onLoadCart = async ({
  item,
  dispatcherActions,
  remove,
  cartActions,
  identityStore,
  cart,
  offerManagement,
}: LoadCartProps) => {
  const quoteReadOnly = isQuoteReadOnly(item, identityStore, cart.authorities);
  const quoteOrdered = isOrdered(item.status);
  const quoteShared = isShared(item.status);
  const unsavedCart = await cartActions
    .getCart()
    .then((cart) => hasUserUnsavedCart(cart));

  if (!quoteReadOnly && isQuoteLoadedByOtherUser(item)) {
    offerManagement.setState((prevState) => ({
      ...prevState,
      cartAlreadyLoadedWarningVisible: true,
    }));
  } else if (!unsavedCart && !quoteOrdered && !quoteShared) {
    determineLoadFunction(
      item,
      identityStore,
      cart.authorities,
      dispatcherActions,
    );
  } else if (quoteShared) {
    offerManagement.setState((prevState: OfferManagementDialogState) => ({
      ...prevState,
      showExistingCartWarning: unsavedCart,
      cartIsSharedWarningVisible: true,
      item: item,
    }));
  } else {
    offerManagement.setState((prevState: OfferManagementDialogState) => ({
      ...prevState,
      showExistingCartWarning: unsavedCart,
      cartAlreadyOrderedWarningVisible: quoteOrdered,
      item: item,
    }));
  }

  remove();
};

export const onToArchive = ({
  item,
  dispatcherActions,
  archive,
  identityStore,
  cart,
  offerManagement,
}: ArchiveProps) => {
  if (
    !isQuoteReadOnly(item, identityStore, cart.authorities) &&
    isQuoteLoadedByOtherUser(item)
  ) {
    offerManagement.setState((prevState: OfferManagementDialogState) => ({
      ...prevState,
      cartAlreadyLoadedWarningVisible: true,
    }));
  } else {
    dispatcherActions.onArchiveQuote(item.id, archive).then();
  }
};

export const onSaveQuote = curry(
  (
    quote: ExtendedCartTO,
    setModifyOfferDialogVisible: SetDialogAction,
    onSetHeader: DispatcherActions["onSetHeader"],
    checkoutInformation: ModifyOfferDialogReducedState,
  ) => {
    onSetHeader(
      quote.cartTO.id,
      prepareInformationDTO(checkoutInformation),
    ).then(() => setModifyOfferDialogVisible({ quoteId: "", visible: false }));
  },
);

export const onDeleteQuote = (
  itemId: string,
  setDeleteCartWarningVisible: SetDialogAction,
  dispatcherActions: DispatcherActions,
) => {
  dispatcherActions
    .onDeleteQuote(itemId)
    .then(() => setDeleteCartWarningVisible({ quoteId: "", visible: false }));
};
