import { faComment } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

import { FlexTableCell } from "../../../../../components/flexTable/flexTable";
import PropertyTextField from "../../../../../components/input/propertyTextField";
import {
  CartActions,
  TeckentrupCartArticleTO,
} from "../../../../../types/cart";
import { ItemState } from "../../../../../utilities/cartUtils";
import { t } from "../../../localizationUtils";
import { SparePartInformationModal } from "../../modals/sparePartInformationModal";
import { CartItemCommentModal } from "./dropdownCell/cartItemCommentModal";

import "./articleCell.scss";

type ArticleCellProps = {
  hasRights: boolean;
  cartActions: CartActions;
  cartItem: TeckentrupCartArticleTO;
  itemRole: ItemState;
  positionNr: string;
};

export const ArticleCell = ({
  hasRights,
  cartActions,
  cartItem,
  itemRole,
  positionNr,
}: ArticleCellProps) => {
  const [toggleSparePartInformation, setToggleSparePartInformation] =
    useState(false);
  const [toggleCommentaryModal, setToggleCommentaryModal] = useState(false);
  const {
    deliveryTime,
    articleName,
    comment,
    minimalAmount,
    longText,
    shortText,
    image,
    materialNumber,
    articleId,
  } = cartItem;
  const { isSparePartItem, isConfigurableItem } = itemRole;

  const onCommentarySubmit = (value: string) => () => {
    cartActions.setComment(cartItem, value).then();
    setToggleCommentaryModal(false);
  };

  return (
    <FlexTableCell>
      <div className="article-info">
        {!isSparePartItem && (
          <span className="article-info-title">{articleName}</span>
        )}
        {isConfigurableItem && (
          <div className="article-properties">
            <div className="article-property">
              <span>{t("cart_title_ref")}</span>
              <PropertyTextField
                className="article-description is-small right"
                property="referenceNo"
                articleId={articleId}
                placeholder={"-"}
                positionNr={positionNr}
              />
            </div>
            <div className="article-property">
              <span>{t("cart_lv_position")}</span>
              <PropertyTextField
                className="article-description is-small right"
                property="lvPosition"
                articleId={articleId}
                placeholder={"-"}
                positionNr={positionNr}
              />
            </div>
            <div className="article-info-deliveryTime is-italic">
              {t("cart_delivery_time")} {deliveryTime}
            </div>
          </div>
        )}
        {isSparePartItem && (
          <div className="article-spare-part-info">
            <div className="article-info-wrapper">
              <div className="article-info-material-number">
                {materialNumber}
              </div>
              <div className="article-info-title is-italic">{articleName}</div>
              <div className="article-info-minimal-amount is-italic">
                {t("cart_minimal_amount")} {minimalAmount}
              </div>
            </div>
            <button
              className="article-info-spare-part-information-icon"
              onClick={() => setToggleSparePartInformation(true)}
            >
              <i className="image-icon exclamation-mark-icon" />
            </button>
          </div>
        )}
        {hasRights && comment && (
          <div className="article-comment">
            <button
              className="article-info-comment"
              onClick={() => setToggleCommentaryModal(true)}
            >
              <FontAwesomeIcon className="is-small" icon={faComment} />
            </button>
          </div>
        )}
        {toggleSparePartInformation && (
          <SparePartInformationModal
            label={articleName}
            image={image}
            shortText={shortText}
            longText={longText}
            onCancel={() => setToggleSparePartInformation(false)}
          />
        )}
        {toggleCommentaryModal && (
          <CartItemCommentModal
            comment={comment}
            onCancel={() => setToggleCommentaryModal(false)}
            onSubmit={onCommentarySubmit}
          />
        )}
      </div>
    </FlexTableCell>
  );
};
