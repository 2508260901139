import {
  faAngleDoubleDown,
  faAngleDoubleUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { equals, isNil, map } from "ramda";
import { Dispatch, SetStateAction, useState } from "react";

import { CharacteristicValues } from "@encoway/rest-api";

import { DEFAULT_CATEGORY } from "../products";
import { CategorySelectionItem } from "./categorySelectionItem";

export type ProductGroup = {
  id: string;
  name: string;
  hasChildProductGroups: boolean;
  characteristicValues: CharacteristicValues;
};

type CategorySelectionProps = {
  currentCategory: string;
  setCurrentCategory: Dispatch<SetStateAction<string>>;
  productGroups: ProductGroup[];
};

export function CategorySelection({
  currentCategory,
  setCurrentCategory,
  productGroups,
}: Readonly<CategorySelectionProps>) {
  const [toggle, setToggle] = useState(
    equals(currentCategory, DEFAULT_CATEGORY),
  );

  const onClick = (id: string) => {
    setCurrentCategory(id);
    setToggle(false);
  };

  if (isNil(productGroups)) {
    return null;
  }

  return (
    <div
      className="categorySelection"
      data-category-selection-unfolded-status={toggle}
    >
      <div className="categorySelectionCards">
        {map(
          (category) => (
            <CategorySelectionItem
              key={category.id}
              isActive={currentCategory === category.id}
              category={category}
              opened={toggle}
              onClickMethod={onClick}
            />
          ),
          productGroups,
        )}
      </div>
      <button
        className="card categorySelectionDropdown"
        onClick={() => setToggle((prev) => !prev)}
        title={toggle ? "Hide further information" : "Show further information"}
        aria-label="more options"
        data-button-id="category_selection_dropdown"
      >
        <span className="icon">
          {toggle ? (
            <FontAwesomeIcon icon={faAngleDoubleUp} width="9px" />
          ) : (
            <FontAwesomeIcon icon={faAngleDoubleDown} width="9px" />
          )}
        </span>
      </button>
    </div>
  );
}
