import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { isNotEmpty, map, not } from "ramda";
import { ComponentProps, MouseEventHandler, useState } from "react";

import { InputMaxLength } from "../input/inputMaxLength";

import "./modalDropdownInput.scss";

type ModalDropdownInputProps = Readonly<
  ComponentProps<typeof InputMaxLength> & {
    options: string[];
    onMouseDown: MouseEventHandler<HTMLButtonElement>;
  }
>;

export const ModalDropdownInput = ({
  options,
  onMouseDown,
  ...props
}: ModalDropdownInputProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <div className="column-entry with-padding">
      <InputMaxLength
        className="dropdownConstructionAndAddressContainer"
        onBlur={() => setIsDropdownOpen(false)}
        onClick={() => setIsDropdownOpen((prev) => not(prev))}
        {...props}
      >
        <div
          className={classNames([
            "input-container dropdown",
            { "is-active": isDropdownOpen },
          ])}
        >
          {isNotEmpty(options) && (
            <>
              <FontAwesomeIcon
                className="icon is-small has-text-orange"
                icon={isDropdownOpen ? faAngleUp : faAngleDown}
              />
              <div className="dropdown-menu dropdownConstructionAndReference">
                <div className="dropdown-content">
                  {map(
                    (option) => (
                      <button
                        key={option}
                        value={option}
                        className="dropdown-item"
                        onMouseDown={onMouseDown}
                      >
                        {option}
                      </button>
                    ),
                    options,
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </InputMaxLength>
    </div>
  );
};
