import {
  addIndex,
  all,
  complement,
  defaultTo,
  either,
  equals,
  isEmpty,
  isNil,
  map,
  pick,
} from "ramda";

const fallBackCopyToClipboard = (text: string): void => {
  const textArea = document.createElement("textarea");
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.select();
  document.execCommand("copy");
  document.body.removeChild(textArea);
};

/**
 * Copies text to clipboard
 *
 * @param {string} text the text to be copied
 * @returns {void}
 */
export const copyToClipboard = (text: string): void => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(text);
  } else {
    fallBackCopyToClipboard(text);
  }
};

export const isNonEmptyString = (
  value: string | null | undefined,
): value is string => {
  if (isNil(value) || isEmpty(value)) {
    return false;
  }
  return true;
};

export const isEmptyString = complement(isNonEmptyString);

export const mapIndexed = <T, U>(
  fn: (item: T, idx: number, list: T[]) => U,
  list: readonly T[],
): U[] => addIndex<T>(map)(fn, list);

export function isStringArray(values: unknown[]): values is string[] {
  return all((value) => equals(typeof value, "string"), values);
}

export function safePick<T extends Record<string, any>>(
  names: Array<keyof T>,
  object: T | null | undefined,
): Partial<T> | {} {
  if (isNil(object)) {
    return {};
  }
  return pick(names, object) as T;
}

export const defaultToString = defaultTo("");

export function isNilOrEmpty(value: string | number): boolean {
  return either(isNil, isEmpty)(value);
}
