import { length, pathOr, T } from "ramda";

import { isReady } from "@encoway/rest-api";

import { ContainerTO } from "../../../../types/configuration";
import { InjectedItem } from "../configuratorTabs";

const beforeTabs = pathOr<InjectedItem[]>([], ["before"]);
const afterTabs = pathOr<InjectedItem[]>([], ["after"]);

export function containerIsEmpty(container: ContainerTO): boolean {
  if (
    Array.isArray(container.parameters) &&
    length(container.parameters) !== 0
  ) {
    return false;
  }
  if (!Array.isArray(container.children)) {
    return true;
  }
  return container.children.every(containerIsEmpty);
}

export function determineActiveTab(
  inject: {
    before: InjectedItem[];
    after: InjectedItem[];
  },
  children: ContainerTO[],
  containerFilter: (container: ContainerTO) => boolean = T,
) {
  if (inject?.before) {
    return inject.before[0].name;
  }
  const filtered = children.filter((tab) => {
    if (containerFilter(tab) === false || containerIsEmpty(tab)) {
      return null;
    }
    return tab;
  });
  if (filtered.length >= 1) {
    return filtered[0].name;
  }
  return children.length >= 1 ? children[0].name : null;
}

const getIndexOfSelectedTab = (
  inject: {
    before: InjectedItem[];
    after: InjectedItem[];
  },
  children: ContainerTO[],
  currentTab: string | null,
) => {
  if (!currentTab) {
    return -1;
  }
  const before = beforeTabs(inject);
  for (let i = 0; i < before.length; i++) {
    if (before[i].name === currentTab) {
      return i;
    }
  }
  for (let i = 0; i < children.length; i++) {
    if (children[i].name === currentTab) {
      return before.length + i;
    }
  }
  const after = afterTabs(inject);
  for (let i = 0; i < after.length; i++) {
    if (after[i].name === currentTab) {
      return before.length + children.length + i;
    }
  }
  return -1;
};

const determineTab = (
  ix: number,
  afterOffset: number,
  after: InjectedItem[],
  before: InjectedItem[],
  navSkipOptionalTabs: boolean,
  children: ContainerTO[],
  containerFilter: (container: ContainerTO) => boolean = T,
) => {
  if (ix >= afterOffset) {
    return after[ix - afterOffset].name;
  }
  if (ix < before.length) {
    return before[ix].name;
  }
  const tab = children[ix - before.length];
  const isFiltered = containerFilter(tab) === false;
  const isEmpty = containerIsEmpty(tab);
  const shouldSkip = navSkipOptionalTabs ? isReady(tab) : false;
  if (!isFiltered && !isEmpty && !shouldSkip) {
    return tab.name;
  }
  return null;
};

const getTabInfos = (
  inject: {
    before: InjectedItem[];
    after: InjectedItem[];
  },
  children: ContainerTO[],
  currentTab: string | null,
) => {
  const before = beforeTabs(inject);
  const after = afterTabs(inject);
  const afterOffset = before.length + children.length;
  const index = getIndexOfSelectedTab(inject, children, currentTab);
  const count = before.length + children.length + after.length;
  return { before, after, afterOffset, index, count };
};

export const determineNextTab = (
  inject: {
    before: InjectedItem[];
    after: InjectedItem[];
  },
  children: ContainerTO[],
  containerFilter: ((container: ContainerTO) => boolean) | undefined,
  currentTab: string | null,
  navSkipOptionalTabs: boolean,
) => {
  const { before, after, afterOffset, index, count } = getTabInfos(
    inject,
    children,
    currentTab,
  );
  if (index === -1 || index === count - 1) {
    return null;
  }
  for (let i = index + 1; i < count; i++) {
    const tab = determineTab(
      i,
      afterOffset,
      after,
      before,
      navSkipOptionalTabs,
      children,
      containerFilter,
    );
    if (tab) {
      return tab;
    }
  }
  return null;
};

export const determinePreviousTab = (
  inject: {
    before: InjectedItem[];
    after: InjectedItem[];
  },
  children: ContainerTO[],
  containerFilter: ((container: ContainerTO) => boolean) | undefined,
  currentTab: string | null,
  navSkipOptionalTabs: boolean,
) => {
  const { before, after, afterOffset, index } = getTabInfos(
    inject,
    children,
    currentTab,
  );
  if (index <= 0) {
    return null;
  }
  for (let i = index - 1; i >= 0; i--) {
    const tab = determineTab(
      i,
      afterOffset,
      after,
      before,
      navSkipOptionalTabs,
      children,
      containerFilter,
    );
    if (tab) {
      return tab;
    }
  }
  return null;
};
