import { equals, isEmpty, not } from "ramda";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useConfiguration } from "../../hooks/useConfiguration";
import { Configuration } from "./configuration";

export function ConfigurationFromId() {
  const params = useParams();
  const { configurationId, start } = useConfiguration();

  useEffect(() => {
    (async () => {
      if (
        isEmpty(configurationId) ||
        not(equals(configurationId, params.configurationId))
      ) {
        await start({ configurationId: params.configurationId });
      }
    })();
  }, []);

  return <Configuration />;
}
