import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import { has } from "ramda";
import React from "react";

import { L10n } from "@encoway/l10n";
import Quantity, {
  Description,
  Icon,
  Viewport,
} from "@encoway/react-components";

import "./parameter.scss";

const defaultStyle = {
  descriptionIcon: "fa-info-circle",
  hasUndo: false,
  showSpecState: true,
  undoIcon: "fa-times",
  defaultIcon: "fa-television",
  userIcon: "fa-user",
  systemIcon: "fa-cog",
  mandatoryIcon: "fa-exclamation-triangle",
  externalIcon: "fa-external-link",
  compact: false,
};

/**
 * A parameter.
 * @class Parameter
 */
export default class Parameter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showInfo: false,
      loading: false,
    };
  }

  setLoading(isLoading) {
    this.setState((prev) => ({
      ...prev,
      loading: isLoading,
    }));
  }

  renderLongTextOverview() {
    if (this.state.showInfo !== true) {
      return null;
    }

    return (
      <Description
        data={this.props.data}
        mediaLink={this.props.mediaLink}
        onClose={() => this.setState({ showInfo: false })}
      />
    );
  }

  handleDescriptionClick() {
    this.setState({ showInfo: true });
  }

  renderDescriptionLink(parameterStyle) {
    if (!this.props.data.longText && !this.props.data.imageUrl) {
      return null;
    }

    return (
      <button
        onClick={() => this.handleDescriptionClick()}
        className="info-icon"
      >
        <span className="icon is-small">
          <Icon
            src={parameterStyle.descriptionIcon}
            title={L10n.format("DESCRIPTION")}
            iconId={`info-icon-${this.props.data.id}`}
          />
        </span>
      </button>
    );
  }

  renderError(small) {
    if (!this.props.error || this.props.error.id !== this.props.data.id) {
      return null;
    }

    const css = classnames({
      notification: true,
      "is-warning": true,
      "is-small": small,
    });
    return (
      <div className={css} title={this.props.error.message}>
        {L10n.format("error_value", this.props.error)}
      </div>
    );
  }

  renderQuantity() {
    const selected = this.props.data.values.filter((v) => v.selected === true);
    if (selected.length === 0) {
      return null;
    }
    if (!has("quantity", selected[0])) {
      return null;
    }
    const quantity = selected[0].quantity;
    if (quantity.editable === false || quantity.maximumQuantity <= 1) {
      return null;
    }

    return (
      <Quantity
        {...quantity}
        data={this.props.data}
        onValueChanged={this.props.onValueChanged}
      />
    );
  }

  renderCompact(parameterStyle) {
    const shortText = this.props.data.shortText ? (
      <span className="help shortText">{this.props.data.shortText}</span>
    ) : null;

    const vp = Viewport.parse(
      this.props.data.name,
      Viewport.orElse(this.props.data.viewPort, "radioButton"),
      this.props.data.multivalued,
    );
    if (vp.name === "hidden") {
      return (
        <div
          id={this.props.data.id}
          data-parameter={this.props.data.name}
          className="content"
        />
      );
    }

    const component = Viewport.instance(vp.name, {
      data: this.props.data,
      style: { single: false, ...parameterStyle, ...vp.style },

      mediaLink: this.props.mediaLink,
      onValueChanged: this.props.onValueChanged,
      onFocus: this.props.onFocus,

      options: this.props.options,
      config: this.props.config,

      setLoading: (isLoading) => this.setLoading(isLoading),
      loading: this.state.loading,
    });

    const containerCss = classnames({
      columns: true,
      "is-ready": this.props.data.terminal === true,
      "is-mandatory":
        this.props.data.mandatory === true &&
        this.props.data.terminal === false,
    });
    const parameterCss = classnames({
      parameter: true,
      "is-mandatory":
        this.props.data.mandatory === true &&
        this.props.data.terminal === false,
    });
    return (
      <div
        id={this.props.data.id}
        className={containerCss}
        data-parameter={this.props.data.name}
      >
        <div className={`column ${parameterStyle.compactLeftColumn}`}>
          <div
            className={parameterCss}
            data-icon-id={
              parameterCss.includes("is-mandatory")
                ? `warning-id-${this.props.data.id}`
                : null
            }
          >
            <div className="translated-name">
              {this.props.data.translatedName}
              {this.renderDescriptionLink(parameterStyle)}
            </div>
            <div className="description">{this.renderState()}</div>
            {this.props.data.mandatory === true &&
              this.props.data.terminal === false && (
                <FontAwesomeIcon
                  icon={faTriangleExclamation}
                  style={{ color: "#ef7b22" }}
                  data-icon-id={`warning-icon-${this.props.data.id}`}
                />
              )}
          </div>
          {shortText}
          {this.renderLongTextOverview(parameterStyle)}
        </div>
        <div className={`column ${parameterStyle.compactRightColumn}`}>
          {this.renderError(true)}
          {component}
          {this.renderQuantity()}
        </div>
      </div>
    );
  }

  renderState() {
    const vp = Viewport.parse(`${this.props.data.name}State`, "parameterState");
    return Viewport.instance(vp.name, {
      ...this.props,
      setLoading: (isLoading) => this.setLoading(isLoading),
    });
  }

  render() {
    const parameterStyle = { ...defaultStyle, ...this.props.style };

    if (parameterStyle.compact === true) {
      return this.renderCompact(parameterStyle);
    }

    const shortText = this.props.data.shortText ? (
      <p className="shortText">{this.props.data.shortText}</p>
    ) : null;

    const vp = Viewport.parse(
      this.props.data.name,
      Viewport.orElse(this.props.data.viewPort, "radioButton"),
      this.props.data.multivalued,
    );
    if (vp.name === "hidden") {
      return (
        <div
          id={this.props.data.id}
          data-parameter={this.props.data.name}
          className="content"
        />
      );
    }

    if (has("single", vp.style)) {
      vp.single = vp.style.single;
    }

    const component = Viewport.instance(vp.name, {
      data: this.props.data,
      style: vp.single ? { ...parameterStyle, ...vp.style } : vp.style,

      mediaLink: this.props.mediaLink,
      onValueChanged: this.props.onValueChanged,
      onFocus: this.props.onFocus,

      options: this.props.options,
      config: this.props.config,
    });

    if (vp.single === true) {
      return (
        <div id={this.props.data.id} data-parameter={this.props.data.name}>
          {this.renderError(false)}
          {component}
          {this.renderQuantity()}
        </div>
      );
    }

    const containerCss = classnames({
      "is-ready": this.props.data.terminal === true,
      "is-mandatory":
        this.props.data.mandatory === true &&
        this.props.data.terminal === false,
    });
    const parameterCss = classnames({
      parameter: true,
      "is-mandatory":
        this.props.data.mandatory === true &&
        this.props.data.terminal === false,
    });
    return (
      <div
        id={this.props.data.id}
        className={containerCss}
        data-parameter={this.props.data.name}
      >
        <article className="media">
          <div className="media-content">
            <div className="content">
              <p
                className={parameterCss}
                data-icon-id={
                  parameterCss.includes("is-mandatory")
                    ? this.props.data.id
                    : null
                }
              >
                <div className="translated-name">
                  {this.props.data.translatedName}
                </div>
                <div className="description">
                  {this.renderDescriptionLink(parameterStyle)}{" "}
                  {this.renderState()}
                </div>
              </p>
              {shortText}
              {this.renderLongTextOverview(parameterStyle)}
            </div>
            {this.renderError(false)}
          </div>
        </article>
        {component}
        {this.renderQuantity()}
      </div>
    );
  }
}
