import { useEffect, useState } from "react";

import { Config } from "../../config/config";

import "./extendableImage.scss";

const ESCAPE_KEY = "Escape";

type ImageOverlayProps = {
  src: string;
  onKeyDown: (event: KeyboardEvent) => void;
};

const ImageOverlay = ({ src, onKeyDown }: ImageOverlayProps) => {
  useEffect(() => {
    document.addEventListener("keydown", onKeyDown, false);
    return () => {
      document.removeEventListener("keydown", onKeyDown, false);
    };
  }, []);

  return (
    <div className="overlay">
      <img
        className="bigImage"
        src={`${src}${Config.ImageResolution.Medium}`}
        alt="big overlay"
      />
    </div>
  );
};

export const ExtendableImage = ({ src }: { src: string }) => {
  const [isBig, setIsBig] = useState(false);
  const toggleIsBig = () => setIsBig((prev) => !prev);
  const onKeyDownImageOverlay = (event: KeyboardEvent) =>
    event.key === ESCAPE_KEY && setIsBig(false);

  return (
    <button onClick={toggleIsBig} className="extendableImage">
      <img
        className="image"
        data-product-image-url={src.replace(/[.:?=/]/g, "-")}
        src={`${src}${Config.ImageResolution.Medium}`}
        alt="overlay"
      />
      {isBig && <ImageOverlay src={src} onKeyDown={onKeyDownImageOverlay} />}
    </button>
  );
};
