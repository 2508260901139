export const Authorities = {
  CART_BUTTON_DEACTIVATE_HEADER_DATA_EDIT:
    "warenkorb_button_deaktiv_kopfdaten_bearbeiten",
  CART_BUTTON_DEACTIVATE_EXPORT_QUOTE:
    "warenkorb_button_deaktiv_export_angebot",
  CART_BUTTON_DEACTIVATED_EXPORT_EXCEL: "warenkorb_button_deaktiv_export_excel",
  CART_BUTTON_DEACTIVATE_ORDER: "warenkorb_button_deaktiv_bestellen",
  CART_BUTTON_VISIBLE_FREE_POSITION: "warenkorb_button_sichtbar_freie_position",
  CART_BUTTON_VISIBLE_ORDER: "warenkorb_button_sichtbar_bestellen",
  CART_BUTTON_VISIBLE_DB_CHECK: "warenkorb_button_sichtbar_db_pruefen",
  CART_BUTTON_VISIBLE_SHARE: "warenkorb_button_sichtbar_vorgang_teilen",
  CART_TEXT_VISIBLE_COMMENTARY_EXTRA:
    "warenkorb_position_kommentar_sichtbar_text_uebermittelt",
  CART_PRINT_XML_VISIBLE: "warenkorb_drucken_sichtbar_xml",
  CART_PRINT_EN_DOC_XML_VISIBLE: "warenkorb_button_encoway_doc_xml_export",
  CART_TEXT_VISIBLE_FREE_ITEM_EXTRA:
    "warenkorb_position_freie_position_sichtbar_text_uebermittelt",
  CART_INFORMATION_VISIBLE_BUILDING_PROJECT:
    "anwendung_status_anzeige_sichtbar_bauvorhaben",
  CART_INFORMATION_VISIBLE_REFERENCE:
    "anwendung_status_anzeige_sichtbar_referenz",
  CART_READ_ONLY_MODE: "warenkorb_read_only_modus",
  CART_DROPDOWN_VISIBLE_SHIPPING_METHOD:
    "warenkorb_versandart_dynamisch_sichtbar",
  CART_SHIPPING_PRICE_MANUAL_INPUT: "warenkorb_versandart_manuell_sichtbar",
  OFFER_MANAGEMENT_VISIBLE_COMMENTARY_EXTRA:
    "vorgangsverwaltung_kommentar_sichtbar_text_uebermittelt",
  OFFER_MANAGEMENT_IMPORT_LV: "vorgangsverwaltung_button_lv_importieren",
  OFFER_MANAGEMENT_Configure_LV: "vorgangsverwaltung_lv_konfigurieren",
  CONFIGURATION_VISIBLE_CONEX: "konfiguration_sichtbar_conex",
  HEADER_DATA_EXTENDED_INFORMATION:
    "vorgangsverwaltung_kopfdaten_sichtbar_erweiterte_vorgangsdaten",
  OFFER_SHARED_METADATA: "vorgang_geteilte_metadaten_sichtbar",
  VISUALIZATION_VISIBLE: "vis_anzeige_sichtbar",
  VISUALIZATION_AR_VISIBLE: "vis_button_qr_code_sichtbar",
  OFFER_MANAGEMENT_VISIBLE_BURGER_MENU: {
    NAME: "vorgangsverwaltung_burger_menu",
    VALUES: {
      VIEW_CART: "offer_management_menu_view_cart", // open
      DUPLICATE: "offer_management_menu_duplicate",
      EDIT: "offer_management_menu_edit_headerdata", // header_data
      ARCHIVE: "offer_management_menu_archive",
      REACTIVE: "offer_management_menu_reactivate",
      DELETE: "offer_management_menu_delete",
      INSPECT_CART: "offer_management_menu_inspect_cart", // view
      COPY: "offer_management_copy",
    },
  },
  CART_VISIBLE_CALCULATION_VIEW: {
    NAME: "warenkorb_kalkansichten",
    VALUES: {
      BUYER: "cart_cost_calculation",
      SALES: "cart_sales_calculation",
      CUSTOMER: "cart_customer_view",
      ORDER: "cart_order_view",
    },
  },
  CART_VISIBLE_BURGER_MENU: {
    NAME: "warenkorb_position_burger_menu",
    VALUES: {
      RECONFIGURE: "cart_title_reconfigure",
      DELIVERY_CHANGE: "cart_title_delivery_time_change",
      DRAWING: "cart_title_get_drawing",
      COPY: "cart_title_copy_article",
      COMMENT: "cart_title_comment",
      DELETE: "cart_title_delete",
    },
  },
  CATALOG_VISIBLE_ALL_PRODUCTS: "katalog_sichtbar_alle_produkte",
};

export const AUTHORITY_ROLE = {
  ADMIN: "msAdmin",
  INNENDIENST: "msInnendienst",
  DEFAULT_USER: "msDefaultAnwender",
  ARCHITECT: "msArchitektPlaner",
  INDIRECT_CUSTOMER: "msIndirekterKunde",
  CUSTOMER: "msKunde",
  AUSSENDIENST: "msAussendienst",
};

export type AuthorityRoleKey = keyof typeof AUTHORITY_ROLE;
