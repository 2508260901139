import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import { defaultTo, find, isNil, map, not, pathOr, propEq } from "ramda";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { Config } from "../../../config/config";
import { ParameterTO } from "../../../types/@encoway/Parameter";
import { Value } from "../../../types/@encoway/Value";
import { ConfiguratorComponentProps } from "../../../types/configuration";

const IMAGE_WIDTH = "256px";

function getSelectedValue(values: Value[] | undefined): Value | undefined {
  return find(propEq(true, "selected"), defaultTo([], values));
}

function strip(html: string) {
  const tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent ?? tmp.innerText;
}

type ModalItemProps = ConfiguratorComponentProps<ParameterTO> & {
  value: Value;
  onSelect: (value: string) => void;
};

const ModalItem = ({ value, onSelect, ...props }: Readonly<ModalItemProps>) => (
  <button
    className="dropdown-imagebutton-item"
    onClick={() => onSelect(value.value)}
    data-image-button-id={`${props.data.name}-${value.value}`}
  >
    <div
      className={classnames("button is-image dropdown-imagebutton-headline", {
        "is-primary": value.selected,
        "is-selectable": not(value.selected) && value.selectable,
        "is-not-selectable": not(value.selected) && not(value.selectable),
      })}
      style={{ width: IMAGE_WIDTH }}
      {...(value.selected && {
        "data-selected-image-button-id": `${props.data.name}-${value.value}`,
      })}
    >
      {value.translatedValue}
    </div>
    <div style={{ width: IMAGE_WIDTH, cursor: "pointer" }}>
      <img
        src={`${props.mediaLink(value.imageUrl)}${Config.ImageResolution.Medium}`}
        alt=""
      />
    </div>
    <div
      className="dropdown-imagebutton-subtext"
      style={{ width: IMAGE_WIDTH }}
    >
      {value.longText && strip(value.longText)}
    </div>
  </button>
);

type DropdownModalProps = ConfiguratorComponentProps<ParameterTO> & {
  setActive: Dispatch<SetStateAction<boolean>>;
  onSelect: (value: string) => void;
};

const DropdownModal = ({
  setActive,
  onSelect,
  ...props
}: Readonly<DropdownModalProps>) => (
  <div className="modal is-active" id="dropdown-menu" role="menu">
    <div className="modal-background" />
    <div
      className="modal-card modal-card-width-drowpdown"
      data-dialog-id={props.data.name}
    >
      <header className="modal-card-head">
        <p className="modal-card-title">{props.data.translatedName}</p>
        <button
          className="delete"
          aria-label="close"
          onClick={() => setActive(false)}
        />
      </header>
      <section className="modal-card-body flex-items">
        {map(
          (value) => (
            <ModalItem
              key={value.value}
              value={value}
              onSelect={onSelect}
              {...props}
            />
          ),
          pathOr<Value[]>([], ["data", "values"], props),
        )}
      </section>
    </div>
  </div>
);

type TriggerIconProps = ConfiguratorComponentProps<ParameterTO> & {
  isLoading: boolean;
  hasWarning: boolean | undefined;
};

const TriggerIcon = ({
  isLoading,
  hasWarning,
  ...props
}: Readonly<TriggerIconProps>) => {
  if (isLoading || props.loading) {
    return (
      <i
        className={classnames("fa fa-angle-down button is-loading", {
          "dropdown-imagebutton-warning": hasWarning,
        })}
        style={{ borderLeft: "none", borderRight: "none" }}
        aria-hidden
      />
    );
  }
  return <FontAwesomeIcon icon={faAngleDown} size="xs" />;
};

type ModalTriggerProps = ConfiguratorComponentProps<ParameterTO> & {
  isLoading: boolean;
  setActive: Dispatch<SetStateAction<boolean>>;
};

const ModalTrigger = ({
  isLoading,
  setActive,
  ...props
}: Readonly<ModalTriggerProps>) => {
  const selectedValue = getSelectedValue(props.data.values);
  const hasWarning = props.data.mandatory && isNil(selectedValue);
  return (
    <div className="dropdown">
      <div className="dropdown-trigger">
        <button
          className={classnames("button", {
            "dropdown-imagebutton-warning": hasWarning,
          })}
          disabled={not(props.data.editable)}
          aria-haspopup="true"
          aria-controls="dropdown-menu"
          onClick={() => setActive(true)}
        >
          <span
            data-selected-value-id={`${props.data.name}-${selectedValue?.value}`}
          >
            {selectedValue?.translatedValue}
          </span>
          <span className="icon is-small">
            <TriggerIcon
              isLoading={isLoading}
              hasWarning={hasWarning}
              {...props}
            />
          </span>
        </button>
      </div>
    </div>
  );
};

export const DropdownImageButton = (
  props: Readonly<ConfiguratorComponentProps<ParameterTO>>,
) => {
  const [active, setActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const currentValue = pathOr(
    "",
    ["data", "selectedValues", 0, "value"],
    props,
  );

  useEffect(() => {
    setIsLoading(false);
  }, [currentValue]);

  function onSelect(value: string) {
    if (currentValue === value) {
      setActive(false);
      return;
    }
    setIsLoading(true);
    props.onValueChanged(props.data, value);
    setActive(false);
  }

  return (
    <div className="control is-expanded">
      <ModalTrigger isLoading={isLoading} setActive={setActive} {...props} />
      {active && (
        <DropdownModal setActive={setActive} onSelect={onSelect} {...props} />
      )}
    </div>
  );
};
