import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import { isEmpty, not } from "ramda";
import React from "react";

export class Dropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
    };
  }

  /**
   * Handler for the selection of a dropdown item.
   * @param valueId id of the selected value
   * @return {void}
   */
  handleChange(valueId) {
    const value =
      this.props.dropdownId === "shipping_method"
        ? this.props.cartViews.isOrder
        : this.props.entry;
    this.props.onClickMethod(valueId, value);
  }

  /**
   * Creates the dropdown items out of the given values.
   *
   * @param values The values to create dropdown items out of
   * @return {*} dropdown items
   */
  createDropdownItems(values) {
    return values
      .sort((a, b) => this.sortEntries(a, b))
      .map((value) => {
        const dropdownItemCss = classnames("dropdown-item ml-0", {
          [value.css]: true,
        });

        return (
          <button
            key={value.id}
            data-filter-entry-id={value.id.replace(/[.:?=\s+/]/g, "")}
            className={dropdownItemCss}
            onMouseDown={this.handleChange.bind(this, value.id)}
          >
            <article className="media">
              <div className="media-content">
                <p>{value.value}</p>
              </div>
            </article>
          </button>
        );
      });
  }

  /**
   * Compare function for the dropdown items.
   *
   * @param value1 First value to compare
   * @param value2 Second value to compare
   * @returns {number}
   */
  sortEntries(value1, value2) {
    if (value1.id === "__undo__") {
      return -1;
    }
    return value1.value.localeCompare(value2.value);
  }

  /**
   * Returns the current selected dropdown item.
   */
  getSelectedItem() {
    return this.props.possibleValues?.find(
      (value) => value?.css === "is-selected",
    );
  }

  render() {
    const dropdownCss = classnames("dropdown", {
      "is-active": this.state.active,
    });
    const buttonCss = classnames("button ml-0", {
      "is-selected": this.props.isSelected,
    });

    return (
      <div className={dropdownCss}>
        <div className="dropdown-trigger">
          <button
            className={buttonCss}
            data-filter-id={`${this.props.characteristicId || this.getSelectedItem().translationKey}-filter`}
            data-filter-value={this.getSelectedItem().id.replace(
              /[.:?=\s+/]/g,
              "",
            )}
            onClick={() =>
              this.setState((prevState) => ({ active: !prevState.active }))
            }
            onBlur={() => this.setState({ active: false })}
            aria-haspopup="true"
            aria-controls="dropdown-menu"
          >
            <span>{this.getSelectedItem().value}</span>
            <span className="icon is-small">
              <FontAwesomeIcon icon={faAngleDown} size="xs" />
            </span>
          </button>
        </div>
        <div className="dropdown-menu" id="dropdown-menu" role="menu">
          <div className="dropdown-content">
            {this.createDropdownItems(this.props.possibleValues)}
            {not(isEmpty(this.props.notPossibleValues)) && (
              <hr className="dropdown-divider" />
            )}
            {this.createDropdownItems(this.props.notPossibleValues)}
          </div>
        </div>
      </div>
    );
  }
}
