import classNames from "classnames";
import {
  concat,
  equals,
  find,
  isEmpty,
  isNil,
  pathOr,
  propEq,
  reduce,
} from "ramda";
import { KeyboardEvent, useEffect, useMemo, useState } from "react";

import { useCart } from "../../hooks/useCart";
import { useConfiguration } from "../../hooks/useConfiguration";
import { ParameterTO } from "../../types/@encoway/Parameter";
import { Value } from "../../types/@encoway/Value";
import { TeckentrupCartArticleTO } from "../../types/cart";

import "./propertyTextField.scss";

const CONFIRM_KEY = "Enter";

/**
 * In case this component is used in cart, the value to set must be found recursively.
 * This is the case, because the depth of the (sub)articles tree can vary and be infinite.
 * @param articles the tree of articles
 * @param articleId the id of the article the value should be read from
 */
function findSubArticle(
  articles: TeckentrupCartArticleTO[],
  articleId: string,
): TeckentrupCartArticleTO | null {
  const found = find(propEq(articleId, "articleId"), articles);
  if (!isNil(found)) {
    return found;
  }
  return reduce<TeckentrupCartArticleTO, TeckentrupCartArticleTO | null>(
    (foundArticle, article) => {
      if (!isEmpty(article.subArticles) && isNil(foundArticle)) {
        return findSubArticle(article.subArticles, articleId);
      }
      return foundArticle;
    },
    null,
    articles,
  );
}

/**
 * Type for the property text field props.
 *  @param property: name of the Property the text field is used for (equal to the fields name in (sub)article)
 *  @param articleId: ID of the article in the cart, the text field is rendered for (not used in configuration)
 *  @param className: additional styling
 *  @param data: set by the configuration when this text field is used as a configuration view port (not used in cart)
 */
type PropertyTextFieldPropsType = {
  property: string;
  className: string;
  articleId?: string;
  data?: ParameterTO;
  placeholder?: string;
  positionNr?: string;
};

/**
 * This component can be used as configuration viewport and cart component. Therefor a lot of functions use the articleId property as a way to determine
 * either if it's rendered as a configuration view port (no articleId set) or cart component (articleId set).
 * @param props of the component. Which ones are set vary depending on the page its rendered (configuration, or cart)
 */
export default function PropertyTextField(
  props: Readonly<PropertyTextFieldPropsType>,
) {
  const { cartActions, cart, properties } = useCart();
  const { reconfiguration } = useConfiguration();
  const [value, setValue] = useState<string>("");
  const [isConfirming, setIsConfirming] = useState<boolean>(false);

  const dataId = useMemo(
    () =>
      concat(
        `${pathOr("", ["positionNr"], props)}-`,
        isNil(props.property)
          ? pathOr("", ["data", "name"], props)
          : props.property,
      ),
    [props],
  );

  /**
   * Determines the value displayed in the text field depending on kind of configuration and page and also the concrete property.
   */
  useEffect(() => {
    if (isNil(props.data) && !isNil(props.articleId)) {
      const subArticle = findSubArticle(
        cart.articles.subArticles,
        props.articleId,
      );
      setValue(pathOr("", [props.property], subArticle));
    } else if (reconfiguration && isNil(props.articleId)) {
      const subArticle = findSubArticle(
        cart.articles.subArticles,
        reconfiguration.articleId,
      );
      if (!isNil(subArticle)) {
        if (props.data?.name === "ALL_M_LV_POS") {
          setValue(subArticle.lvPosition);
        } else {
          setValue(subArticle.referenceNo);
        }
      } else {
        setValue("");
      }
    } else if (!isEmpty(props.data?.values)) {
      const foundValue = find(
        (value: Value) => value.selectionSource !== "NOT_SET",
        pathOr([], ["data", "values"], props),
      );
      setValue(pathOr("", ["value"], foundValue));
    }
  }, [cart]);

  const propertyValues = properties.values();

  useEffect(() => {
    setIsConfirming(false);
  }, [propertyValues]);

  /**
   * To set a value, the component must distinguish between two scenarios it's used in: configuration, or cart.
   * Case 1 (configuration): Article does not exist in cart yet.
   * Entries are mapped in the cart store and will be added to the article, after the article itself was added to the cart according to the configuration.
   * Case2 (cart): Article exists in cart. Entries can be set directly to the article.
   */
  function confirm() {
    setIsConfirming(true);
    const property =
      equals(props.data?.name, "ALL_M_LV_POS") ||
      equals(props.property, "lvPosition")
        ? "_lv_pos"
        : "_reference_no";
    if (isNil(props.articleId)) {
      cartActions.setPropertyConfiguration(property, value);
      return;
    }
    cartActions.setProperty({ articleId: props.articleId }, property, value);
  }

  function enterValue(event: KeyboardEvent<HTMLInputElement>) {
    if (equals(event.key, CONFIRM_KEY)) {
      confirm();
    }
  }

  return (
    <div
      className={classNames("wrapper control", {
        "control is-loading": isConfirming,
      })}
    >
      <input
        className={classNames(["input", props.className])}
        type="text"
        data-input-field-id={dataId}
        value={value}
        onChange={(event) => setValue(event.target.value)}
        onBlur={confirm}
        onKeyDown={enterValue}
        maxLength={30}
        placeholder={pathOr("", ["placeholder"], props)}
      />
    </div>
  );
}
