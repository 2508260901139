import classNames from "classnames";
import { isNil } from "ramda";
import { ComponentPropsWithoutRef } from "react";

type InputProps = ComponentPropsWithoutRef<"input"> & {
  label?: string;
  inputId?: string;
};

export function Input({ label, className, inputId, ...props }: InputProps) {
  return (
    <>
      {!isNil(label) && <label className="label">{label}</label>}
      <input
        className={classNames(["input", className])}
        type="text"
        data-input-field-id={inputId}
        {...props}
      />
    </>
  );
}
