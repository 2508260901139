import { keys, not, pathOr, reduce } from "ramda";
import { ChangeEvent, useMemo } from "react";

import { RadioButton } from "../../../../components/input/radioButton";
import { PlainDropdown } from "../../../../components/plainDropdown";
import { useCart } from "../../../../hooks/useCart";
import { hasRightToSeeQuoteCalculationView } from "../../../../http/identityHelper";
import {
  BasedOn,
  CartViewReturn,
  cartViews,
  CartViewsState,
  LOCAL_STORAGE_CART_VIEW_BASED_ON_KEY,
  ViewName,
} from "../../hooks/useCartView";

import "./cartView.scss";

const retrieveRight = (hasRights: Record<ViewName, boolean>, right: string) =>
  pathOr(false, [right], hasRights);

const transformRights = (hasRights: Record<ViewName, boolean>) =>
  reduce<ViewName, Array<{ value: string; label: string }>>(
    (acc, cur) =>
      retrieveRight(hasRights, cur)
        ? [...acc, { value: cur, label: cartViews.view[cur].l10n }]
        : acc,
    [],
    keys(cartViews.view) as ViewName[],
  );

type CartViewProps = {
  changeCartView: CartViewReturn["changeCartView"];
  cartView: CartViewsState;
};

export const CartView = ({ changeCartView, cartView }: CartViewProps) => {
  const { cart, cartActions } = useCart();

  const hasRights = hasRightToSeeQuoteCalculationView(
    cart.authorities.valueAuthorities,
  );

  const hasRightToSwitchCalculationBasis = useMemo(() => {
    return (
      hasRights[cartView.view] &&
      cartViews.view[cartView.view].canSwitchCalculationBasis
    );
  }, [cartView, hasRights]);

  if (not(retrieveRight(hasRights, cartView.view))) {
    window.localStorage.setItem("view", cartViews.view.buyerView.name);
  }

  return (
    <div className="cart-view column">
      <PlainDropdown
        viewName={cartView.view}
        values={transformRights(hasRights)}
        onChange={(value) => changeCartView("view", value as ViewName)}
        filterId="cart-calculation-filter"
      />
      {hasRightToSwitchCalculationBasis && (
        <div className="control">
          <RadioButton
            className="radio"
            label="cart_based_on_margin"
            name={LOCAL_STORAGE_CART_VIEW_BASED_ON_KEY}
            value={cartViews.basedOn.surchargeBased}
            checked={cartView.basedOn === cartViews.basedOn.surchargeBased}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              changeCartView("basedOn", event.target.value as BasedOn);
              cartActions.setCalculationBasis(cartViews.basedOn.surchargeBased);
            }}
          />
          <RadioButton
            className="radio"
            label="cart_based_on_discount"
            name={LOCAL_STORAGE_CART_VIEW_BASED_ON_KEY}
            value={cartViews.basedOn.discountBased}
            checked={cartView.basedOn === cartViews.basedOn.discountBased}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              changeCartView("basedOn", event.target.value as BasedOn);
              cartActions.setCalculationBasis(cartViews.basedOn.discountBased);
            }}
          />
        </div>
      )}
    </div>
  );
};
