import { ReactElement, ReactNode } from "react";
import { HashRouter, Navigate, Route, Routes } from "react-router-dom";

import { CartInformation } from "./components/CartInformation";
import { CurrentUserDisplay } from "./components/CurrentUserDisplay";
import { ErrorPage } from "./components/ErrorPage";
import { Mocks } from "./components/navigation/Mocks";
import { Tabs } from "./components/tabs";
import { TopBar } from "./components/topBar";
import { CartProvider } from "./context/CartProvider";
import { ConfigurationProvider } from "./context/ConfigurationProvider";
import { ROUTES } from "./http/constants";
import { ConfigurationFromId } from "./pages/configurator/configurationFromId";
import { ConfigurationFromProduct } from "./pages/configurator/configurationFromProduct";
import { ReconfigurationFromId } from "./pages/configurator/reconfigurationFromId";
import { ReconfigurationFromProduct } from "./pages/configurator/reconfigurationFromProduct";
import { CustomerManagement } from "./pages/customerManagement/customerManagement";
import DevLogin from "./pages/devLogin";
import { OfferManagement } from "./pages/offerManagement/offerManagement";
import { OfferManagementOpenOffer } from "./pages/offerManagement/offerManagementOpenOffer";
import { Products } from "./pages/products/products";
import { SharedShoppingCart } from "./pages/shoppingCart/sharedShoppingCart";
import { ShoppingCart } from "./pages/shoppingCart/shoppingCart";

import "./app.scss";
import "./assets/css/style.scss";

function RouteWithTabs({ element }: Readonly<{ element: ReactNode }>) {
  return (
    <>
      <CartInformation />
      <Tabs />
      {element}
    </>
  );
}

function RouteWithCartInformation({
  element,
}: Readonly<{ element: ReactElement }>) {
  return (
    <>
      <CartInformation />
      {element}
    </>
  );
}

function App() {
  return (
    <HashRouter>
      <CartProvider>
        <ConfigurationProvider>
          <div id="teo">
            <CurrentUserDisplay />
            <Mocks />
            <TopBar />
            <section className="section">
              <div className="container">
                <Routes>
                  <Route
                    path={ROUTES.PRODUCTS}
                    element={<RouteWithTabs element={<Products />} />}
                  />
                  <Route
                    path={ROUTES.CART}
                    element={<RouteWithTabs element={<ShoppingCart />} />}
                  />
                  <Route
                    path={ROUTES.OPEN_SHARED_CART}
                    element={<RouteWithTabs element={<SharedShoppingCart />} />}
                  />
                  <Route
                    path={ROUTES.CONFIGURE_FROM_PRODUCT}
                    element={
                      <RouteWithTabs element={<ConfigurationFromProduct />} />
                    }
                  />
                  <Route
                    path={ROUTES.CONFIGURE_FROM_ID}
                    element={
                      <RouteWithTabs element={<ConfigurationFromId />} />
                    }
                  />
                  <Route
                    path={ROUTES.RECONFIGURE_FROM_PRODUCT}
                    element={
                      <RouteWithTabs element={<ReconfigurationFromProduct />} />
                    }
                  />
                  <Route
                    path={ROUTES.RECONFIGURE_FROM_ID}
                    element={
                      <RouteWithTabs element={<ReconfigurationFromId />} />
                    }
                  />
                  <Route
                    path={ROUTES.OFFER_MANAGEMENT}
                    element={
                      <RouteWithCartInformation element={<OfferManagement />} />
                    }
                  />
                  <Route
                    path={ROUTES.OFFER_MANAGEMENT_OPEN_OFFER}
                    element={
                      <RouteWithCartInformation
                        element={<OfferManagementOpenOffer />}
                      />
                    }
                  />
                  <Route
                    path={ROUTES.CUSTOMER_MANAGEMENT}
                    element={
                      <RouteWithCartInformation
                        element={<CustomerManagement />}
                      />
                    }
                  />
                  <Route path={ROUTES.ERROR} element={<ErrorPage />} />
                  <Route path={ROUTES.DEV_LOGIN} element={<DevLogin />} />
                  <Route
                    path={ROUTES.HOME}
                    element={<Navigate replace to={ROUTES.PRODUCTS} />}
                  />
                </Routes>
              </div>
            </section>
          </div>
        </ConfigurationProvider>
      </CartProvider>
    </HashRouter>
  );
}

export default App;
