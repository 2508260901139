import { gt, length, map, pathOr } from "ramda";

import { L10n } from "@encoway/l10n";

import { TeckentrupCartArticleTO, TeckentrupCartTO } from "../types/cart";
import {
  CART_STATUS_OPEN,
  CART_STATUS_ORDERED,
  CART_STATUS_ORDERED_PART,
  ORDER_STATUS_FLAGGED,
  ORDER_STATUS_ORDERED,
  ORDER_STATUS_UNORDERD,
} from "./cartUtils";
import { getFormattedDate } from "./dateUtils";

/**
 * Retrieves the order status of an article based on the cart status and article order status.
 *
 * @param {string} cartStatus - The status of the cart.
 * @param {string} articleOrderStatus - The order status of the article.
 * @returns {string | undefined} - The order status of the article.
 */
export function getArticleOrderStatus(
  cartStatus: string | undefined,
  articleOrderStatus: string,
): string | undefined {
  if (
    cartStatus === CART_STATUS_OPEN ||
    cartStatus === CART_STATUS_ORDERED_PART
  ) {
    return articleOrderStatus === ORDER_STATUS_UNORDERD ||
      articleOrderStatus === ORDER_STATUS_FLAGGED
      ? L10n.format("cart_table_order_status_unordered")
      : L10n.format("cart_table_order_status_ordered");
  }
  if (cartStatus === CART_STATUS_ORDERED) {
    return L10n.format("cart_table_order_status_ordered");
  }
  return undefined;
}

/**
 * Retrieves the order date of an article based on the cart status, article order status, cart ordered date, and part
 * order date.
 *
 * @param {string} cartStatus - The status of the cart.
 * @param {string} articleOrderStatus - The order status of the article.
 * @param {string} partOrderDate - The part order date of the article.
 * @returns {string | undefined} - The order date of the article.
 */
export function getArticleOrderDate(
  cartStatus: string | undefined,
  articleOrderStatus: string,
  partOrderDate: string,
): string | undefined {
  if (
    cartStatus === CART_STATUS_OPEN ||
    cartStatus === CART_STATUS_ORDERED_PART
  ) {
    return articleOrderStatus === ORDER_STATUS_ORDERED
      ? getFormattedDate(partOrderDate, {})
      : "";
  }
  if (cartStatus === ORDER_STATUS_ORDERED) {
    return getFormattedDate(partOrderDate, {});
  }
  return undefined;
}

/**
 * Retrieves the order number based on the cart status, cart order number, and part order number.
 *
 * @param {string} cartStatus - The status of the cart.
 * @param {string} cartOrderNumber - The order number of the cart.
 * @param {string} partOrderNumber - The part order number.
 * @returns {string | undefined} - The order number.
 */
export function getOrderNumber(
  cartStatus: string | undefined,
  cartOrderNumber: string,
  partOrderNumber: string,
): string | undefined {
  if (cartStatus === CART_STATUS_ORDERED_PART) {
    return partOrderNumber;
  }
  if (cartStatus === ORDER_STATUS_ORDERED) {
    return cartOrderNumber;
  }
  return undefined;
}

export function getPartOrderDetails(
  cartTo: TeckentrupCartTO,
): TeckentrupCartArticleTO[] {
  if (
    cartTo.articles.subArticles &&
    gt(length(cartTo.articles.subArticles), 0)
  ) {
    return map((subArticle) => {
      const cartStatus = cartTo.headerData?.status;
      const cartOrderNumber = cartTo.orderNumber;

      const subArticles = map((folderSubArticle) => {
        const updatedSubArticle = {
          ...folderSubArticle,
          orderStatus: getArticleOrderStatus(
            cartStatus,
            folderSubArticle.articleOrderStatus,
          ),
          orderDate: getArticleOrderDate(
            cartStatus,
            folderSubArticle.articleOrderStatus,
            pathOr(
              pathOr("", ["headerData", "orderedDate"], subArticle),
              ["partOrderDate"],
              folderSubArticle,
            ),
          ),
          orderNumber: getOrderNumber(
            cartStatus,
            cartOrderNumber,
            folderSubArticle.partOrderNumber,
          ),
        };

        return updatedSubArticle;
      }, subArticle.subArticles);

      return {
        ...subArticle,
        subArticles: gt(length(subArticle.subArticles), 0)
          ? subArticles
          : subArticle.subArticles,
        orderStatus: getArticleOrderStatus(
          cartStatus,
          subArticle.articleOrderStatus,
        ),
        orderDate: getArticleOrderDate(
          cartStatus,
          subArticle.articleOrderStatus,
          pathOr(
            pathOr("", ["headerData", "orderedDate"], cartTo),
            ["partOrderDate"],
            subArticle,
          ),
        ),
        orderNumber: getOrderNumber(
          cartStatus,
          cartOrderNumber,
          subArticle.partOrderNumber,
        ),
      };
    }, cartTo.articles.subArticles);
  }

  return cartTo.articles.subArticles;
}
