import classnames from "classnames";
import { equals, isNil, path } from "ramda";
import { useEffect, useMemo, useState } from "react";

import { Constants, containsParameter } from "@encoway/react-components";

import { ContainerTO } from "../../../../types/configuration";
import { ConfiguratorTabsProps } from "../configuratorTabs";
import { getCurrentTab, selectTab } from "../helper/tabsHelper";
import {
  containerIsEmpty,
  determineActiveTab,
  determineNextTab,
  determinePreviousTab,
} from "../utils/tabsUtils";

const defaultStyle = {
  boxed: false,
  toggle: false,
  readyIcon: null,
  iconPosition: "left",
  hideSingleMenu: false,
  showNavigation: false,
  navSkipOptionalTabs: false,
};

const onEvent = (
  currentTab: string | null,
  props: ConfiguratorTabsProps,
  setCurrentTab: React.Dispatch<React.SetStateAction<string | null>>,
  event: any,
) => {
  const filtered = props.data.children.filter((tab) =>
    containsParameter(tab, event.parameter),
  );
  filtered.length >= 1 &&
    selectTab(
      props.options.eventBus,
      currentTab,
      setCurrentTab,
      filtered[0].name,
    );
};

type TabsStyle = {
  boxed: boolean;
  hideSingleMenu: boolean;
  iconPosition: string;
  navSkipOptionalTabs: boolean;
  readyIcon: unknown;
  showNavigation: boolean;
  toggle: boolean;
};

type TabsReturnType = {
  style: TabsStyle;
  tabStyle: string;
  current: ContainerTO;
  currentTab: string | null;
  previous: string | null;
  next: string | null;
  tabs: ContainerTO[];

  containerFilter: ((container: ContainerTO) => boolean) | undefined;
  setCurrentTab: React.Dispatch<React.SetStateAction<string | null>>;
  previousClickHandler: () => void | null;
  nextClickHandler: () => void | null;
};

export function useConfiguratorTabs(
  props: ConfiguratorTabsProps,
): TabsReturnType {
  const containerFilter = path<TabsReturnType["containerFilter"]>(
    ["options", "filter", "container"],
    props,
  );

  const [currentTab, setCurrentTab] = useState<string | null>(
    determineActiveTab(props.inject, props.data.children, containerFilter),
  );

  const style = { ...defaultStyle, ...props.style };

  const tabStyle = classnames({
    tabs: true,
    "is-boxed": style.boxed,
    "is-toggle": style.toggle,
  });

  const current = useMemo(
    () =>
      getCurrentTab(
        props.options.eventBus,
        currentTab,
        props.inject,
        props.data.children,
        containerFilter,
      ),
    [props.data, props.inject, currentTab, containerFilter],
  );

  const previous = useMemo(
    () =>
      determinePreviousTab(
        props.inject,
        props.data.children,
        containerFilter,
        currentTab,
        style.navSkipOptionalTabs,
      ),
    [containerFilter, currentTab, style],
  );

  const next = useMemo(
    () =>
      determineNextTab(
        props.inject,
        props.data.children,
        containerFilter,
        currentTab,
        style.navSkipOptionalTabs,
      ),
    [containerFilter, currentTab, style],
  );

  const previousClickHandler = () =>
    isNil(previous)
      ? null
      : selectTab(props.options.eventBus, currentTab, setCurrentTab, previous);

  const nextClickHandler = () =>
    isNil(next)
      ? null
      : selectTab(props.options.eventBus, currentTab, setCurrentTab, next);

  const tabs = useMemo(
    () =>
      props.data.children.filter(
        (child) =>
          !(
            (containerFilter && containerFilter(child) === false) ||
            containerIsEmpty(child)
          ),
      ),
    [props.data.children],
  );

  props.options.eventBus
    .filter((event: any) =>
      equals(event.event, Constants.Commands.GotoParameter),
    )
    .onValue((event: any) => onEvent(currentTab, props, setCurrentTab, event));

  useEffect(() => {
    const activeTab = determineActiveTab(
      props.inject,
      props.data.children,
      containerFilter,
    );
    selectTab(props.options.eventBus, currentTab, setCurrentTab, activeTab);
  }, [props.data.id]);

  return {
    style,
    tabStyle,
    containerFilter,
    current,
    currentTab,
    setCurrentTab,
    previous,
    next,
    previousClickHandler,
    nextClickHandler,
    tabs,
  };
}
