import { pathOr, pipe, reduce, toPairs } from "ramda";
import { useEffect, useMemo, useState } from "react";

import { CharacteristicPossibleValue } from "@encoway/rest-api";

import { Dropdown } from "../../../../../components/dropdown";
import { useApp } from "../../../../../hooks/useApp";
import { useLocaleNumberFormat } from "../../../../../hooks/useLocaleNumberFormat";
import {
  CartActions,
  ExtendedTeckentrupCartTO,
  LocalizedShippingMethodTO,
} from "../../../../../types/cart";
import { ViewsState } from "../../../../../utilities/cartUtils";
import { t } from "../../../localizationUtils";
import { buildShippingMethodDropDown } from "./calculationUtils";

import "./shippingMethod.scss";

const CHARACTERISTIC_KEY_SHIPPING_METHOD = "sp_versandart";

type ShippingMethodProps = {
  cart: ExtendedTeckentrupCartTO;
  setShippingMethod: CartActions["setShippingMethod"];
  cartViews: ViewsState;
};

const emptyShippingMethod: LocalizedShippingMethodTO = {
  id: "",
  rawPrice: 0,
  translationKey: "",
  price: {
    currency: "",
    formattedPrice: "",
    price: 0,
  },
  translatedName: "",
};

export const ShippingMethod = ({
  cart,
  setShippingMethod,
  cartViews,
}: ShippingMethodProps) => {
  const { formatPrice } = useLocaleNumberFormat();
  const { catalogService } = useApp();
  const [shippingMethodTranslation, setShippingMethodTranslation] = useState<
    Record<string, string>
  >({});

  const getShippingMethodTranslation: Promise<Record<string, string>> =
    useMemo(async () => {
      const shippingMethodCharacteristic = await catalogService.characteristic(
        CHARACTERISTIC_KEY_SHIPPING_METHOD,
      );
      return pipe(
        toPairs,
        reduce(
          (
            acc: Record<string, string>,
            [_, value]: [string, CharacteristicPossibleValue],
          ) => ({ ...acc, [value.id]: value.value }),
          {},
        ),
      )(shippingMethodCharacteristic.possibleValues);
    }, [catalogService]);

  useEffect(() => {
    getShippingMethodTranslation.then((result) => {
      setShippingMethodTranslation(result);
    });
  }, [getShippingMethodTranslation]);

  const allowedShippingMethods = pathOr<LocalizedShippingMethodTO[]>(
    [],
    ["allowedShippingMethods"],
    cart,
  );
  const allowedShippingMethodsPartOrder = pathOr<LocalizedShippingMethodTO[]>(
    [],
    ["allowedShippingMethodsPartOrder"],
    cart,
  );
  const currentShippingMethod = pathOr<LocalizedShippingMethodTO>(
    emptyShippingMethod,
    ["currentShippingMethod"],
    cart,
  );
  const currentShippingMethodPartOrder = pathOr<LocalizedShippingMethodTO>(
    emptyShippingMethod,
    ["currentShippingMethodPartOrder"],
    cart,
  );

  const activeShippingMethod = cartViews.isOrder
    ? currentShippingMethodPartOrder
    : currentShippingMethod;
  const activeAllowedShippingMethods = cartViews.isOrder
    ? allowedShippingMethodsPartOrder
    : allowedShippingMethods;

  return (
    <div className="columns calculation-box">
      <label>{t("shipping_method")}</label>
      <Dropdown
        possibleValues={buildShippingMethodDropDown(
          activeAllowedShippingMethods,
          activeShippingMethod,
          shippingMethodTranslation,
        )}
        notPossibleValues={[]}
        onClickMethod={setShippingMethod}
        characteristicId="shipping"
        cartViews={cartViews}
      />
      <label className="column is-three-quarters shipping-method-labels">
        {t("shipping_cost")}
      </label>
      <div
        data-cell-id={"cart-sum-shipping_method-absolute"}
        className="column has-text-right is-one-quarter"
      >
        {formatPrice(activeShippingMethod.price)}
      </div>
    </div>
  );
};
