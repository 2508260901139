import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import { equals, map, pathOr } from "ramda";
import { ReactNode, useState } from "react";

import { L10n } from "@encoway/l10n";

import { cartViews, ViewName } from "../pages/shoppingCart/hooks/useCartView";

import "./plainDropdown.scss";

type DropdownValue = {
  label: string;
  value: string;
};

type PlainDropdownProps = {
  viewName: ViewName;
  values: Array<DropdownValue>;
  filterId: string;
  onChange: (value: string) => void;
};

export function PlainDropdown(props: Readonly<PlainDropdownProps>) {
  const { viewName, values, filterId, onChange } = props;
  const [active, setActive] = useState<boolean>(false);

  const handleChange = (value: string): void => {
    onChange(value);
    setTimeout(() => setActive(false), 200);
  };

  const createDropdownItems = (
    viewName: ViewName,
    values: Array<DropdownValue>,
  ): ReactNode =>
    map(
      (current: DropdownValue) => (
        <button
          key={current.label}
          data-filter-entry-id={pathOr(
            "",
            ["view", current.value, "l10n"],
            cartViews,
          )}
          className={classnames("dropdown-item", {
            "is-selected": equals(viewName, current.value),
          })}
          onMouseDown={() => handleChange(current.value)}
        >
          <p>{L10n.format(current.label)}</p>
        </button>
      ),
      values,
    );

  return (
    <div className={classnames("dropdown shadow", { "is-active": active })}>
      <div className="dropdown-tigger">
        <button
          data-filter-id={filterId}
          className="button rounded"
          onClick={() => setActive((prev) => !prev)}
          onBlur={() => setActive(false)}
        >
          <span>{L10n.format(cartViews.view[viewName].l10n)}</span>
          <span className="icon is-small">
            <FontAwesomeIcon
              icon={faAngleDown}
              size="xs"
              style={{ color: "#ef7b22" }}
            />
          </span>
        </button>
        <div className="dropdown-menu" id="dropdown-menu" role="menu">
          <div className="dropdown-content">
            {createDropdownItems(viewName, values)}
          </div>
        </div>
      </div>
    </div>
  );
}
