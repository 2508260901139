import classNames from "classnames";
import { equals, gt, isEmpty, map, not } from "ramda";
import { useEffect, useState } from "react";

import { L10n } from "@encoway/l10n";
import { Viewport } from "@encoway/react-components";

import { Modal, ModalHeader } from "../../../components/modal/modal";
import { useLvTable } from "../../../hooks/useLvTable";
import { ParameterTO } from "../../../types/@encoway/Parameter";
import { ConfiguratorComponentProps } from "../../../types/configuration";
import { LV_TAB_ID, LvTabId } from "../../../utilities/lvUtils";
import { LvConfiguratorDropdownField } from "../lvTable/components/LvConfiguratorDropdownField";
import { LvImportTab } from "./LvImportTab";
import { LvOfferTab } from "./LvOfferTab";
import { LvTableTab } from "./LvTableTab";

type UploadLvModalProps = {
  onCancel: () => void;
};

export const UploadLvModal = ({ onCancel }: UploadLvModalProps) => {
  const lvTable = useLvTable();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedTab, setSelectedTab] = useState<LvTabId>(LV_TAB_ID.LV_IMPORT);

  const lvTabs = [
    {
      id: LV_TAB_ID.LV_IMPORT,
      name: "offer_management_create_lv_import_select_lv",
    },
    {
      id: LV_TAB_ID.LV_POSITIONS,
      name: "offer_management_create_lv_import_lv_positions",
      disabled: equals(lvTable.lvRowDTOsLength, 0),
    },
    {
      id: LV_TAB_ID.LV_HEADER_DATA,
      name: "offer_management_create_lv_import_lv_header_data",
      disabled:
        not(lvTable.isAnyLvRowChecked) || isEmpty(lvTable.lvOldFileName),
    },
  ];

  useEffect(() => {
    Viewport.register("section", (props: ConfiguratorComponentProps<any>) =>
      props.data.parameters.map((parameter: ParameterTO) =>
        Viewport.instance(parameter.viewPort!, {
          ...props,
          data: parameter,
          key: parameter.id,
        }),
      ),
    );
    Viewport.register("lvDropdown", LvConfiguratorDropdownField);
    Viewport.register("lvHidden", () => null);
  }, []);

  return (
    <Modal dialogId={"offer_management_create_lv_import"} className="fullwidth">
      <ModalHeader
        label={L10n.format("offer_management_create_lv_import")}
        onCancel={onCancel}
      />
      <div>
        <div className="tabs is-fullwidth mb-0">
          <ul>
            {map(
              ({ id, name, disabled }) => (
                <li
                  key={id}
                  className={classNames({
                    "is-active": equals(selectedTab, id),
                    "is-disabled": disabled,
                  })}
                >
                  <a
                    {...(not(disabled) && {
                      onClick: () => setSelectedTab(id),
                    })}
                  >
                    {L10n.format(name)}
                  </a>
                </li>
              ),
              lvTabs,
            )}
          </ul>
        </div>
      </div>
      {/* could not render the elements conditionally, as this meant that the components always had to be reloaded
       when switching tabs. Nevertheless, dynamic rendering is required to reset the scroll state when changing PDFs or settings  */}
      <LvImportTab
        isHidden={not(equals(selectedTab, LV_TAB_ID.LV_IMPORT))}
        lvTable={lvTable}
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        onCancel={onCancel}
        setSelectedTab={setSelectedTab}
      />
      {gt(lvTable.lvRowDTOsLength, 0) && (
        <LvTableTab
          isHidden={not(equals(selectedTab, LV_TAB_ID.LV_POSITIONS))}
          lvTable={lvTable}
          selectedFile={selectedFile}
          onCancel={onCancel}
          setSelectedTab={setSelectedTab}
        />
      )}
      {gt(lvTable.lvRowDTOsLength, 0) && (
        <LvOfferTab
          isHidden={not(equals(selectedTab, LV_TAB_ID.LV_HEADER_DATA))}
          lvTable={lvTable}
          onCancel={onCancel}
        />
      )}
    </Modal>
  );
};
