import { isNil } from "ramda";

import { Loading } from "../../components/loading";
import { useConfiguration } from "../../hooks/useConfiguration";
import { ConfiguratorContainer } from "./configuratorContainer";
import { ResultContainer } from "./resultContainer";

export function Configuration() {
  const { guiTO, config, variantConditionPrices } = useConfiguration();

  if (isNil(guiTO) || isNil(config) || isNil(variantConditionPrices)) {
    return <Loading />;
  }

  return (
    <div className="columns" data-page-id="configuration">
      <ConfiguratorContainer />
      <ResultContainer />
    </div>
  );
}
