import { equals, isNil } from "ramda";
import {
  createContext,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from "react";

import { ServiceFactoryBuilder } from "@encoway/cart-service-js-client";
import { L10n } from "@encoway/l10n";
import { CatalogService } from "@encoway/rest-api";

import { Config } from "../config/config";
import IdentityStore from "../http/identityStore";
import { createHttp } from "../http/setupHttp";
import configurationTranslations from "../localization/configurationTranslations.json";
import DevLogin from "../pages/devLogin";
import { getOrCreateCompany } from "../service/customerManagementService";
import { fetchPost } from "../service/fetch";
import { loadTranslations } from "../utilities/languageUtils";

export enum MODE {
  DEVELOP = "develop",
  PRODUCTION = "productive",
  ENVIRONMENT = "environment",
}

const http = createHttp(null);
export const SERVICE_BASE_URL = `${Config.Service.BaseUrl}${Config.Service.BackendPath}`;
export const SERVICE_FACTORY = new ServiceFactoryBuilder()
  .withHttp(http)
  .withBaseUrl(`${SERVICE_BASE_URL}/api/cart`)
  .withLocaleProvider(L10n.currentFullLocale)
  .withDefaultMappingProfile("NOTHING");

type AppContextType = {
  language: string;
  systemName: string;
  mode: MODE;
  catalogService: CatalogService;
  identityStore: IdentityStore;
  conex: boolean | null;
  companyId: number | null;
  token: string | null;
};

export const AppContext = createContext<AppContextType | null>(null);

export function AppProvider({ children }: Readonly<PropsWithChildren>) {
  const [loading, setLoading] = useState(true);
  const [language, setLanguage] = useState("de-DE");
  const [catalogService, setCatalogService] = useState<CatalogService | null>(
    null,
  );
  const [mode, setMode] = useState(MODE.PRODUCTION);
  const [systemName, setSystemName] = useState("");
  const [conex, setConex] = useState<boolean | null>(null);
  const [companyId, setCompanyId] = useState<number | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [identityStore, setIdentityStore] = useState<IdentityStore | null>(
    null,
  );
  const tokenRef = useRef<string | null>(null);
  const modeRef = useRef(MODE.PRODUCTION);

  useEffect(() => {
    async function initializeApp(newMode: MODE, newSystemName: string) {
      const languageCode: string = language_code;
      const newLanguage = languageCode || language;
      try {
        L10n.currentLocale(newLanguage);
        const newCatalogService = new CatalogService(
          http,
          SERVICE_BASE_URL,
          L10n.currentFullLocale(),
        );
        const translations = await loadTranslations(newCatalogService);
        L10n.source("configuration", configurationTranslations, true);
        L10n.source("ui", translations, true);
        await L10n.reloadResources(newLanguage);
        setCatalogService(newCatalogService);
        setLanguage(newLanguage);
        setMode(newMode);
        setSystemName(newSystemName);
      } catch {
        setMode(newMode);
        setSystemName(newSystemName);
      }
    }

    async function initializeIdentity() {
      try {
        tokenRef.current = await teotoken; // teotoken is globally defined
        window.localStorage.setItem(MODE.ENVIRONMENT, MODE.PRODUCTION);
        modeRef.current = MODE.PRODUCTION;
      } catch (e) {
        tokenRef.current = window.localStorage.getItem("authToken");
        window.localStorage.setItem(MODE.ENVIRONMENT, MODE.DEVELOP);
        modeRef.current = MODE.DEVELOP;
      }
      if (isNil(tokenRef.current)) {
        await initializeApp(modeRef.current, "No System found");
        setLoading(false);
        return;
      }
      try {
        const login = await fetchPost("teckentrup-server/api/login")(
          {},
          { Authorization: `Bearer ${tokenRef.current}` },
        )();
        const {
          data: { returnValue },
        } = await getOrCreateCompany();
        const newIdentityStore = new IdentityStore(tokenRef.current);
        await newIdentityStore.storeAuthorities();
        await initializeApp(modeRef.current, login.headers.systemname);
        setLoading(false);
        setConex(equals(login.headers.conex, "true"));
        setCompanyId(returnValue.id);
        setToken(tokenRef.current);
        setIdentityStore(newIdentityStore);
      } catch (e) {
        await initializeApp(modeRef.current, "No System found");
        setLoading(false);
        setToken(tokenRef.current);
      }
    }

    initializeIdentity();
  }, []);

  if (loading) {
    return null;
  }

  if (
    equals(mode, MODE.DEVELOP) &&
    isNil(window.localStorage.getItem("authToken"))
  ) {
    return <DevLogin />;
  }

  if (isNil(identityStore) || isNil(catalogService)) {
    return null;
  }

  const value = {
    systemName,
    mode,
    language,
    catalogService,
    identityStore,
    conex,
    companyId,
    token,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}
