import {
  Modal,
  ModalBodyWrapper,
  ModalHeader,
} from "../../../../components/modal/modal";
import { Config } from "../../../../config/config";
import { SERVICE_BASE_URL } from "../../../../context/AppProvider";
import { CONSTANTS } from "../../../../http/constants";

import "./sparePartInformationModal.scss";

type SparePartInformationModalProps = Readonly<{
  label: string;
  image: string;
  shortText: string;
  longText: string;
  onCancel: () => void;
}>;

export function SparePartInformationModal(
  props: SparePartInformationModalProps,
) {
  const { label, image, shortText, longText, onCancel } = props;

  return (
    <Modal onClick={onCancel}>
      <ModalHeader label={label} onCancel={onCancel} />
      <ModalBodyWrapper>
        <div className="spare-part-image has-text-centered">
          <img
            src={`${SERVICE_BASE_URL}/${CONSTANTS.Images.Catalog}${image}${Config.ImageResolution.Medium}`}
            alt={shortText}
          />
        </div>
        <div
          className="spare-part-long-text "
          contentEditable="true"
          dangerouslySetInnerHTML={{ __html: longText }}
        />
      </ModalBodyWrapper>
    </Modal>
  );
}
