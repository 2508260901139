import {
  faCheck,
  faExclamationTriangle,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { PropsWithChildren, ReactNode, useEffect, useMemo } from "react";

import { useConfiguration } from "../../../../../../hooks/useConfiguration";
import { TeckentrupCartArticleTO } from "../../../../../../types/cart";
import {
  Reconfiguration,
  ReconfigurationParameter,
} from "../../../../../../types/configuration";
import { mapIndexed } from "../../../../../../utilities/utilities";
import { CartWarningWrapper } from "../../../../../offerManagement/cartWarningWrapper";
import { t } from "../../../../localizationUtils";
import {
  isReconfigFailed,
  isReconfigPossible,
  isReconfigSuccessful,
} from "../../../modals/deliveryTimeChangeCheckModalUtils";
import { getPossibleDeliveryTimeWish } from "../../../modals/deliveryTimeChangeModalUtils";

import "./deliveryTimeChangeCheckModal.scss";
import "./deliveryTimeCheckModal.scss";

type StandardBodyProps = {
  button: ReactNode;
};

const StandardBody = ({
  button,
  children,
}: PropsWithChildren<StandardBodyProps>) => (
  <div className="delivery-time-change-check-modal-body">
    <p className="custom-margin-2">
      {t("cart_text_delivery_time_change_check")}
    </p>
    <div className="button-label-wrapper custom-margin-2">
      {button}
      {t("cart_text_progress_delivery_time_change_check")}
    </div>
    {children}
  </div>
);

type FailedTableProps = {
  unsuccessfulParameters?: ReconfigurationParameter[];
};

const FailedTable = ({ unsuccessfulParameters = [] }: FailedTableProps) => (
  <div className="failed-table-wrapper">
    <p className="failed-text">
      {t("cart_text_delivery_time_change_check_negative_one")}
    </p>
    <table className="table is-fullwidth">
      <thead>
        <tr>
          <th />
          <th>{t("cart_text_delivery_time_change_name")}</th>
          <th>{t("cart_text_delivery_time_change_old_value")}</th>
          <th>{t("cart_text_delivery_time_change_new_value")}</th>
        </tr>
      </thead>
      <tbody>
        {mapIndexed(
          (unsuccessfulParameter, key) => (
            <tr key={key}>
              <td>{key + 1}</td>
              <td>{unsuccessfulParameter.translatedName}</td>
              <td>{unsuccessfulParameter.selectedValues[0].translatedValue}</td>
              <td>{unsuccessfulParameter.properties.newValue}</td>
            </tr>
          ),
          unsuccessfulParameters,
        )}
      </tbody>
    </table>
  </div>
);

type DeliveryTimeChangeCheckModalBodyProps = {
  reconfigFailed: boolean;
  reconfigPossible: boolean;
  reconfigSuccessful: boolean;
  reconfiguration: Reconfiguration | null;
};

const DeliveryTimeChangeCheckModalBody = ({
  reconfigFailed,
  reconfigPossible,
  reconfigSuccessful,
  reconfiguration,
}: DeliveryTimeChangeCheckModalBodyProps) => {
  const buttonClassName = classNames("button", {
    "is-danger": reconfigFailed,
    "is-warning": reconfigPossible,
    "is-success": reconfigSuccessful,
  });

  return (
    <StandardBody
      button={
        <button className={buttonClassName}>
          <span className="icon is-small">
            {reconfigFailed && <FontAwesomeIcon icon={faXmark} />}
            {reconfigPossible && (
              <FontAwesomeIcon icon={faExclamationTriangle} />
            )}
            {reconfigSuccessful && <FontAwesomeIcon icon={faCheck} />}
          </span>
        </button>
      }
    >
      {reconfigFailed && (
        <p>{t("cart_text_delivery_time_change_check_negative_two")}</p>
      )}
      {reconfigPossible && (
        <FailedTable
          unsuccessfulParameters={reconfiguration?.unsuccessfulParameters}
        />
      )}
      {reconfigSuccessful && (
        <p>{t("cart_text_delivery_time_change_check_positive")}</p>
      )}
    </StandardBody>
  );
};

type DeliveryTimeChangeCheckModalProps = {
  cartItem: TeckentrupCartArticleTO;
  onCancel: () => void;
  onSave: () => void;
  isLoading: boolean;
  deliveryClassRange: string[];
};

export const DeliveryTimeChangeCheckModal = ({
  cartItem,
  onCancel,
  onSave,
  isLoading,
  deliveryClassRange,
}: DeliveryTimeChangeCheckModalProps) => {
  const { restart, reconfiguration, config } = useConfiguration();
  const isReConfigFailed = useMemo(
    () => isReconfigFailed(reconfiguration),
    [reconfiguration],
  );

  useEffect(() => {
    (async () => {
      await restart({
        articleId: cartItem.articleId,
        articleName: cartItem.articleNumber,
        cartPosition: cartItem.positionNr,
        deliveryTimeWish: getPossibleDeliveryTimeWish(
          cartItem.deliveryClass,
          deliveryClassRange,
        ),
      });
    })();
  }, []);

  if (!config?.cfg && !isLoading) {
    return (
      <CartWarningWrapper
        headerLabel={t("cart_title_delivery_time_change_check")}
        bodyLabel={
          <StandardBody button={<button className="button is-loading" />} />
        }
      />
    );
  }

  return (
    <CartWarningWrapper
      headerLabel={t("cart_title_delivery_time_change_check")}
      isLoading={isLoading}
      bodyLabel={
        <DeliveryTimeChangeCheckModalBody
          reconfigFailed={isReConfigFailed}
          reconfigPossible={isReconfigPossible(reconfiguration)}
          reconfigSuccessful={isReconfigSuccessful(reconfiguration)}
          reconfiguration={reconfiguration}
        />
      }
      onCancel={onCancel}
      footerCancelLabel={t("cart_cancel_delivery_time_change_check")}
      {...(!isReConfigFailed && { onSave })}
      {...(!isReConfigFailed && {
        footerSaveLabel: t("cart_accept_delivery_time_change_check"),
      })}
    />
  );
};
