import classnames from "classnames";
import { pathOr } from "ramda";

import { Config } from "../../../config/config";
import { ProductGroup } from "./categorySelection";

type CategorySelectionItemProps = {
  isActive: boolean;
  category: ProductGroup;
  opened: boolean;
  onClickMethod: (categoryId: string) => void;
};

export function CategorySelectionItem({
  isActive,
  category,
  opened,
  onClickMethod,
}: Readonly<CategorySelectionItemProps>) {
  return (
    <button
      className="card categorySelectionItem"
      data-category-id={category.id}
      onClick={() => onClickMethod(category.id)}
    >
      <header className="card-header w-100">
        <p
          className={classnames({
            "card-header-title": true,
            "is-active": isActive,
          })}
        >
          {category.name}
        </p>
      </header>
      {opened && (
        <div>
          <img
            src={`${pathOr("", ["values", 0, "uri"], category.characteristicValues.Product_image)}${Config.ImageResolution.Medium}`}
            alt={category.name}
            data-image-id={category.id}
          />
          <div className="card-content">
            <div
              className="content"
              dangerouslySetInnerHTML={{
                __html: pathOr(
                  "",
                  ["values", 0],
                  category.characteristicValues.long_text_gui,
                ),
              }}
            />
          </div>
        </div>
      )}
    </button>
  );
}
