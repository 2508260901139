import { isEmpty } from "ramda";
import { useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";

import { Loading } from "../../components/loading";
import { useCart } from "../../hooks/useCart";
import { useConfiguration } from "../../hooks/useConfiguration";
import { ReconfiguringArticleLoadingModal } from "./reconfiguringArticleLoadingModal";

export function ReconfigurationFromProduct() {
  const { articleId, articleName, cartPosition, articleOrderStatus } =
    useParams();
  const { restart, loading, configurationId } = useConfiguration();
  const { cartActions } = useCart();

  useEffect(() => {
    (async () => {
      await restart({
        articleId: articleId!,
        articleName: articleName!,
        cartPosition: cartPosition!,
        articleOrderStatus,
      });
      cartActions.overwriteState({ reconfigurationPosition: cartPosition });
    })();
  }, []);

  if (isEmpty(configurationId) || loading) {
    return (
      <>
        <ReconfiguringArticleLoadingModal />
        <Loading />
      </>
    );
  }

  return (
    <Navigate
      to={`/reconfiguration/id/${configurationId}/${articleName}/${articleId}/${cartPosition}/${articleOrderStatus}`}
    />
  );
}
