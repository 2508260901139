import { faBan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { isNil, not } from "ramda";
import { BaseSyntheticEvent, useEffect, useReducer, useRef } from "react";

import { L10n } from "@encoway/l10n";

import {
  ChangeProperty,
  LoadCheckoutInformation,
  StateReducer,
  initialOrderModalState,
  simpleReducer,
} from "../../../../../actions/orderActions";
import { InputMaxLength } from "../../../../../components/input/inputMaxLength";
import { useCart } from "../../../../../hooks/useCart";
import { useLocaleNumberFormat } from "../../../../../hooks/useLocaleNumberFormat";
import { TeckentrupCartTO } from "../../../../../types/cart";
import { InputConstants } from "../../../../offerManagement/modifyOfferModal/inputConstants";
import {
  isOrdered,
  isShared,
} from "../../../../offerManagement/offerManagementTable/offerManagementTableUtils";
import { DateInput } from "./dateinput";
import {
  DifferingShippingAddress,
  DifferingShippingAddressState,
} from "./differingShippingAddress";
import { OrderModalState, createNewCheckoutInformation } from "./orderHelper";

import "./orderModal.scss";

type SubmitButtonProps = {
  isLoading: boolean;
  allMandatoriesCompleted: boolean;
  orderNumber: string;
  buildProject: string;
  headerData: TeckentrupCartTO["headerData"];
  submitDialog: () => Promise<void>;
};

const SubmitButton = ({
  isLoading,
  allMandatoriesCompleted,
  orderNumber,
  buildProject,
  headerData,
  submitDialog,
}: SubmitButtonProps) =>
  !isLoading &&
  allMandatoriesCompleted &&
  orderNumber &&
  buildProject &&
  not(isOrdered(headerData?.status)) ? (
    <button className={"button is-info is-orange"} onClick={submitDialog}>
      <div className="is-pulled-left" data-button-id="send_order">
        {L10n.format("order_dialog_send")}
      </div>
      <div className="is-pulled-right"></div>
    </button>
  ) : (
    <button
      className={classNames("button is-info is-orange", {
        "is-loading": isLoading,
      })}
      disabled
    >
      <div className="is-pulled-left" data-button-id="send_order">
        {L10n.format("order_dialog_send")}
      </div>
      <div className="is-pulled-right"></div>
    </button>
  );

type OrderModalProps = {
  errorMethod: () => void;
  closingMethod: () => void;
  isPartialOrder: boolean;
};

export const OrderModal = ({
  errorMethod,
  closingMethod,
  isPartialOrder,
}: OrderModalProps) => {
  const shippingAddress = useRef<{
    resultInformation: () => DifferingShippingAddressState | undefined;
  }>();
  const [state, dispatch] = useReducer<
    (state: OrderModalState, reducer: StateReducer) => any
  >(simpleReducer, initialOrderModalState);
  const { cart, cartActions } = useCart();
  const {
    orderNumber,
    buildProject,
    furtherNotes,
    allMandatoriesCompleted,
    isLoading,
  } = state;
  const { formatPrice } = useLocaleNumberFormat();

  useEffect(() => {
    if (isNil(cart.headerData)) {
      return;
    }
    dispatch(LoadCheckoutInformation(cart.headerData));
  }, [cart.headerData]);

  const onChange = (name: string, value: object | boolean) =>
    dispatch(ChangeProperty(name, value));

  const submitDialog = async () => {
    try {
      dispatch(ChangeProperty("isLoading", true));
      if (isShared(cart.headerData?.status)) {
        await cartActions.performSharedQuoteCheckout(
          createNewCheckoutInformation(
            state,
            shippingAddress.current?.resultInformation(),
          ),
        );
      } else if (cart.flaggedForPartOrder) {
        await cartActions.performPartOrderCheckout(
          createNewCheckoutInformation(
            state,
            shippingAddress.current?.resultInformation(),
          ),
        );
      } else {
        await cartActions.saveCheckoutInformation(
          createNewCheckoutInformation(
            state,
            shippingAddress.current?.resultInformation(),
          ),
        );
        await cartActions.performCheckout();
      }
      closingMethod();
    } catch (e) {
      dispatch(ChangeProperty("isLoading", false));
      errorMethod();
    }
  };

  return (
    <div className="modal is-active" data-dialog-id="order_dialog_title">
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">
            {L10n.format("order_dialog_title")}
          </p>
          <button
            data-button-id="order_dialog_title"
            className="delete"
            aria-label="close"
            onClick={() => closingMethod()}
            disabled={isLoading}
          />
        </header>
        <section className="modal-card-body">
          <div className="modal-card-subtitle">
            {L10n.format("order_dialog_subtitle")}
          </div>
          <div className="two-columns">
            <div className="column-entry with-padding">
              <InputMaxLength
                name="buildProject"
                value={buildProject}
                onChange={(event: BaseSyntheticEvent) =>
                  onChange(event.target.name, event.target.value)
                }
                label={
                  L10n.format("order_dialog_build_project") +
                  L10n.format("mandatory_symbol")
                }
                placeholder={L10n.format(
                  "order_dialog_build_project_placeholder",
                )}
                maxLength={InputConstants.length.order_dialog_build_project}
                inputId="order_dialog_build_project"
              />
            </div>
            <div className="column-entry with-padding">
              <InputMaxLength
                name="orderNumber"
                value={orderNumber}
                onChange={(event: BaseSyntheticEvent) =>
                  onChange(event.target.name, event.target.value)
                }
                label={
                  L10n.format("reference_order_id") +
                  L10n.format("mandatory_symbol")
                }
                placeholder={L10n.format("order_dialog_reference_placeholder")}
                maxLength={InputConstants.length.order_dialog_reference}
                inputId="reference_order_id"
              />
            </div>
          </div>
          <hr />
          {isPartialOrder && (
            <>
              <div className="column-center with-padding">
                <span
                  className="boldText part-order-hint"
                  data-text-id="part-order-hint"
                >
                  {L10n.format("order_dialog_part_order_hint")}
                </span>
                <br />
                <span data-text-id="part-order-description">
                  {L10n.format("order_dialog_part_order_description", {
                    itemCountPartOrder: cart.itemCountPartOrder,
                    ekNettoGpPlusSurchargeAbsolutePartOrder: formatPrice(
                      cart.ekNettoGpPlusSurchargeAbsolutePartOrder,
                    ),
                  })}
                </span>
              </div>
              <hr />
            </>
          )}
          <DifferingShippingAddress
            ref={shippingAddress}
            headerData={cart.headerData}
            checkAllMandatoriesCompleted={(value) =>
              onChange("allMandatoriesCompleted", value)
            }
          />
          <hr />
          <div className="two-columns">
            <div className="column-entry with-padding">
              <DateInput
                name="dateOfDelivery"
                onChange={(date) =>
                  dispatch(ChangeProperty("dateOfDelivery", date))
                }
                label={L10n.format("order_dialog_date_of_delivery")}
                placeholder={L10n.format(
                  "order_dialog_date_of_delivery_placeholder",
                )}
                inputId={"order_dialog_date_of_delivery"}
              />
              <div className="smallText">
                <span className="boldText">
                  {L10n.format("order_dialog_date_of_delivery_information")}
                </span>
                <span>{L10n.format("order_dialog_date_of_delivery_hint")}</span>
              </div>
            </div>
            <div className="column-entry with-padding">
              <label className="label">
                {L10n.format("order_dialog_further_notes")}
              </label>
              <textarea
                name="furtherNotes"
                className="textarea"
                value={furtherNotes}
                onChange={(event: BaseSyntheticEvent) =>
                  onChange(event.target.name, event.target.value)
                }
                placeholder={L10n.format(
                  "order_dialog_further_notes_placeholder",
                )}
                maxLength={InputConstants.length.order_dialog_further_notes}
                data-input-field-id="order_dialog_further_notes"
              />
            </div>
          </div>
          <hr />
          <div className="smallText with-padding">
            {L10n.format("mandatory_symbol_information")}
          </div>
          <div className="bottom-space" />
        </section>
        <footer className="modal-card-foot">
          <button
            className="button is-info is-blue-dark"
            onClick={() => closingMethod()}
            disabled={isLoading}
          >
            <div className="is-pulled-left" data-button-id="cancel">
              {L10n.format("cancel")}
            </div>
            <div className="is-pulled-right">
              <FontAwesomeIcon icon={faBan} className="pl-2" />
            </div>
          </button>
          <SubmitButton
            isLoading={isLoading}
            allMandatoriesCompleted={allMandatoriesCompleted}
            orderNumber={orderNumber}
            buildProject={buildProject}
            headerData={cart.headerData}
            submitDialog={submitDialog}
          />
        </footer>
      </div>
    </div>
  );
};
