import { is } from "ramda";

import { NumberInput } from "../../../../components/input/NumberInput";
import { TextInput } from "../../../../components/input/TextInput";
import { t } from "../../../shoppingCart/localizationUtils";

type LvInputFieldProps = {
  name: string;
  value: string | number;
  setValue: (value: string | number) => void;
};

export const LvInputField = ({ name, value, setValue }: LvInputFieldProps) => {
  return (
    <div className="field has-addons is-align-items-center" style={{ gap: 8 }}>
      <span className="is-size-7 has-text-weight-bold">
        {t(`offer_management_${name}`)}
      </span>
      <div className="control is-expanded">
        {is(Number, value) ? (
          <NumberInput
            className="input is-small is-fullwidth"
            value={value}
            setValue={setValue}
          />
        ) : (
          <TextInput
            className="input is-small is-fullwidth"
            value={value}
            setValue={setValue}
          />
        )}
      </div>
    </div>
  );
};
