import "./inputError.scss";

type InputErrorProps = Readonly<{
  label: string;
  visible: boolean;
}>;

export function InputError({ label, visible }: InputErrorProps) {
  if (visible) {
    return (
      <div className="input-error">
        <p className="error-label">{label}</p>
      </div>
    );
  }

  return null;
}
