import { any, lt } from "ramda";

import { FreeItem } from "../../../pages/shoppingCart/components/modals/freeItemModal";
import { isNilOrEmpty } from "../../../utilities/utilities";

const MIN_BASE_PRICE = 0;
const MIN_AMOUNT = 1;

/**
 * Checks all mandatory fields of freeItemModal
 *
 * @param {FreeItem} freeItem the freeItem to create from freeItemModal
 *
 * @returns {boolean} Returns true if not all mandatory fields have been filled
 */
export const mandatoryFieldsIncomplete = (freeItem: FreeItem): boolean => {
  const { name, basePrice, amount } = freeItem;
  return (
    any(isNilOrEmpty, [name, basePrice, amount]) ||
    lt(basePrice, MIN_BASE_PRICE) ||
    lt(amount, MIN_AMOUNT)
  );
};
