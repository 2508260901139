import { path } from "ramda";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { L10n } from "@encoway/l10n";

import { ExtendableImage } from "../../../components/image/extendableImage";
import { useApp } from "../../../hooks/useApp";
import { useCart } from "../../../hooks/useCart";
import { ROUTES } from "../../../http/constants";
import { getTechnicalDocumentation } from "../../../service/catalogService";
import {
  copyConfigurationEvent,
  track,
} from "../../../utilities/eventTracking";
import noPicture from "./no-picture.png";
import { OrderedProductAttributes } from "./orderedProductAttributes";
import { ProductAttributes } from "./productAttributes";

import "./productView.scss";

function Article({ product, articleHasBeenAdded, addArticleToCart }) {
  if (product.configurable) {
    return (
      <div className="is-relative">
        <button
          className={
            "button is-info is-pulled-right is-blue-dark addProductButton"
          }
          data-button-id="catalogue_add_to_shopping_cart"
          onClick={addArticleToCart}
        >
          <div className="is-pulled-left">
            {L10n.format("catalogue_add_to_shopping_cart")}
          </div>
          <div className="is-pulled-right">
            <i className="image-icon warenkorb-leer" aria-hidden="true" />
          </div>
          {articleHasBeenAdded && (
            <div
              className="inputWarning"
              data-dialog-id="article_has_been_added"
            >
              {articleHasBeenAdded}
            </div>
          )}
        </button>
      </div>
    );
  }
  return (
    <div className="is-relative">
      <Link
        to={ROUTES.CART}
        className={"button is-info is-pulled-right is-orange addProductButton"}
        data-button-id="catalogue_add_to_shopping_cart"
        onClick={addArticleToCart}
      >
        <div className="is-pulled-left">
          {L10n.format("catalogue_add_to_shopping_cart")}
        </div>
        <div className="is-pulled-right">
          <i className="image-icon warenkorb-leer" aria-hidden="true" />
        </div>
      </Link>
    </div>
  );
}

export function ProductView({ product, language, colSize }) {
  const { catalogService } = useApp();
  const { cartActions } = useCart();
  const [state, setState] = useState({
    currentProduct: null,
    articleHasBeenAdded: null,
    isLoading: false,
    showDocumentLinks: true,
  });

  function getProductImage() {
    const productImage =
      state.currentProduct.product.characteristicValues.Product_image;
    if (productImage) {
      return productImage.values[0].uri;
    }
    return noPicture;
  }

  function getMinimalAmount() {
    const minimalAmount =
      state.currentProduct.product.characteristicValues.sp_mindestbestellmenge;
    return path(["values", 0, "value"], minimalAmount);
  }

  function resetArticleHasBeenAddedMessage() {
    setState({ ...state, articleHasBeenAdded: null });
  }

  function showArticleHasBeenAddedMessage() {
    setState({
      ...state,
      articleHasBeenAdded: L10n.format(
        "catalogue_article_has_been_added_to_cart",
      ),
    });
    setTimeout(resetArticleHasBeenAddedMessage, 2000);
  }

  function addArticleToCart() {
    cartActions.add(product.id, getMinimalAmount());
    showArticleHasBeenAddedMessage();
  }

  useEffect(() => {
    (async () => {
      const currentProduct = await catalogService.product(product.id);
      const doc = await getTechnicalDocumentation(product.id, language);
      setState((prev) => ({
        ...prev,
        currentProduct,
        documentationCharacteristics: doc?.data?.documentationCharacteristics,
        showDocumentLinks: path(["product", "configurable"], currentProduct),
      }));
    })();
  }, []);

  if (!state.currentProduct) {
    return null;
  }

  return (
    <td colSpan={colSize}>
      <div className="columns">
        <div className="column is-3 image">
          <ExtendableImage src={getProductImage()} />
        </div>
        <div className="column is-7 attributes-wrapper">
          <ProductAttributes
            headline={"catalogue_technical_characteristics"}
            currentProduct={state.currentProduct}
            characteristicName="technicalPropertyView"
            displayAsLink={false}
          />
          {state.showDocumentLinks ? (
            <OrderedProductAttributes
              documentationCharacteristics={state.documentationCharacteristics}
              headline={"catalogue_technical_documentation"}
              displayAsLink={true}
            />
          ) : (
            <div className="attribute" />
          )}
        </div>
        <div className="buttons column is-2">
          {state.currentProduct.product.configurable && (
            <Link
              className={`button is-info is-pulled-right is-orange${state.isLoading ? " is-loading" : ""}`}
              to={`/configuration/product/${product.id}`}
              onClick={() => track(copyConfigurationEvent)}
            >
              <div
                className="is-pulled-left"
                data-button-id="catalogue_start_configuration"
              >
                {L10n.format("catalogue_start_configuration")}
              </div>
              <div className="is-pulled-right">
                <i
                  className="image-icon schraubenschluessel"
                  aria-hidden="true"
                />
              </div>
            </Link>
          )}
          <Article
            product={product}
            addArticleToCart={addArticleToCart}
            articleHasBeenAdded={state.articleHasBeenAdded}
            resetArticleHasBeenAddedMessage={resetArticleHasBeenAddedMessage}
          />
        </div>
      </div>
    </td>
  );
}
