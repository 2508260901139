import React from "react";
import { createRoot } from "react-dom/client";

import App from "./App";
import { AppProvider } from "./context/AppProvider";

function Entry() {
  return (
    <React.StrictMode>
      <AppProvider>
        <App />
      </AppProvider>
    </React.StrictMode>
  );
}

const root = createRoot(document.getElementById("content") as HTMLElement);

root.render(<Entry />);
